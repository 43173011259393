export default class LogDirectory {
  public static readonly EnableImagePreloaderLogging: boolean = false;
  public static readonly EnableCameraLogging: boolean = false;
  public static readonly EnableNavigatorLogging: boolean = false;
  public static readonly EnableMapLogging: boolean = false;
  public static readonly EnablePlaybackLogging: boolean = false;
  public static readonly EnableMilepostLogging: boolean = false;
  public static readonly EnableChainageLogging: boolean = false;
  public static readonly EnableSessionInfoLogging: boolean = false;
  public static readonly EnableYearLogging: boolean = false;
  public static readonly EnableLocatorsLogging: boolean = false;
  public static readonly EnableCurrentLocatorLogging: boolean = false;
  public static readonly EnableTableLogging: boolean = false;
  public static readonly EnableAppLogging: boolean = false;
}
