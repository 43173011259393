import UserGroup from "./userGroup";

export default class User {
  public idUser: number = 0;
  public username: string = "";
  public password: string = "";
  public email: string = "";
  public firstName: string = "";
  public lastName: string = "";
  public name: string = "";
  public isAdmin: boolean = false;
  public isPortalAdmin: boolean = false;
  public isDemoUser: boolean = false;
  public createDate: Date | undefined = undefined;
  public lastLoginDate: Date | undefined = undefined;
  public lastActivityDate: Date | undefined = undefined;
  public userGroups: UserGroup[] = [];

  public updateName(): void {
    this.name = `${this.firstName} ${this.lastName}`;
  }

  public onUserInfoChanged: any;
}
