export default class NavigatorPosition {
  public idProject: number = 0;
  public idRoad: number = 0;
  public idSession: number = 0;
  public distance: number = 0;
  public chainage: number | undefined = undefined;

  // constructor(
  //   idRoad: number = 0,
  //   idSession: number = 0,
  //   distance: number = 0,
  //   chainage: number = 0
  // ) {
  //   this.idRoad = idRoad;
  //   this.idSession = idSession;
  //   this.distance = distance;
  //   this.chainage = chainage;
  // }

  public Clone(): NavigatorPosition {
    const navigatorPosition = new NavigatorPosition();
    navigatorPosition.idProject = this.idProject;
    navigatorPosition.idRoad = this.idRoad;
    navigatorPosition.idSession = this.idSession;
    navigatorPosition.distance = this.distance;
    navigatorPosition.chainage = this.chainage;
    return navigatorPosition;
  }

  static getClosestNavigatorPositionIndexExact(
    navigatorPosition: NavigatorPosition,
    navigatorPositions: NavigatorPosition[]
  ) {
    let index = -1;

    if (navigatorPositions.length === 0) {
      return index;
    }

    let navigationDistance = navigatorPosition.distance;

    const isBetween =
      navigationDistance >= navigatorPositions[0].distance &&
      navigationDistance <=
        navigatorPositions[navigatorPositions.length - 1].distance;

    if (!isBetween) {
      return index;
    }

    for (let i = 0; i < navigatorPositions.length; i++) {
      const item = navigatorPositions[i];
      if (item.distance > navigationDistance) {
        if (i > 0) {
          index = i - 1;
        }
        break;
      }
    }
    return index;
  }

  toString() {
    const navigatorPosition = this;
    return `navigatorPosition(${navigatorPosition.idProject}, ${navigatorPosition.idRoad}, ${navigatorPosition.idSession}, ${navigatorPosition.distance}, ${navigatorPosition.chainage})`;
  }
}
