const distanceBetweenPoints = (
  x1: number,
  y1: number,
  x2: number,
  y2: number
): number => {
  const dist = Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
  return dist;
};

const isPointOnLine = (
  x1: number, // a point on line (line start)
  y1: number, // a point on line (line start)
  x2: number, // b point on line (line end)
  y2: number, // b point on line (line end)
  x: number, // c point to check if on line
  y: number, // c point to check if on line
  epsilon: number = 0.1
): boolean => {
  // A-C------B
  const distanceAC = distanceBetweenPoints(x1, y1, x, y);
  const distanceCB = distanceBetweenPoints(x, y, x2, y2);
  const distanceAB = distanceBetweenPoints(x1, y1, x2, y2);
  const distC = Math.abs(distanceAC + distanceCB - distanceAB);

  //return distC < 1e-9;
  //return distC < 0.1;
  return distC < epsilon;
};

const isPointCloseToPoint = (
  x1: number, // known point
  y1: number, // known point
  x: number, // point to check if in vicinity
  y: number, // point to check if in vicinity
  r: number = 2 // radius / vicinity
) => {
  // for closest point with pythagora in circle - cosider circle with origin in point and given radius
  const dx = x - x1;
  const dy = y - y1;
  // const r = 2;
  const rXr = r * r;

  const isHit = dx * dx + dy * dy < rXr;
  return isHit;
};

const geometryHelper = {
  distanceBetweenPoints,
  isPointOnLine,
  isPointCloseToPoint,
};

export default geometryHelper;
