import SessionEvents from "../models/sessionEvents";
import httpService from "./httpService";

const apiEndpoint = "/events";

const getSessionEvents = (idProject: number, idSession: number) => {
  return httpService.get<SessionEvents>(
    `${apiEndpoint}/getSessionEvents?idProject=${idProject}&idSession=${idSession}`
  );
};

const eventService = {
  getSessionEvents,
};

export default eventService;
