const numberTo3Digits = (
  value: number | undefined | null
): number | undefined => {
  return numberToNDigits(value, 3);
};

const numberTo4Digits = (
  value: number | undefined | null
): number | undefined => {
  return numberToNDigits(value, 4);
};

const numberToNDigits = (
  value: number | undefined | null,
  digits: number
): number | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }
  const value3digitsString = Number(value).toFixed(digits);
  const value3digitsNumber = +value3digitsString;
  return value3digitsNumber;
};

const numberHelper = {
  numberTo3Digits,
  numberTo4Digits,
  numberToNDigits,
};

export default numberHelper;
