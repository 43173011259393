const hideScrollbars = () => {
  document.body.style.overflow = "hidden";
};

const setAutoScrollbars = () => {
  document.body.style.overflow = "auto";
};

const pageHelper = {
  hideScrollbars,
  setAutoScrollbars,
};

export default pageHelper;
