import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import FormMessage from "../components/common/FormMessage";
import LoginBoxContent from "../components/common/LoginBoxContent";
import MotionDiv from "../components/common/MotionDiv";
import userService from "../services/userService";
import formHelper from "../util/formHelper";

import pageHelper from "../util/pageHelper";

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [resetPasswordToken, setResetPasswordToken] = useState<string>("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    console.log("resetPasswordPageInit");
    pageHelper.hideScrollbars();

    const resetPasswordToken = searchParams.get("t");
    if (resetPasswordToken) {
      setResetPasswordToken(resetPasswordToken);
    }
  }, [searchParams]);

  const clearError = () => {
    setErrorMessage("");
    setMessage("");
  };

  const onSumbit = async (event: any) => {
    event.preventDefault();

    clearError();

    if (
      formHelper.checkEmpty(newPassword, "New Password", setErrorMessage) ||
      formHelper.checkEmpty(
        confirmNewPassword,
        "Confirm New Password",
        setErrorMessage
      )
    ) {
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setErrorMessage("New Password and Confirm Password don't match");
      return;
    }

    // all good

    setIsBusy(true);

    try {
      const { data } = await userService.resetPassword(
        resetPasswordToken,
        newPassword
      );
      setIsBusy(false);
      setMessage(data);
    } catch (error: any) {
      setIsBusy(false);
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
    }
  };

  return (
    <MotionDiv>
      <LoginBoxContent>
        <div className="mb-3 mt-4">
          <h4>Reset Password</h4>
        </div>
        <form className="input-form" onSubmit={onSumbit}>
          <div className="row">
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">
                New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                onChange={(event) =>
                  formHelper.onFieldChanged(event, setNewPassword, clearError)
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmNewPassword" className="form-label">
                Confirm New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmNewPassword"
                onChange={(event) =>
                  formHelper.onFieldChanged(
                    event,
                    setConfirmNewPassword,
                    clearError
                  )
                }
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <Link to="/login">Back to Login</Link>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isBusy}
              >
                Reset Password
              </button>
            </div>
          </div>
          <FormMessage
            message={message}
            errorMessage={errorMessage}
            isBusy={isBusy}
          />
        </form>
      </LoginBoxContent>
    </MotionDiv>
  );
};

export default ResetPassword;
