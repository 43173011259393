import { useEffect, useState } from "react";
import BoxContent from "../../components/common/BoxContent";
import organizationService from "../../services/organizationService";
import WarningIcon from "@mui/icons-material/Warning";
import OrganizationStat from "../../models/organizationStat";
import dateTimeHelper from "../../util/dateTimeHelper";
import Moment from "react-moment";
import fileService from "../../services/fileService";
import userService from "../../services/userService";

const Admin: React.FC = () => {
  const [liveUsersCount, setLiveUsersCount] = useState<number>();
  const [rows, setOrganizationStatRows] = useState<OrganizationStat[][]>([]);

  useEffect(() => {
    const init = async () => {
      const { data: liveUsers } = await userService.getActiveUsers(
        userService.usersActiveLiveSeconds
      );
      setLiveUsersCount(liveUsers.length);

      const { data: organizationStats } =
        await organizationService.getOrganizationStats();

      const rowSize = 4;
      const rows = [];
      for (let i = 0; i < organizationStats.length; i += rowSize) {
        const row = organizationStats.slice(i, i + rowSize);

        if (row.length < rowSize) {
          const rowLenDiff = rowSize - row.length;
          for (let index = 0; index < rowLenDiff; index++) {
            row.push(new OrganizationStat());
          }
        }

        rows.push(row);
      }

      setOrganizationStatRows(rows);
    };

    init();
  }, []);

  const exportOrganizationStats = async () => {
    const response = await organizationService.getOrganizationStatsCsvFile();
    fileService.exportFile(response);
  };

  const exportOrganizationUserStats = async () => {
    const response =
      await organizationService.getOrganizationUserStatsCsvString();
    fileService.exportFile(response);
  };

  return (
    <BoxContent title="Dashboard" useFullWidth>
      <div>
        <div className="container-fluid">
          <div className="mb-3">
            Live Users{" "}
            <span className="text-lg text-heading fw-bold">
              {liveUsersCount}
            </span>
          </div>
          <div className="mb-3">
            <span className="link" onClick={exportOrganizationStats}>
              Export Organization Stats
            </span>
            <span
              className="link"
              style={{ marginLeft: "15px" }}
              onClick={exportOrganizationUserStats}
            >
              Export Organization User Stats
            </span>
          </div>
          {rows.map((row, i) => (
            <div className="row" key={`row${i}`}>
              {row.map((organizationStatInfo, j) => (
                <div
                  key={`row${i}_item${j}`}
                  className="col card"
                  style={{
                    margin: "5px",
                    boxShadow: "0px 3px 3px -1px rgba(10,22,70,.04)",
                    borderRadius: ".75rem",
                    visibility:
                      organizationStatInfo.idOrganization !== 0
                        ? "visible"
                        : "hidden",
                    padding: "5px",
                  }}
                >
                  <div className="card-body">
                    <div className="d-flex mb-1">
                      <span className="text-muted">
                        {organizationStatInfo.organizationName}
                      </span>
                      {organizationStatInfo.usersCount === 0 && (
                        <WarningIcon
                          style={{
                            color: "orange",
                            marginLeft: "4px",
                            fontSize: "20px",
                          }}
                        />
                      )}
                      {organizationStatInfo.subscriptionRenewalDate &&
                        dateTimeHelper.dateDiffNowDays(
                          organizationStatInfo.subscriptionRenewalDate
                        ) < 0 && (
                          <span
                            style={{ marginLeft: "5px" }}
                            className="badge bg-danger"
                          >
                            Expired
                          </span>
                        )}
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="me-2">Users</div>
                      <div className="text-lg text-heading fw-bold">
                        {organizationStatInfo.usersCount}
                      </div>
                      {organizationStatInfo.usersCount === 0 && (
                        <WarningIcon
                          style={{
                            color: "orange",
                            marginLeft: "4px",
                            fontSize: "20px",
                          }}
                        />
                      )}
                    </div>
                    <div>
                      Go Live Date
                      <span className="text-lg text-heading fw-bold ms-2">
                        {organizationStatInfo.goLiveDate && (
                          <Moment format={dateTimeHelper.dateFormat}>
                            {organizationStatInfo.goLiveDate}
                          </Moment>
                        )}
                        {!organizationStatInfo.goLiveDate && "-"}
                      </span>
                    </div>
                    <div>
                      Live Days
                      <span className="text-lg text-heading fw-bold ms-2">
                        {organizationStatInfo.goLiveDate
                          ? Math.abs(
                              dateTimeHelper.dateDiffNowDays(
                                organizationStatInfo.goLiveDate
                              )
                            )
                          : "-"}
                      </span>
                    </div>
                    <div>
                      Is Paid Subscription
                      <span className="text-lg text-heading fw-bold ms-2">
                        {organizationStatInfo.isPaidSubscription
                          ? "True"
                          : "False"}
                      </span>
                    </div>
                    <div>
                      Subscription Renewal Date
                      <span className="text-lg text-heading fw-bold ms-2">
                        {organizationStatInfo.subscriptionRenewalDate && (
                          <Moment format={dateTimeHelper.dateFormat}>
                            {organizationStatInfo.subscriptionRenewalDate}
                          </Moment>
                        )}
                        {!organizationStatInfo.subscriptionRenewalDate && "-"}
                      </span>
                    </div>
                    <div>
                      Subscription Time Remaining (Days)
                      <span className="text-lg text-heading fw-bold ms-2">
                        {organizationStatInfo.subscriptionRenewalDate
                          ? dateTimeHelper.dateDiffNowDays(
                              organizationStatInfo.subscriptionRenewalDate
                            )
                          : "-"}
                        {organizationStatInfo.subscriptionRenewalDate &&
                          dateTimeHelper.dateDiffNowDays(
                            organizationStatInfo.subscriptionRenewalDate
                          ) < 0 && (
                            <WarningIcon
                              style={{
                                color: "red",
                                marginLeft: "4px",
                                fontSize: "20px",
                              }}
                            />
                          )}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </BoxContent>
  );
};

export default Admin;
