import Organization from "../models/organization";
import organizationService from "../services/organizationService";

export default class OrganizationContextModel {
  public organizations: Organization[] = [];
  public currentOrganization: Organization | undefined = undefined;

  public onOrganizationsChanged: any;
  public onCurrentOrganizationChanged: any;

  private isInitialized: boolean = false;

  public async init() {
    if (this.isInitialized) {
      return;
    }

    await this.loadOrganizations();

    this.currentOrganization = this.organizations[0];

    this.isInitialized = true;
  }

  public async loadOrganizations() {
    const { data: organizations } =
      await organizationService.getOrganizations();

    this.organizations = organizations;

    const newRef = this.organizations.find(
      (o) => o.idOrganization === this.currentOrganization?.idOrganization
    );
    this.currentOrganization = newRef;

    if (this.onOrganizationsChanged) {
      this.onOrganizationsChanged();
    }
  }

  public invokeCurrentOrganizationChanged() {
    if (this.onCurrentOrganizationChanged) {
      this.onCurrentOrganizationChanged();
    }
  }
}
