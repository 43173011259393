import { KEY_RETURN } from "keycode-js";

const onFieldChanged = (
  event: any,
  onSetFieldValue: any,
  onClearError: any = undefined
) => {
  const newValue = event.target.value;
  onSetFieldValue(newValue);
  if (onClearError) {
    onClearError();
  }
};

const checkEmpty = (
  fieldValue: string,
  fieldName: string,
  onSetError: any
): boolean => {
  if (!fieldValue) {
    onSetError(`${fieldName} cannot be empty`);
    return true;
  }
  return false;
};

const onEnterKeyDownPreventSubmit = (event: any) => {
  const keyCode = event.keyCode || event.which || event.charCode;
  if (keyCode === KEY_RETURN) {
    event.preventDefault();
  }
};

const formHelper = {
  onFieldChanged,
  checkEmpty,
  onEnterKeyDownPreventSubmit,
};

export default formHelper;
