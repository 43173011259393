const BoxContent: React.FC<{
  title: string;
  useFullWidth?: boolean;
  useNarrowPadding?: boolean;
  leftChildren?: any;
  children: any;
}> = ({
  title,
  useFullWidth = false,
  useNarrowPadding = false,
  leftChildren = undefined,
  children,
}) => {
  return (
    <div className={useFullWidth ? "container-fluid" : "container"}>
      <div className="d-flex align-items-center justify-content-center">
        <div
          className="box-shadow"
          style={{
            width: useFullWidth ? "100%" : "800px",
            marginTop: "15px",
            padding: useNarrowPadding ? "10px 20px 20px 20px" : "30px",
          }}
        >
          <div className="d-flex justify-content-between">
            <h1 style={{ fontWeight: "400", marginBottom: "20px" }}>{title}</h1>
            {leftChildren && <div>{leftChildren}</div>}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default BoxContent;
