import Navigator from "../../models/navigator";
import UturnImage from "../../assets/images/UTurn.png";
import LoadingIndicator from "../common/LoadingIndicator";
import { useState } from "react";
import ProjectInfo from "../../models/projectInfo";
import mapService from "../../services/mapService";

const Uturn: React.FC<{ navigator: Navigator; project: ProjectInfo }> = ({
  navigator,
  project,
}) => {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const onUturnClick = async () => {
    if (!navigator.navigatorPosition) {
      return;
    }

    setIsBusy(true);

    const { data: uTrunPosition } =
      await mapService.getUturnPositionForLocation(
        project.idProject,
        navigator.navigatorPosition
      );

    if (!uTrunPosition) {
      setIsBusy(false);
      setHasError(true);
      setTimeout(() => setHasError(false), 2000);
      return;
    }

    await navigator.setFilters(project.idProject, []);

    await navigator.move(uTrunPosition);

    setIsBusy(false);
  };

  return (
    <div style={{ marginLeft: "-4px" }}>
      <img
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title="U-turn"
        className="img-button"
        src={UturnImage}
        alt=""
        height="25px"
        onClick={onUturnClick}
      />
      {isBusy && (
        <div
          style={{
            position: "absolute",
            top: "16px",
            zIndex: "2",
          }}
        >
          <LoadingIndicator className="utrun" />
        </div>
      )}
      {hasError && (
        <div
          style={{
            position: "absolute",
            top: "42px",
            zIndex: "2",
            margin: "3px",
          }}
        >
          <div className="alert alert-danger">
            U-turn not possible at this location!
          </div>
        </div>
      )}
    </div>
  );
};

export default Uturn;
