import { useContext, useEffect } from "react";
import { FullScreen } from "ol/control";
import MapContext from "../contexts/mapContext";

const FullScreenControl = () => {
  const map: any = useContext(MapContext);

  useEffect(() => {
    if (!map) {
      return;
    }

    let fullScreenControl = new FullScreen({});
    map.controls.push(fullScreenControl);

    return () => map.controls.remove(fullScreenControl);
  }, [map]);
  return null;
};

export default FullScreenControl;
