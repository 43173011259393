import { KEY_RETURN } from "keycode-js";
import { useEffect, useState } from "react";
import Navigable, { NavigatorMove } from "../../models/navigable";
import Navigator from "../../models/navigator";
import NavigatorPosition from "../../models/navigatorPosition";
import ProjectInfo from "../../models/projectInfo";
import roadManager from "../../models/roadManager";
import LogDirectory from "../../services/logDirectory";
import LogService from "../../services/logService";
import numberHelper from "../../util/numberHelper";
import unitConverter from "../../util/units/unitConverter";
import Settings from "../../models/settings";

const logService = new LogService(LogDirectory.EnableChainageLogging);

const showDistance: boolean = false;

const Chainage: React.FC<{
  navigator: Navigator;
  project: ProjectInfo;
  showChainageUnit: boolean;
  settings: Settings;
}> = ({ navigator, project, showChainageUnit, settings }) => {
  const [chainage, setChainage] = useState<number>(0);
  const [distance, setDistance] = useState<number>(0);

  useEffect(() => {
    logService.log("chainage:init()");

    const navigable = new Navigable();
    navigable.id = "chainage";

    const covertChaingeToUnit = (chainageMeters: number) => {
      return unitConverter.metersToMiles(chainageMeters);
    };

    settings.onIsChainageInMetersChanged = (checked: boolean) => {
      if (navigator.navigatorPosition?.chainage) {
        if (settings.isChainageInMeters) {
          setDisplayChainage(navigator.navigatorPosition.chainage);
        } else {
          const chainageToUnit = covertChaingeToUnit(
            navigator.navigatorPosition.chainage
          );
          setDisplayChainage(chainageToUnit);
        }
      }
    };

    const navigatorMove: NavigatorMove = async (
      navigatorPosition: NavigatorPosition
    ) => {
      logService.log("chainage:navigator:move()");

      setDisplayDistance(navigatorPosition.distance);

      if (navigatorPosition.chainage !== undefined) {
        const chainage = navigatorPosition.chainage;

        if (settings.isChainageInMeters) {
          setDisplayChainage(chainage);
        } else {
          const chainageToUnit = covertChaingeToUnit(chainage);
          setDisplayChainage(chainageToUnit);
        }
      }
    };
    navigable.navigatorMove = navigatorMove;

    navigator.attach(navigable);

    return () => navigator.detach(navigable);
  }, [navigator, settings]);

  const covertChaingeToMeters = (chainageUnit: number) => {
    return unitConverter.milesToMeters(chainageUnit);
  };

  const setDisplayChainage = (chainage: number) => {
    const value = numberHelper.numberTo3Digits(chainage);
    if (value !== undefined) {
      setChainage(value);
    }
  };

  const setDisplayDistance = (distance: number) => {
    const value = numberHelper.numberTo3Digits(distance);
    if (value !== undefined) {
      setDistance(value);
    }
  };

  const onChainageChanged = (event: any) => {
    const chainageValue = Number(event.target.value);
    setDisplayChainage(chainageValue);
  };

  const move = async (chainageUnit: number) => {
    let chainage = chainageUnit;

    if (!settings.isChainageInMeters) {
      chainage = covertChaingeToMeters(chainageUnit);
    }

    if (
      navigator.navigatorPosition &&
      navigator.navigatorPosition.chainage !== chainage
    ) {
      logService.log("chainage:move()");
      const navigatorPosition = roadManager.getNavigatorPositionForChainage(
        chainage,
        navigator.roadLocatorSegments
      );
      if (navigatorPosition) {
        await navigator.move(navigatorPosition);
      }
    }
  };

  const onLostFocus = (event: any) => {
    logService.log("chainage:onLostFocus()");
    move(chainage);
  };

  const onKeyDown = (event: any) => {
    if (event.which === KEY_RETURN) {
      logService.log("chainage:onEnterKeyDown()");
      event.preventDefault();
      move(chainage);
    }
  };

  const getChainageDisplayName = () => {
    if (project.chainageDisplayNameShort) {
      return project.chainageDisplayNameShort;
    }

    if (project.chainageDisplayName) {
      return project.chainageDisplayName;
    }

    return "Chainage";
  };

  return (
    <div className="d-flex">
      <div className="menu-dropdown align-items-center">
        <div className="locators-title">
          {getChainageDisplayName()} {showChainageUnit ? "(mi)" : ""}
        </div>
        <div style={{ fontSize: "12px" }}>
          <input
            // className="form-control btn-secondary"
            className="form-control"
            style={{
              width: !showDistance ? "75px" : "100px",
              padding: "5px",
              fontWeight: "bold",
              // backgroundColor: "white",
              // color: "black",
            }}
            type="number"
            value={chainage}
            onChange={onChainageChanged}
            step="0.001"
            onBlur={onLostFocus}
            onKeyDown={onKeyDown}
          />
        </div>
        <div
          style={{
            // position: "absolute",
            // bottom: "-4px",
            fontSize: "10px",
            marginLeft: "4px",
            marginTop: "-2px",
            marginBottom: "-17px",
            fontWeight: "bold",
          }}
        >
          {!showDistance && <div>&nbsp;</div>}
          {showDistance && <div>Distance: {distance}</div>}
        </div>
      </div>
    </div>
  );
};

export default Chainage;
