import { useEffect, useState } from "react";
import AdminBoxConent from "../../components/admin/AdminBoxContent";
import userService from "../../services/userService";
import Moment from "react-moment";
import dateTimeHelper from "../../util/dateTimeHelper";
import SignupPending from "../../models/signupPending";
import SignupDomain from "../../models/signupDomain";
import ModalWindow from "../../components/common/ModalWindow";

const SignupPendings: React.FC<{}> = () => {
  const [signupPendings, setSignupPendings] = useState<SignupPending[]>([]);
  const [allSignupPendings, setAllSignupPendings] = useState<SignupPending[]>(
    []
  );

  const [searchText, setSearchText] = useState<string>("");

  const [showConfirmAccountWindow, setShowConfirmAccountWindow] =
    useState<boolean>(false);

  const [signupPendingDelete, setSignupPendingDelete] =
    useState<SignupPending>();

  const init = async () => {
    const { data: signupPendings } = await userService.getSignupPendings();
    setSignupPendings(signupPendings);
    setAllSignupPendings(signupPendings);
    setSearchText("");
  };

  useEffect(() => {
    init();
  }, []);

  const isEmailDomainAutoApproved = (
    email: string | undefined,
    signupDomains: SignupDomain[] | undefined
  ) => {
    if (!email || !signupDomains) {
      return false;
    }

    for (const signupDomain of signupDomains) {
      var emailDomain = email.slice(email.indexOf("@") + 1, email.length);
      if (
        emailDomain === signupDomain.domainName &&
        signupDomain.isAutoApproved
      ) {
        return true;
      }
    }

    return false;
  };

  const boolToString = (value: boolean) => {
    return value ? "True" : "False";
  };

  const isEmailDomainAutoApprovedHtml = (
    email: string | undefined,
    signupDomains: SignupDomain[] | undefined
  ) => {
    const isAutoApproved = isEmailDomainAutoApproved(email, signupDomains);

    return (
      <div
        style={{
          color: !isAutoApproved ? "red" : "initial",
          fontWeight: !isAutoApproved ? "bold" : "initial",
        }}
      >
        {boolToString(isAutoApproved)}
      </div>
    );
  };

  const onConfirmAccountConfirm = (signupPending: SignupPending) => {
    setShowConfirmAccountWindow(true);
    setSignupPendingDelete(signupPending);
  };

  const onConfirmAccountOk = async () => {
    setShowConfirmAccountWindow(false);

    if (signupPendingDelete?.user) {
      await userService.confirmAccountByUsername(
        signupPendingDelete.user.username
      );
    }

    await init();

    setSignupPendingDelete(undefined);
  };

  const onConfirmAccountCancel = () => {
    setShowConfirmAccountWindow(false);
    setSignupPendingDelete(undefined);
  };

  const onSearchTextChange = (event: any) => {
    const value = event.target.value;
    setSearchText(value);

    const searchToken = event.target.value.toLowerCase();

    const filteredSignupPendings = allSignupPendings.filter(
      (sp) =>
        sp.organization?.organizationName.toLowerCase().includes(searchToken) ||
        sp.user?.email.toLowerCase().includes(searchToken) ||
        sp.user?.firstName.toLowerCase().includes(searchToken) ||
        sp.user?.lastName.toLowerCase().includes(searchToken)
    );

    setSignupPendings(filteredSignupPendings);
  };

  return (
    <AdminBoxConent
      title="Signup Pendings - Emails Not Confirmed"
      hasCount={true}
      count={signupPendings.length}
      countType="signup pendings"
      hasAddNew={false}
      hasCurrentOrganizationDropdown={false}
    >
      <div className="mb-3">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={searchText}
            onChange={onSearchTextChange}
          />
          <button type="submit" className="btn btn-primary">
            Search
          </button>
        </div>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Organization Name</th>
            <th scope="col">Organization Auto Approved Domains</th>
            <th scope="col">Email</th>
            <th scope="col">Is Auto Approved Email Domain</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Create Date</th>
            <th style={{ width: "200px" }} />
          </tr>
        </thead>
        <tbody>
          {signupPendings.map((signupPending: SignupPending, index) => (
            <tr key={index.toString()}>
              <td>{signupPending.organization?.organizationName}</td>
              <td>
                {signupPending.organization?.signupDomains
                  .filter((sd) => sd.isAutoApproved)
                  .map((sd) => `@${sd.domainName}`)
                  .join(", ")}
              </td>
              <td>{signupPending.user?.email}</td>
              <td>
                {isEmailDomainAutoApprovedHtml(
                  signupPending.user?.email,
                  signupPending.organization?.signupDomains
                )}
              </td>
              <td>{signupPending.user?.firstName}</td>
              <td>{signupPending.user?.lastName}</td>
              <td>
                {signupPending.user?.createDate && (
                  <Moment format={dateTimeHelper.dateTimeFormat}>
                    {signupPending.user?.createDate}
                  </Moment>
                )}
              </td>
              <td>
                <button
                  className="btn btn-primary"
                  onClick={() => onConfirmAccountConfirm(signupPending)}
                >
                  Confirm Account
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showConfirmAccountWindow && (
        <ModalWindow
          title="Confirm Account"
          onOk={onConfirmAccountOk}
          onCancel={onConfirmAccountCancel}
        >
          <div>
            Are you sure you want to confirm account for:{" "}
            <b>{signupPendingDelete?.user?.email}</b>?
            {!isEmailDomainAutoApproved(
              signupPendingDelete?.user?.email,
              signupPendingDelete?.organization?.signupDomains
            ) && (
              <>
                <br />
                <br />
                <b style={{ color: "red", fontSize: "13px" }}>
                  The email domain is NOT Auto Approved. User will need manual
                  approval.
                </b>
              </>
            )}
          </div>
        </ModalWindow>
      )}
    </AdminBoxConent>
  );
};

export default SignupPendings;
