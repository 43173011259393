const delay = (timeMs: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), timeMs);
  });
};

const promiseHelper = {
  delay,
};

export default promiseHelper;
