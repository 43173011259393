import { useState } from "react";
import ImageDownload from "../../models/imageDownload";
import Navigator from "../../models/navigator";
import ProjectInfo from "../../models/projectInfo";
import imageService from "../../services/imageService";
import ImageDownloadEdit from "./ImageDownloadEdit";
import ImageDownloads from "./ImageDownloads";

const ImageDownloader: React.FC<{
  navigator: Navigator;
  project: ProjectInfo;
  onHasRunningTasks: any;
  open: Boolean;
}> = ({ navigator, project, onHasRunningTasks, open }) => {
  const [isNewImageDownload, setIsNewImageDownload] = useState<boolean>(false);
  const [isViewImageDownload, setIsViewImageDownload] =
    useState<boolean>(false);

  const [imageDownload, setImageDownload] = useState<ImageDownload>(
    new ImageDownload()
  );
  const [showListView, setShowListView] = useState<boolean>(true);
  const [showEditView, setShowEditView] = useState<boolean>(false);

  // useEffect(() => {
  //   if (open.valueOf() === true) {
  //     console.log("ImageDownloaderOpened");
  //   }
  // }, [open]);

  const closeAll = () => {
    setShowListView(false);
    setShowEditView(false);
    setIsNewImageDownload(false);
    setIsViewImageDownload(false);
  };

  const onNewImageDownload = () => {
    closeAll();

    const imageDownload = new ImageDownload();
    setImageDownload(imageDownload);

    setIsNewImageDownload(true);
    setShowEditView(true);
  };

  const onViewImageDownload = (imageDownload: ImageDownload) => {
    closeAll();

    setImageDownload(imageDownload);

    setIsViewImageDownload(true);
    setShowEditView(true);
  };

  const onCancelImageDownload = async (imageDownload: ImageDownload) => {
    await imageService.cancelImageDownload(imageDownload.idImageDownload);
  };

  const onRemoveImageDownload = async (imageDownload: ImageDownload) => {
    await imageService.removeImageDownload(imageDownload.idImageDownload);
  };

  const onCancelWindow = () => {
    closeAll();
    setShowListView(true);
  };

  const onScheduleDownload = () => {
    closeAll();
    setShowListView(true);
  };

  return (
    <div>
      {showListView && (
        <ImageDownloads
          project={project}
          onNewImageDownload={onNewImageDownload}
          onViewImageDownload={onViewImageDownload}
          onCancelImageDownload={onCancelImageDownload}
          onRemoveImageDownload={onRemoveImageDownload}
          onHasRunningTasks={onHasRunningTasks}
          refresh={open}
        />
      )}
      {showEditView && (
        <ImageDownloadEdit
          imageDownload={imageDownload}
          isNew={isNewImageDownload}
          isEdit={false}
          isView={isViewImageDownload}
          onScheduleDownload={onScheduleDownload}
          onCancel={onCancelWindow}
          navigator={navigator}
          project={project}
        />
      )}
    </div>
  );
};

export default ImageDownloader;
