import {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
} from "react";
import MapControl from "./controls/MapControl";
import { Fill, Stroke, Style } from "ol/style";
import { osm, vector, xyz } from "./source";
import { fromLonLat, get } from "ol/proj";
import GeoJSON from "ol/format/GeoJSON";
import { Feature, Map as MapObject } from "ol";
import Point from "ol/geom/Point";
import { useState } from "react";
import CircleStyle from "ol/style/Circle";
import NavigatorPosition from "../../models/navigatorPosition";
import Navigator from "../../models/navigator";
import Layers from "./layers/Layers";
import TileLayer from "./layers/TileLayer";
import VectorLayer from "./layers/VectorLayer";
import Controls from "./controls/Controls";
import FullScreenControl from "./controls/FullScreenControl";
import SessionGpsPosition from "../../models/sessionGpsPosition";
import Navigable, { NavigatorMove, SetFilters } from "../../models/navigable";
import LogService from "../../services/logService";
import LogDirectory from "../../services/logDirectory";
import mapService from "../../services/mapService";
import RoadLayer from "../../models/roadLayer";
import imageService from "../../services/imageService";
import mvt from "./source/mvt";
import VectorTileLayer from "./layers/VectorTileLayer";
import Filter from "../../models/filter";
import { transform } from "ol/proj";
import GpsPosition from "../../models/gpsPosition";
import appSettings from "../../appSettings";
import ProjectInfo from "../../models/projectInfo";
import LoadingIndicator from "../common/LoadingIndicator";
import * as olSource from "ol/source";

const logService = new LogService(LogDirectory.EnableMapLogging);

const styles = {
  PolylineCurrent: new Style({
    stroke: new Stroke({
      color: "blue",
      width: 4,
    }),
  }),
  PolylineActive: new Style({
    stroke: new Stroke({
      color: "#16B173",
      width: 4,
    }),
  }),
  PolylineInactive: new Style({
    stroke: new Stroke({
      color: "#9A656F",
      width: 4,
    }),
  }),
};

function getCurrentPositionMarker(lonLatPosition: number[]): any {
  const iconStyle = new Style({
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: "blue",
      }),
      stroke: new Stroke({
        color: "white",
        width: 3,
      }),
    }),
  });
  const feature = new Feature({
    geometry: new Point(fromLonLat(lonLatPosition)),
  });
  feature.setStyle(iconStyle);
  return feature;
}

interface Props {
  navigator: Navigator;
  project: ProjectInfo;
  // children: React.ReactNode;
}

export interface MapRef {
  refresh: () => void;
}

const Map = forwardRef((props: Props, ref: Ref<MapRef>) => {
  const { navigator, project } = props;

  const [roadLayer, setRoadLayer] = useState<RoadLayer>();
  const [center, setCenter] = useState<number[]>([]);
  const [zoom, setZoom] = useState(0);

  const [currentPosition, setCurrentPosition] = useState<number[]>();
  const [currentPositionMarker, setCurrentPositionMarker] = useState<any>();

  const [baseLayer, setBaseLayer] = useState<any>();

  const [mvtSource, setMvtSource] = useState<olSource.VectorTile | undefined>();

  const [refreshCount, setRefreshCount] = useState(0);

  const [filtersIdRoads, setFiltersIdRoads] = useState<number[]>([]);

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [noLocationFound, setNoLocationFound] = useState<boolean>(false);

  // const [sessionGpsPositions, setSessionGpsPositions] = useState<
  //   SessionGpsPosition[]
  // >([]);

  // const [currentIdSession, setCurrentIdSession] = useState<number>();

  useImperativeHandle(ref, () => ({ refresh }));

  const refresh = () => {
    logService.log("map:refresh()");
    setRefreshCount((prev) => prev + 1);
  };

  useEffect(() => {
    logService.log("map:int()");

    setZoom(13);
    setBaseLayer(osm());

    let currentIdSession: number | null = null;
    let currentIdRoad: number | null = null;
    let currentBatchSessionGpsPositions: SessionGpsPosition[] = [];
    let currentBatchSessionGpsPositionIndex: number = -1;

    const navigable = new Navigable();
    navigable.id = "map";

    if (project.hasAllRoadsMBTiles) {
      const mvtSrc = mvt({
        url: `${appSettings.serverUrl}/maptileservice/xyz/all-roads?idProject=${project.idProject}&x={x}&y={y}&z={z}`,
        minZoom: 0,
        maxZoom: 14,
      });
      setMvtSource((prevMvtSrc) => {
        if (prevMvtSrc) {
          prevMvtSrc.dispose();
        }
        return mvtSrc;
      });
    } else {
      setMvtSource((prevMvtSrc) => {
        if (prevMvtSrc) {
          prevMvtSrc.dispose();
        }
        return undefined;
      });
    }

    const loadData = async (navigatorPosition: NavigatorPosition) => {
      logService.log("map:loadData()");
      try {
        const { data: roadLayer } = await mapService.getRoadLayer(
          project.idProject,
          navigatorPosition.idRoad
        );
        setRoadLayer(roadLayer);

        const { data: sessionGpsPositionIndex } =
          await mapService.getSessionGpsPositionIndex(
            project.idProject,
            navigatorPosition.idSession,
            navigatorPosition.distance
          );

        let startIndex =
          sessionGpsPositionIndex - imageService.preloaderBackCacheSize * 35;
        if (startIndex < 0) {
          startIndex = 0;
        }
        const stopIndex = startIndex + mapService.batchSize;

        const { data: sessionGpsPositions } =
          await mapService.getSessionGpsPositions(
            project.idProject,
            navigatorPosition.idSession,
            startIndex,
            stopIndex
          );

        currentBatchSessionGpsPositions = sessionGpsPositions;

        currentBatchSessionGpsPositionIndex =
          NavigatorPosition.getClosestNavigatorPositionIndexExact(
            navigatorPosition,
            currentBatchSessionGpsPositions
          );

        if (
          currentBatchSessionGpsPositions.length === 0 ||
          currentBatchSessionGpsPositions[0].gpsPosition === null
        ) {
          return;
        }

        const lonLatPosition = [
          currentBatchSessionGpsPositions[0].gpsPosition.longitude,
          currentBatchSessionGpsPositions[0].gpsPosition.latitude,
        ];

        setCenter(lonLatPosition);
        setCurrentPositionMarker(getCurrentPositionMarker(lonLatPosition));

        logService.log("map:loadData()-done");
      } catch (error) {
        logService.log(error);
      }
    };

    const navigatorMove: NavigatorMove = async (
      navigatorPosition: NavigatorPosition
    ) => {
      logService.log("map:navigator:move()");

      if (
        currentIdSession === navigatorPosition.idSession &&
        currentIdRoad === navigatorPosition.idRoad
      ) {
        currentBatchSessionGpsPositionIndex =
          NavigatorPosition.getClosestNavigatorPositionIndexExact(
            navigatorPosition,
            currentBatchSessionGpsPositions
          );
      } else {
        currentBatchSessionGpsPositionIndex = -1;
        currentIdSession = navigatorPosition.idSession;
        currentIdRoad = navigatorPosition.idRoad;
      }

      if (currentBatchSessionGpsPositionIndex === -1) {
        await loadData(navigatorPosition);
      }

      const sessionGpsPosition =
        currentBatchSessionGpsPositions[currentBatchSessionGpsPositionIndex];

      if (sessionGpsPosition.gpsPosition !== null) {
        const lonLatPosition = [
          sessionGpsPosition.gpsPosition.longitude,
          sessionGpsPosition.gpsPosition.latitude,
        ];
        setCurrentPosition(lonLatPosition);
        setCurrentPositionMarker(getCurrentPositionMarker(lonLatPosition));
      }
    };
    navigable.navigatorMove = navigatorMove;

    const navigatorSetFilters: SetFilters = (filters: Filter[]) => {
      setFiltersIdRoads(navigator.filtersIdRoads);
      return Promise.resolve();
    };
    navigable.setFilters = navigatorSetFilters;

    navigator.attach(navigable);

    // this sould be called on open/close window
    if (
      navigator.navigatorPosition &&
      navigator.navigatorPosition.idProject === project.idProject
    ) {
      logService.log("map:navigator:move()-init");
      navigatorMove(navigator.navigatorPosition);
    }

    // setTimeout(() => {
    //   const lonlat = [-111.43163717989924, 35.85683404274522];
    //   setCurrentPosition(lonlat);
    //   setCurrentPositionMarker(getCurrentPositionMarker(lonlat));
    //   setZoom(9);
    // }, 10000);

    return () => {
      navigator.detach(navigable);
    };
  }, [navigator, project]);

  const projection = get("EPSG:3857");

  const onMapClick = async (evt: any) => {
    const lonlat = transform(evt.coordinate, "EPSG:3857", "EPSG:4326");
    const [lon, lat] = lonlat;

    const gpsPosition = new GpsPosition();
    gpsPosition.longitude = lon;
    gpsPosition.latitude = lat;

    const map = evt.map as MapObject;
    const zoom = map.getView().getZoom();
    const zoomLevel = Math.round(zoom ?? 0);

    console.log("MapClick lonlat, zoomLevel", lonlat, zoomLevel);

    setIsBusy(true);
    setNoLocationFound(false);

    const { data: navigatorPosition } =
      await mapService.getClosestNavigatorPositionForGpsPosition(
        project.idProject,
        gpsPosition,
        zoomLevel,
        []
      );
    if (navigatorPosition) {
      if (navigator.filters.length > 0) {
        const isRoadInFilters = filtersIdRoads.find(
          (filterIdRoad) => filterIdRoad === navigatorPosition.idRoad
        );
        if (!isRoadInFilters) {
          // clear filters
          await navigator.setFilters(project.idProject, []);
        }
      }

      await navigator.move(navigatorPosition);
      setIsBusy(false);
    } else {
      setIsBusy(false);
      setNoLocationFound(true);
      setTimeout(() => setNoLocationFound(false), 2000);
    }
  };

  const onChangeBasemap = (event: any) => {
    const layerType = event.target.value;

    if (layerType === "osm") {
      setBaseLayer(osm());
    }

    if (layerType === "aerial") {
      const xyzLayer = xyz({
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        maxZoom: 19,
      });
      setBaseLayer(xyzLayer);
    }
  };

  const featureStyle = useCallback(
    (feature: any) => {
      if (filtersIdRoads.length > 0) {
        const featureRoadId = feature.getId(); // or feature.getProperties()["idRoad"]
        const hasRoad = filtersIdRoads.find(
          (filterIdRoad) => filterIdRoad === featureRoadId
        );
        if (hasRoad) {
          return styles.PolylineActive;
        } else {
          return styles.PolylineInactive;
        }
      }

      return styles.PolylineActive;
    },
    [filtersIdRoads]
  );

  return (
    <div>
      <MapControl
        center={center}
        zoom={zoom}
        positionInView={currentPosition}
        refreshCount={refreshCount}
        onClick={onMapClick}
      >
        <Layers>
          <TileLayer source={baseLayer} zIndex={0} />
          {mvtSource && (
            <VectorTileLayer
              style={featureStyle}
              //style={styles.PolylineGreen}
              source={mvtSource}
              zIndex={1}
            />
          )}
          {roadLayer && projection && (
            <VectorLayer
              style={styles.PolylineCurrent}
              source={vector({
                features: new GeoJSON().readFeatures(roadLayer.geometry, {
                  featureProjection: projection,
                }),
              })}
              zIndex={2}
            />
          )}
          {currentPositionMarker && (
            <VectorLayer
              source={vector({ features: [currentPositionMarker] })}
              zIndex={3}
            />
          )}
        </Layers>
        <Controls>
          <FullScreenControl />
        </Controls>
      </MapControl>
      <div style={{ position: "absolute", top: "6px", right: "40px" }}>
        <select style={{ padding: "2px 4px" }} onChange={onChangeBasemap}>
          <option value="osm">Streets</option>
          <option value="aerial">Aerial</option>
        </select>
      </div>
      {isBusy && <LoadingIndicator className="map" />}
      {noLocationFound && (
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            right: "0px",
            zIndex: "2",
            margin: "3px",
          }}
        >
          <div
            style={{ padding: "0px 2px", margin: "0px", lineHeight: "1.5" }}
            className="alert alert-danger"
          >
            No data found at selected location!
          </div>
        </div>
      )}
    </div>
  );
});

export default Map;
