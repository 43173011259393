import React, { useEffect, useState } from "react";
import ImagePreloader from "../../models/imagePreloader";
import NavigatorPosition from "../../models/navigatorPosition";
import ImageNotAvailableImage from "../../assets/images/image-not-available-wide.png";
import ImageFrame from "../../models/imageFrame";
import Navigable, { NavigatorMove } from "../../models/navigable";
import Navigator from "../../models/navigator";
import LogService from "../../services/logService";
import LogDirectory from "../../services/logDirectory";
import imageService from "../../services/imageService";
import ImageCacheItem from "../../models/imageCacheItem";
import ProjectInfo from "../../models/projectInfo";
import Settings from "../../models/settings";

const logService = new LogService(LogDirectory.EnableCameraLogging);

const Camera: React.FC<{
  cameraName: string;
  navigator: Navigator;
  project: ProjectInfo;
  settings: Settings;
}> = ({ cameraName, navigator, project, settings }) => {
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imageOriginalUrl, setImageOriginalUrl] = useState<string>("");
  const [showCameraOrginalUrl, setShowCameraOrginalUrl] =
    useState<boolean>(false);

  useEffect(() => {
    logService.log(`camera[${cameraName}]:init()`);

    const imagePreloader: ImagePreloader = new ImagePreloader();

    let currentIdSession: number | null = null;
    let currentBatchImageFrames: ImageFrame[] = [];
    let currentBatchImageFrameIndex: number = -1;

    const navigable = new Navigable();
    navigable.id = `camera[${cameraName}]`;

    let noImages: boolean = false;

    settings.addOnShowCameraImageUrlChangedCallback((checked: boolean) =>
      setShowCameraOrginalUrl(checked)
    );

    const loadData = async (navigatorPosition: NavigatorPosition) => {
      logService.log(`camera[${cameraName}]:loadData()`);
      try {
        const { data: sessionImageFrameIndex } =
          await imageService.getImageFrameIndex(
            project.idProject,
            navigatorPosition.idSession,
            navigatorPosition.distance,
            cameraName
          );

        let startIndex =
          sessionImageFrameIndex - imageService.preloaderBackCacheSize * 10;
        if (startIndex < 0) {
          startIndex = 0;
        }
        const stopIndex = startIndex + imageService.batchSize;

        const { data: frames } = await imageService.getImageFrames(
          project.idProject,
          navigatorPosition.idSession,
          cameraName,
          startIndex,
          stopIndex
        );

        currentBatchImageFrames = frames;

        currentBatchImageFrameIndex =
          NavigatorPosition.getClosestNavigatorPositionIndexExact(
            navigatorPosition,
            currentBatchImageFrames
          );

        noImages = frames.length === 0;

        if (noImages) {
          setImageUrl(ImageNotAvailableImage);
          return;
        }

        var imageUrls = [];
        for (const frame of frames) {
          imageUrls.push(frame.imageUrl);
        }

        imagePreloader.setCameraName(cameraName);
        imagePreloader.setImageUrls(imageUrls);

        logService.log(`camera[${cameraName}]:loadData()-done`);
      } catch (error) {
        logService.log(error);
      }
    };

    const navigatorMove: NavigatorMove = async (
      navigatorPosition: NavigatorPosition
    ) => {
      logService.log(`camera[${cameraName}]:navigator:move()`);

      if (currentIdSession === navigatorPosition.idSession) {
        currentBatchImageFrameIndex =
          NavigatorPosition.getClosestNavigatorPositionIndexExact(
            navigatorPosition,
            currentBatchImageFrames
          );
      } else {
        currentBatchImageFrameIndex = -1;
        currentIdSession = navigatorPosition.idSession;
        noImages = false;
      }

      if (currentBatchImageFrameIndex === -1 && !noImages) {
        await loadData(navigatorPosition);
      }

      if (noImages) {
        return;
      }

      const imageUrl = await imagePreloader.getImage(
        currentBatchImageFrameIndex
      );
      if (imageUrl && imageUrl !== ImageCacheItem.LOADING) {
        setImageUrl(imageUrl);
        setImageOriginalUrl(
          imagePreloader.getImageOriginalUrl(currentBatchImageFrameIndex)
        );
      }

      logService.log(`camera[${cameraName}]:navigator:move()-done`);
    };
    navigable.navigatorMove = navigatorMove;

    // const navigatorMoveAllCompleted: NavigatorMoveAllCompleted = async () => {
    //   logService.log(`camera[${cameraName}]:MoveAllCompleted`);

    //   if (imageFrameIndex !== -1) {
    //     imagePreloader.preloadImages(imageFrameIndex);
    //   }
    // };
    // navigable.navigatorMoveAllCompleted = navigatorMoveAllCompleted;

    navigator.attach(navigable);

    // this sould be called on open/close window
    if (
      navigator.navigatorPosition &&
      navigator.navigatorPosition.idProject === project.idProject
    ) {
      logService.log(`camera[${cameraName}]:navigator:move()-init`);
      navigatorMove(navigator.navigatorPosition);
    }

    return () => {
      navigator.detach(navigable);
      imagePreloader.clearCache();
    };
  }, [cameraName, navigator, project, settings]);

  return (
    <div>
      {showCameraOrginalUrl && (
        <div
          style={{
            position: "absolute",
            background: "white",
            opacity: "0.8",
            zIndex: 1,
          }}
        >
          {imageOriginalUrl}
        </div>
      )}
      <img className="camera-img" src={imageUrl} alt="" />
    </div>
  );
};

export default Camera;
