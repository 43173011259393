import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import UserContext from "../../contexts/userContext";
import User from "../../models/user";

const UserNavBarItem: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useContext<User | undefined>(UserContext);

  const [nameOfUser, setNameOfUser] = useState<string>();

  useEffect(() => {
    if (!user) {
      return;
    }

    setNameOfUser(user.name);

    user.onUserInfoChanged = () => setNameOfUser(user.name);
  }, [user]);

  return (
    <div className="navbar-nav">
      <div
        className="nav-link dropdown-toggle hidden-arrow"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div data-bs-toggle="tooltip" data-bs-placement="bottom" title="User">
          <i className="navbar-nav-fa fa fa-user-circle-o fa-lg"></i>
        </div>
      </div>
      <ul className="dropdown-menu dropdown-menu-end">
        <li>
          {user?.isDemoUser && (
            <div style={{ fontWeight: "bold" }} className="dropdown-item">
              {nameOfUser}
            </div>
          )}
          {!user?.isDemoUser && (
            <button
              style={{ fontWeight: "bold" }}
              className="dropdown-item"
              type="button"
              onClick={() => {
                navigate("/profile");
              }}
            >
              {nameOfUser} Profile{" "}
              {(user?.isAdmin || user?.isPortalAdmin) && (
                <div
                  style={{
                    display: "inline-block",
                    fontSize: "8px",
                    position: "absolute",
                    right: "2px",
                    top: "2px",
                  }}
                  className="badge bg-secondary"
                >
                  {user?.isPortalAdmin ? "PORTAL ADMIN" : "ADMIN"}
                </div>
              )}
            </button>
          )}
        </li>
        {user?.isPortalAdmin && (
          <li>
            <button
              className="dropdown-item"
              type="button"
              onClick={() => {
                navigate("/admin");
              }}
            >
              Admin
            </button>
          </li>
        )}
        {location.pathname !== "/projects" && (
          <li>
            <button
              className="dropdown-item"
              type="button"
              onClick={() => {
                navigate("/projects");
              }}
            >
              Projects
            </button>
          </li>
        )}
        <li>
          <button
            className="dropdown-item"
            type="button"
            onClick={() => {
              navigate("/logout");
            }}
          >
            Logout
          </button>
        </li>
      </ul>
    </div>
  );
};

export default UserNavBarItem;
