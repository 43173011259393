export interface ICompararable<T> {
  compareTo: (other: T) => boolean;
}

export default class Range<T = number> {
  private lowerBound: T;
  private upperBound: T;

  public getLowerBound() {
    return this.lowerBound;
  }

  public getUpperBound() {
    return this.upperBound;
  }

  constructor(lowerBound: T, upperBound: T, isInverse: boolean = false) {
    if (!isInverse && this.compareTo(lowerBound, upperBound) > 0) {
      const bound: T = lowerBound;
      lowerBound = upperBound;
      upperBound = bound;
    }

    this.lowerBound = lowerBound;
    this.upperBound = upperBound;
  }

  public contains(value: T): boolean {
    return (
      this.compareTo(this.lowerBound, value) <= 0 &&
      this.compareTo(this.upperBound, value) >= 0
    );
  }

  public overlaps(value: Range<T>) {
    return (
      value &&
      (this.contains(value.lowerBound) ||
        this.contains(value.upperBound) ||
        value.contains(this.lowerBound) ||
        value.contains(this.upperBound))
    );
  }

  public compareTo(x: T, y: T): number {
    // LessThan=-1, Equal=0, GreaterThan=1

    if (x < y) {
      return -1;
    } else if (x > y) {
      return 1;
    }

    // are equal
    return 0;
  }
}
