import SignupDomain from "./signupDomain";
import UserGroup from "./userGroup";

export default class Organization {
  public idOrganization: number = 0;
  public organizationName: string = "";
  public organizationCode: string = "";
  public idUserGroupSignup: number = 0;
  public signupUserGroup: UserGroup | undefined = undefined;
  public isPaidSubscription: boolean = false;
  public subscriptionRenewalDate: Date | undefined = undefined;
  public goLiveDate: Date | undefined = undefined;
  public signupDomains: SignupDomain[] = [];
}
