import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormMessage from "../components/common/FormMessage";
import LoginBoxContent from "../components/common/LoginBoxContent";
import MotionDiv from "../components/common/MotionDiv";
import userService from "../services/userService";
import formHelper from "../util/formHelper";

import pageHelper from "../util/pageHelper";
import RoutePaths from "./routePaths";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    console.log("forgotPasswordPageInit");
    pageHelper.hideScrollbars();
  }, []);

  const clearError = () => {
    setErrorMessage("");
    setMessage("");
  };

  const onSumbit = async (event: any) => {
    event.preventDefault();

    clearError();

    if (formHelper.checkEmpty(email, "Email", setErrorMessage)) {
      return;
    }

    // all good

    setIsBusy(true);

    try {
      const { data } = await userService.forgotPassword(
        email,
        RoutePaths.getResetPasswordRouteUrl()
      );
      setIsBusy(false);
      setMessage(data);
    } catch (error: any) {
      setIsBusy(false);
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
    }
  };

  return (
    <MotionDiv>
      <LoginBoxContent>
        <div className="mb-3 mt-4">
          <h4>Forgot Password</h4>
        </div>
        <form className="input-form" onSubmit={onSumbit}>
          <div className="row">
            <div className="mb-3">
              <label htmlFor="usernameOrEmail" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="usernameOrEmail"
                onChange={(event) =>
                  formHelper.onFieldChanged(event, setEmail, clearError)
                }
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <Link to="/login">Back to Login</Link>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isBusy}
              >
                Forgot Password
              </button>
            </div>
          </div>
          {/* <Link to="/reset-password">Reset Password Page</Link> */}
          <FormMessage
            message={message}
            errorMessage={errorMessage}
            isBusy={isBusy}
          />
        </form>
      </LoginBoxContent>
    </MotionDiv>
  );
};

export default ForgotPassword;
