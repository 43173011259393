import { Tooltip } from "bootstrap";

const initTooltips = () => {
  Array.from(
    document.querySelectorAll('button[data-bs-toggle="tooltip"]')
  ).forEach((tooltipNode) => new Tooltip(tooltipNode, { trigger: "hover" }));

  Array.from(
    document.querySelectorAll('div[data-bs-toggle="tooltip"]')
  ).forEach((tooltipNode) => new Tooltip(tooltipNode, { trigger: "hover" }));

  Array.from(
    document.querySelectorAll('img[data-bs-toggle="tooltip"]')
  ).forEach((tooltipNode) => new Tooltip(tooltipNode, { trigger: "hover" }));

  Array.from(document.querySelectorAll('i[data-bs-toggle="tooltip"]')).forEach(
    (tooltipNode) => new Tooltip(tooltipNode, { trigger: "hover" })
  );
};

const tooltips = {
  initTooltips,
};

export default tooltips;
