import Settings from "../models/settings";
import httpService from "./httpService";

const apiEndpoint = "/settings";

const getSettings = async (defaultSettings: Settings): Promise<Settings> => {
  const { data: settings } = await httpService.post<Settings>(
    `${apiEndpoint}/getSettings`,
    defaultSettings
  );
  if (settings) {
    Object.setPrototypeOf(settings, Settings.prototype);

    // init lists
    settings.onShowCameraImageUrlChangedCallbacks = [];
  }

  return Promise.resolve(settings);
};

const saveSettings = (settings: Settings) => {
  return httpService.post(`${apiEndpoint}/saveSettings`, settings);
};

const settingService = {
  getSettings,
  saveSettings,
};

export default settingService;
