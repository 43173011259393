import ProjectLocatorField from "../models/projectLocatorField";
import httpService from "./httpService";

const apiEndpoint = "/locators";

const getLocatorFields = (idProject: number) => {
  return httpService.get<ProjectLocatorField[]>(
    `${apiEndpoint}/getLocatorFields?idProject=${idProject}`
  );
};

const getLocators = (idProject: number) => {
  return httpService.get(`${apiEndpoint}/getLocators?idProject=${idProject}`);
};

const locatorSerice = {
  getLocatorFields,
  getLocators,
};

export default locatorSerice;
