import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FormMessage from "../components/common/FormMessage";
import LoginBoxContent from "../components/common/LoginBoxContent";
import MotionDiv from "../components/common/MotionDiv";
import SignupInfo from "../models/signupInfo";
import userService from "../services/userService";
import formHelper from "../util/formHelper";

import pageHelper from "../util/pageHelper";
import RoutePaths from "./routePaths";

const Signup: React.FC = () => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [organizationCode, setOrganizationCode] = useState<string>("");

  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    console.log("signupPageInit");
    pageHelper.hideScrollbars();
  }, []);

  const clearError = () => {
    setErrorMessage("");
    setMessage("");
  };

  const onSumbit = async (event: any) => {
    event.preventDefault();

    clearError();

    if (
      formHelper.checkEmpty(firstName, "First Name", setErrorMessage) ||
      formHelper.checkEmpty(lastName, "Last Name", setErrorMessage) ||
      formHelper.checkEmpty(username, "Email", setErrorMessage) ||
      formHelper.checkEmpty(password, "Password", setErrorMessage) ||
      formHelper.checkEmpty(
        confirmPassword,
        "Confirm Password",
        setErrorMessage
      )
    ) {
      return;
    }

    if (!organizationCode) {
      setErrorMessage("Organization Code is required");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Password and Confirm Password don't match");
      return;
    }

    // all good

    const formData: SignupInfo = {
      firstName,
      lastName,
      username,
      email: username,
      password,
      organizationCode,
      accountConfirmationUrl: RoutePaths.getAccountConfirmationUrl(),
    };

    setIsBusy(true);

    try {
      const { data } = await userService.signup(formData);
      setIsBusy(false);
      setMessage(data);
    } catch (error: any) {
      setIsBusy(false);
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
    }
  };

  return (
    <MotionDiv>
      <LoginBoxContent>
        <div className="mb-3">
          <h4>Signup</h4>
        </div>
        <form className="input-form" onSubmit={onSumbit}>
          <div className="row">
            <div className="d-flex mb-3">
              <div style={{ marginRight: "10px" }}>
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  onChange={(event) =>
                    formHelper.onFieldChanged(event, setFirstName, clearError)
                  }
                />
              </div>
              <div>
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  onChange={(event) =>
                    formHelper.onFieldChanged(event, setLastName, clearError)
                  }
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="username"
                onChange={(event) =>
                  formHelper.onFieldChanged(event, setUsername, clearError)
                }
              />
            </div>
            <div className="d-flex mb-3">
              <div style={{ marginRight: "10px" }}>
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  onChange={(event) =>
                    formHelper.onFieldChanged(event, setPassword, clearError)
                  }
                />
              </div>
              <div>
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  onChange={(event) =>
                    formHelper.onFieldChanged(
                      event,
                      setConfirmPassword,
                      clearError
                    )
                  }
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="organizationCode" className="form-label">
                Organization Code
              </label>
              <input
                type="text"
                className="form-control"
                id="organizationCode"
                onChange={(event) =>
                  formHelper.onFieldChanged(
                    event,
                    setOrganizationCode,
                    clearError
                  )
                }
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <Link to="/login">Back to Login</Link>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isBusy}
              >
                Signup
              </button>
            </div>
          </div>
          {/* <Link to={RoutePaths.AccountConfirmationRoutePath}>
            Account Confirmation Page
          </Link> */}
          <FormMessage
            message={message}
            errorMessage={errorMessage}
            isBusy={isBusy}
          />
        </form>
      </LoginBoxContent>
    </MotionDiv>
  );
};

export default Signup;
