import React, { useRef, useState, useEffect } from "react";
import MapContext from "../contexts/mapContext";
import { transformExtent, transform, fromLonLat } from "ol/proj";
import * as ol from "ol";
import LogService from "../../../services/logService";
import LogDirectory from "../../../services/logDirectory";

const logService = new LogService(LogDirectory.EnableMapLogging);

const MapControl: React.FC<{
  children: any;
  zoom: any;
  center: any;
  positionInView: any;
  refreshCount: any;
  onClick?: any | undefined;
}> = ({ children, zoom, center, positionInView, refreshCount, onClick }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<ol.Map>();

  useEffect(() => {
    if (map) {
      map.updateSize();
    }
  }, [refreshCount, map]);

  // on component mount
  useEffect(() => {
    logService.log("mapControl:init()");

    let options = {
      view: new ol.View(),
      layers: [],
      controls: [],
      overlays: [],
    };

    let mapObject = new ol.Map(options);

    if (mapRef.current !== null) {
      mapObject.setTarget(mapRef.current);
      setMap(mapObject);
    }

    return () => mapObject.setTarget(undefined);
  }, []);

  useEffect(() => {
    if (!map) {
      return;
    }
    map.on("singleclick", onClick);

    return () => map.un("singleclick", onClick);
  }, [map, onClick]);

  // zoom change handler
  useEffect(() => {
    if (!map) {
      return;
    }

    if (!zoom) {
      return;
    }

    logService.log("mapControl:setZoom()");

    map.getView().setZoom(zoom);
  }, [map, zoom]);

  // center change handler
  useEffect(() => {
    if (!map) {
      return;
    }

    if (center.length === 0) {
      return;
    }

    logService.log("mapControl:setCenter()");

    let centerTransform = fromLonLat(center);
    map.getView().setCenter(centerTransform);
  }, [map, center]);

  // center change handler
  useEffect(() => {
    if (!map) {
      return;
    }

    if (!positionInView) {
      return;
    }

    logService.log("mapControl:checkPositionInView()");

    let lon = positionInView[0];
    let lat = positionInView[1];

    let extent = map.getView().calculateExtent(map.getSize());
    extent = transformExtent(extent, "EPSG:3857", "EPSG:4326");

    let isLonInExtent = extent[0] < lon && lon < extent[2];
    let isLatInExtent = extent[1] < lat && lat < extent[3];
    let isLonLatInExtent = isLonInExtent && isLatInExtent;

    if (!isLonLatInExtent) {
      logService.log("mapControl:setPositionInView()");
      map.getView().setCenter(transform([lon, lat], "EPSG:4326", "EPSG:3857"));
    }
  }, [map, positionInView]);

  return (
    <MapContext.Provider value={map}>
      <div ref={mapRef} className="ol-map">
        {children}
      </div>
    </MapContext.Provider>
  );
};
export default MapControl;
