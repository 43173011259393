const linearInterpolation = (
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  x: number
) => {
  if (x1 - x0 === 0) {
    return (y0 + y1) / 2;
  }
  return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0);
};

const interpolation = {
  linearInterpolation,
};

export default interpolation;
