import React, { useEffect, useState } from "react";
import Filter from "../../models/filter";
import ProjectLocatorField from "../../models/projectLocatorField";
import Navigator from "../../models/navigator";
import ProjectInfo from "../../models/projectInfo";
import locatorSerice from "../../services/locatorService";
import LogDirectory from "../../services/logDirectory";
import LogService from "../../services/logService";
import roadService from "../../services/roadService";
import Dropdown from "../common/Dropdown";
import Navigable, { NavigatorMove, SetFilters } from "../../models/navigable";
import NavigatorPosition from "../../models/navigatorPosition";
import ProjectLocatorValue from "../../models/projectLocatorValue";

const logService = new LogService(LogDirectory.EnableLocatorsLogging);

const starValue = "*";
const starOption = { value: starValue, label: starValue };

const Locators: React.FC<{ navigator: Navigator; project: ProjectInfo }> = ({
  navigator,
  project,
}) => {
  const [locatorFields, setLocatorFields] = useState<ProjectLocatorField[]>([]);
  const [locators, setLocators] = useState<any>();

  const [locatorsValuesMap, setLocatorsValuesMap] = useState<
    Map<string, string[]>
  >(new Map<string, string[]>());

  const [selectedOptions, setSelectedOptions] = useState<any>({});

  const [currentLocatorValues, setCurrentLocatorValues] = useState<
    ProjectLocatorValue[]
  >([]);

  useEffect(() => {
    let isDisposed: boolean = false;

    logService.log("locators:init()");

    const navigable = new Navigable();
    navigable.id = "locators";

    let _locatorFileds: ProjectLocatorField[] = [];

    const init = async () => {
      const { data: locatorFields } = await locatorSerice.getLocatorFields(
        project.idProject
      );
      setLocatorFields(locatorFields);
      _locatorFileds = locatorFields;

      if (isDisposed) {
        return;
      }

      const { data: locators } = await locatorSerice.getLocators(
        project.idProject
      );
      setLocators(locators);

      if (isDisposed) {
        return;
      }

      const newSelectedOptions = getClearFiltersOptions(locatorFields);
      setSelectedOptions(newSelectedOptions);

      updateLocatorsValuesMap(locators, locatorFields, newSelectedOptions);
    };

    init();

    const navigatorMove: NavigatorMove = async (
      navigatorPosition: NavigatorPosition
    ) => {
      logService.log("locators:navigator:move()");

      const locatorValues = roadService.getLocatorValues(
        navigatorPosition,
        navigator.roadLocatorSegments
      );
      setCurrentLocatorValues(locatorValues);
    };
    navigable.navigatorMove = navigatorMove;

    const setFilters: SetFilters = async (filters: Filter[]) => {
      if (filters.length === 0) {
        const newSelectedOptions = getClearFiltersOptions(_locatorFileds);
        setSelectedOptions(newSelectedOptions);
      } else {
        // TODO
      }

      return Promise.resolve();
    };
    navigable.setFilters = setFilters;

    navigator.attach(navigable);

    return () => {
      logService.log("locators:uninit()");
      navigator.detach(navigable);
      isDisposed = true;
    };
  }, [navigator, project]);

  const getClearFiltersOptions = (locatorFields: ProjectLocatorField[]) => {
    // const selectedOptions = {
    //   COUNTY_NAME: { value: "*", label: "*" },
    //   RoadName: { value: "*", label: "*" },
    //   CARDINAL_DIR: { value: "*", label: "*" },
    // };

    let newSelectedOptions: any;
    for (const locatorField of locatorFields) {
      newSelectedOptions = { ...newSelectedOptions };
      newSelectedOptions[locatorField.displayName] = starOption;
    }

    return newSelectedOptions;
  };

  const updateLocatorsValuesMap = (
    locators: any,
    locatorFields: ProjectLocatorField[],
    selectedLocatorsValues: any
  ) => {
    const locatorsValuesMap: Map<string, string[]> = new Map<
      string, // locator name
      string[] // locator values
    >();

    for (const locatorField of locatorFields) {
      locatorsValuesMap.set(locatorField.displayName, []);
    }

    for (const locator of locators) {
      let locatorIndex = 0;

      for (const locatorField of locatorFields) {
        let locatorValue = locator[locatorField.displayName];

        if (typeof locatorValue === "boolean") {
          locatorValue = locatorValue ? "True" : "False";
        }

        const locatorValueMap = locatorsValuesMap.get(locatorField.displayName);

        if (locatorValue === undefined) {
          locatorIndex++;
          continue;
        }

        if (locatorIndex === 0) {
          // distinct
          if (
            locatorValueMap?.find((lv) => lv === locatorValue) === undefined
          ) {
            locatorValueMap?.push(locatorValue);
          }
        } else {
          let canAdd: boolean = true;
          for (let i = 0; i < locatorIndex; i++) {
            const locatorFieldAtIndex = locatorFields[i];
            if (locatorFieldAtIndex) {
              const beforeLocatorValue =
                locator[locatorFieldAtIndex.displayName];
              const beforeLocatorFilterValue =
                selectedLocatorsValues[locatorFieldAtIndex.displayName].value;

              const ok =
                beforeLocatorFilterValue === starValue ||
                beforeLocatorValue === beforeLocatorFilterValue;

              if (!ok) {
                canAdd = false;
                break;
              }
            }
          }
          if (canAdd) {
            // distinct
            if (
              locatorValueMap?.find((lv) => lv === locatorValue) === undefined
            ) {
              locatorValueMap?.push(locatorValue);
            }
          }
        }

        locatorIndex++;
      }
    }

    // sort values

    const locatorsValuesMapSorted: Map<string, string[]> = new Map<
      string,
      string[]
    >();

    locatorsValuesMap.forEach((value, key) => {
      var sortedValues = value.sort();
      locatorsValuesMapSorted.set(key, sortedValues);
    });

    setLocatorsValuesMap(locatorsValuesMapSorted);
  };

  // const options = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ];

  const getLocatorOptions = (locatorValues: string[] | undefined) => {
    const options: any[] = [];

    options.push(starOption);

    locatorValues?.forEach((value) => {
      const option = {
        value: value,
        label: value,
      };
      options.push(option);
    });

    return options;
  };

  const onChage = async (index: number, newValue: any) => {
    const newSelectedOptions = { ...selectedOptions };

    for (let i = 0; i < locatorFields.length; i++) {
      if (i === index) {
        newSelectedOptions[locatorFields[i].displayName] = newValue;
      } else if (i > index) {
        newSelectedOptions[locatorFields[i].displayName] = starOption;
      }
    }
    setSelectedOptions(newSelectedOptions);

    updateLocatorsValuesMap(locators, locatorFields, newSelectedOptions);

    const filters = selectedOptionsToFilters(newSelectedOptions);

    await navigator.setFilters(project.idProject, filters);

    logService.log("locators:filters-applied", filters);

    let { data: navigatorPosition } =
      await roadService.getFirstNavigatorPositionForFilters(
        project.idProject,
        filters,
        true,
        navigator.navigatorPosition
      );

    logService.log(
      "locators:firstNavigatorPositionForFilters",
      navigatorPosition
    );

    navigator.move(navigatorPosition);
  };

  const selectedOptionsToFilters = (selectedOptions: any): Filter[] => {
    const filters: Filter[] = [];

    for (let i = 0; i < locatorFields.length; i++) {
      const selectedOptionName = locatorFields[i].displayName;
      const selectedOptionValue = selectedOptions[selectedOptionName];
      if (selectedOptionValue.value !== starValue) {
        const filter = new Filter();
        filter.fieldName = locatorFields[i].fieldName;
        filter.fieldValue = selectedOptionValue.value;
        filters.push(filter);
      }
    }

    return filters;
  };

  const getSelectedOption = (locatorField: ProjectLocatorField) => {
    const value = selectedOptions[locatorField.displayName];
    return value;
  };

  return (
    <div style={{ display: "flex" }}>
      {locatorFields.map((item, index) => (
        <div
          key={index.toString()}
          className="menu-dropdown menu-item-spacing-right"
        >
          <div className="locators-title">{item.displayName}</div>
          <Dropdown
            options={getLocatorOptions(locatorsValuesMap.get(item.displayName))}
            value={getSelectedOption(item)}
            onChange={(newValue: any) => onChage(index, newValue)}
          />
          <div
            style={{
              // position: "absolute",
              // bottom: "-4px",
              fontSize: "10px",
              marginLeft: "4px",
              marginTop: "-2px",
              marginBottom: "-17px",
              fontWeight: "bold",
            }}
          >
            {currentLocatorValues.find(
              (loc) => loc.projectLocatorField?.displayName === item.displayName
            )?.value ?? <>&nbsp;</>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Locators;
