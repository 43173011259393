import ProjectLocatorValue from "./projectLocatorValue";

export default class RoadLocatorSegment {
  public idProject: number = 0;
  public idSegment: number = 0;
  public idRoad: number = 0;
  public idSession: number = 0;
  public startDistance: number = 0;
  public endDistance: number = 0;
  public startChainage: number = 0;
  public endChainage: number = 0;
  public isUpChainage: boolean = false;
  public locatorValues: ProjectLocatorValue[] = [];

  public isEqual(roadLocatorSegment: RoadLocatorSegment): boolean {
    const isEqual =
      roadLocatorSegment.idProject === this.idProject &&
      roadLocatorSegment.idSegment === this.idProject &&
      roadLocatorSegment.idRoad === this.idRoad &&
      roadLocatorSegment.idSession === this.idSession &&
      roadLocatorSegment.startDistance === this.startDistance &&
      roadLocatorSegment.endDistance === this.endDistance &&
      roadLocatorSegment.startChainage === this.startChainage &&
      roadLocatorSegment.endChainage === this.endChainage &&
      roadLocatorSegment.isUpChainage === this.isUpChainage &&
      roadLocatorSegment.locatorValues === this.locatorValues;
    return isEqual;
  }

  public static clone(
    roadLocatorSegment: RoadLocatorSegment
  ): RoadLocatorSegment {
    const newRoadLocatorSegment = new RoadLocatorSegment();
    newRoadLocatorSegment.idProject = roadLocatorSegment.idProject;
    newRoadLocatorSegment.idSegment = roadLocatorSegment.idSegment;
    newRoadLocatorSegment.idRoad = roadLocatorSegment.idRoad;
    newRoadLocatorSegment.idSession = roadLocatorSegment.idSession;
    newRoadLocatorSegment.startDistance = roadLocatorSegment.startDistance;
    newRoadLocatorSegment.endDistance = roadLocatorSegment.endDistance;
    newRoadLocatorSegment.startChainage = roadLocatorSegment.startChainage;
    newRoadLocatorSegment.endChainage = roadLocatorSegment.endChainage;
    newRoadLocatorSegment.isUpChainage = roadLocatorSegment.isUpChainage;
    newRoadLocatorSegment.locatorValues = roadLocatorSegment.locatorValues;
    return newRoadLocatorSegment;
  }
}
