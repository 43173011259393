import { useNavigate } from "react-router-dom";
import AdminBoxConent from "../../components/admin/AdminBoxContent";
import Moment from "react-moment";
import dateTimeHelper from "../../util/dateTimeHelper";
import { useEffect, useState } from "react";
import User from "../../models/user";
import userService from "../../services/userService";
import Organization from "../../models/organization";
import EditIcon from "@mui/icons-material/Edit";

const Users: React.FC<{ organization: Organization | undefined }> = ({
  organization,
}) => {
  const navigate = useNavigate();

  const [users, setUsers] = useState<User[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [organizationUsers, setOrganizationUsers] = useState<User[]>([]);

  const [showAllUsers, setShowAllUsers] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    const init = async () => {
      let { data: users } = await userService.getUsers();
      setAllUsers(users);
    };

    init();
  }, []);

  useEffect(() => {
    if (!organization) {
      return;
    }

    if (!showAllUsers) {
      const organizationUsers: User[] = [];

      for (const user of allUsers) {
        let found = false;
        for (const usergroup of user.userGroups) {
          for (const org of usergroup.organizations) {
            if (org.idOrganization === organization.idOrganization) {
              organizationUsers.push(user);
              found = true;
              break;
            }
          }
          if (found) {
            break;
          }
        }
      }

      setOrganizationUsers(organizationUsers);

      setUsers(organizationUsers);
    } else {
      setUsers(allUsers);
    }
  }, [allUsers, organization, showAllUsers]);

  const onShowAllUsersChanged = () => {
    const newValue = !showAllUsers;
    setShowAllUsers(newValue);

    setSearchText("");
  };

  const onSearchTextChange = (event: any) => {
    const value = event.target.value;
    setSearchText(value);

    const searchToken = event.target.value.toLowerCase();

    let searchInUsers: User[] = [];

    if (showAllUsers) {
      searchInUsers = allUsers;
    } else {
      searchInUsers = organizationUsers;
    }

    const filteredUsers = searchInUsers.filter(
      (u) =>
        getUserOrganizationName(u).toLowerCase().includes(searchToken) ||
        u.email.toLowerCase().includes(searchToken) ||
        u.firstName.toLowerCase().includes(searchToken) ||
        u.lastName.toLowerCase().includes(searchToken)
    );

    setUsers(filteredUsers);
  };

  const getUserOrganizationName = (user: User) => {
    return user.userGroups[0].organizations[0].organizationName;
  };

  const onEditUser = (user: User) => {
    navigate(`/admin/users/${user.idUser}`);
  };

  return (
    <AdminBoxConent
      title="Users"
      hasCount={true}
      count={users.length}
      countType="users"
      hasAddNew={false}
      hasCurrentOrganizationDropdown={!showAllUsers}
    >
      <div className="mb-3">
        <div className="d-flex align-items-center">
          <label className="form-check-label" htmlFor="showAllUsers">
            Show All Users
          </label>
          <div className="form-switch form-switch-right-text">
            <input
              className="form-check-input form-switch-right-text"
              type="checkbox"
              checked={showAllUsers}
              onChange={onShowAllUsersChanged}
              id="showAllUsers"
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={searchText}
            onChange={onSearchTextChange}
          />
          <button type="submit" className="btn btn-primary">
            Search
          </button>
        </div>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Organization Name</th>
            <th scope="col">Email</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Last Login Date</th>
            <th scope="col">Last Activity Date</th>
            <th scope="col">Create Date</th>
            <th style={{ width: "50px" }} />
          </tr>
        </thead>
        <tbody>
          {users.map((user: User, index) => (
            <tr key={index.toString()}>
              <td>{getUserOrganizationName(user)}</td>
              <td>{user.email}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>
                {user.lastLoginDate && (
                  <Moment format={dateTimeHelper.dateTimeFormat}>
                    {user.lastLoginDate}
                  </Moment>
                )}
              </td>
              <td>
                {user.lastActivityDate && (
                  <Moment format={dateTimeHelper.dateTimeFormat}>
                    {user.lastActivityDate}
                  </Moment>
                )}
              </td>
              <td>
                {user.createDate && (
                  <Moment format={dateTimeHelper.dateTimeFormat}>
                    {user.createDate}
                  </Moment>
                )}
              </td>
              <td>
                <EditIcon
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  fontSize="small"
                  onClick={() => onEditUser(user)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AdminBoxConent>
  );
};

export default Users;
