import { useNavigate, useParams } from "react-router-dom";
import BoxContent from "../../components/common/BoxContent";
import { useEffect, useState } from "react";
import SignupDomain from "../../models/signupDomain";
import ValidatorManager from "../../components/common/validationManager";
import organizationService from "../../services/organizationService";
import formHelper from "../../util/formHelper";
import Validator from "../../components/common/Validator";
import Organization from "../../models/organization";
import ModalWindow from "../../components/common/ModalWindow";

const validator = {
  domainName: "domainName",
};

export const SignupDomainEdit: React.FC<{
  organization: Organization | undefined;
}> = ({ organization }) => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>();

  const [showDeleteWindow, setShowDeleteWindow] = useState<boolean>(false);

  const [validatorManager] = useState<ValidatorManager>(new ValidatorManager());

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [signupDomain, setSignupDomain] = useState<SignupDomain>(
    new SignupDomain()
  );

  useEffect(() => {
    const initEdit = async (idSignupDomain: number) => {
      const { data: signupDomain } =
        await organizationService.getSignupDomainById(idSignupDomain);

      setSignupDomain(signupDomain);

      validatorManager.setIsValid(validator.domainName, true);
    };

    const isNew = idParam === "new";
    setIsNew(isNew);

    if (!isNew) {
      const idSignupDomain = Number(idParam);
      initEdit(idSignupDomain);
    } else {
      if (organization) {
        const newSignupDomain = new SignupDomain();
        newSignupDomain.idOrganization = organization.idOrganization;
        setSignupDomain(newSignupDomain);
      }
    }
  }, [idParam, validatorManager, organization]);

  const onDomainNameChanged = (event: any) => {
    const newValue = event.target.value;

    const newSignupDomain = {
      ...signupDomain,
      domainName: newValue,
    };

    setSignupDomain(newSignupDomain);

    const isValid = newValue.length !== 0;
    validatorManager.setIsValid(validator.domainName, isValid);
  };

  const onIsAutoApprovedChanged = () => {
    const newValue = !signupDomain.isAutoApproved;

    const newSignupDomain = {
      ...signupDomain,
      isAutoApproved: newValue,
    };

    setSignupDomain(newSignupDomain);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    if (!validatorManager.validate()) {
      return;
    }

    try {
      if (isNew) {
        await organizationService.createSignupDomain(signupDomain);
      } else {
        await organizationService.updateSignupDomain(signupDomain);
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    navigateAway();
  };

  const onDeleteConfirm = () => {
    setShowDeleteWindow(true);
  };

  const onDeleteOk = async () => {
    setShowDeleteWindow(false);
    await organizationService.deleteSignupDomain(signupDomain.idSignupDomain);
    navigateAway();
  };

  const onDeleteCancel = () => {
    setShowDeleteWindow(false);
  };

  const onCancel = () => {
    navigateAway();
  };

  const navigateAway = () => {
    navigate("/admin/signup-domains");
  };

  return (
    <BoxContent
      title={(isNew ? "New" : "Edit") + " Signup Domain"}
      useFullWidth
    >
      <div className="d-flex mb-3">
        <div style={{ fontWeight: "bold", marginRight: "10px" }}>
          Organizaiton
        </div>
        <div>{organization?.organizationName}</div>
      </div>
      <form
        className="input-form"
        onSubmit={onSubmit}
        onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
      >
        <div className="row">
          <div className="mb-3">
            <label htmlFor="domainName" className="form-label">
              Domain Name
            </label>
            <div className="input-group">
              <span className="input-group-text">@</span>
              <Validator
                name={validator.domainName}
                errorMessage="Please enter an domain name."
                validationManager={validatorManager}
              >
                <input
                  type="text"
                  className="form-control"
                  id="domainName"
                  value={signupDomain.domainName}
                  onChange={onDomainNameChanged}
                />
              </Validator>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex align-items-center">
              <label className="form-check-label" htmlFor="isAutoApproved">
                Is Auto Approved
              </label>
              <div className="form-switch form-switch-right-text">
                <input
                  className="form-check-input form-switch-right-text"
                  type="checkbox"
                  checked={signupDomain.isAutoApproved}
                  onChange={onIsAutoApprovedChanged}
                  id="isAutoApproved"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <button type="submit" className="btn btn-primary">
              {isNew ? "Create" : "Save"}
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              style={{ marginLeft: "10px" }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <div className="col-md-12 text-center mt-3">
              {errorMessage !== "" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
          </div>
          {!isNew && (
            <div>
              <button
                type="button"
                className="btn btn-danger"
                onClick={onDeleteConfirm}
              >
                Delete
              </button>
            </div>
          )}
        </div>
      </form>
      {showDeleteWindow && (
        <ModalWindow title="Delete" onOk={onDeleteOk} onCancel={onDeleteCancel}>
          <div>{`Are you sure you want to delete the signup domain: @${signupDomain?.domainName}?`}</div>
        </ModalWindow>
      )}
    </BoxContent>
  );
};
