import imageService from "../services/imageService";
import LogDirectory from "../services/logDirectory";
import LogService from "../services/logService";
import interpolation from "../util/interpolation";
import ImageFrame from "./imageFrame";
import Navigable from "./navigable";
import Navigator from "./navigator";
import NavigatorPosition from "./navigatorPosition";
import ProjectInfo from "./projectInfo";
import RoadLocatorSegment from "./roadLocatorSegment";

//const playbackBatchSize = imageService.batchSize;
const playbackBatchSize = 1000;

export default class PlaybackManager {
  private logService: LogService = new LogService(
    LogDirectory.EnablePlaybackLogging
  );

  private timerIntervalMs: number = 140;

  private navigator: Navigator;
  private navigable: Navigable;

  private project: ProjectInfo;

  private isPlaying: boolean = false;

  private currentRoadLocatorSegmentIndex: number = -1;
  private currentRoadLocatorSegment: RoadLocatorSegment | null = null;

  private cameraName: string = "";
  private cameraNamePreferred: string = "";

  private currentIdSession: number | null = null;
  private currentBatchImageFrames: ImageFrame[] = [];
  private currentBatchImageFrameIndex: number = -1;

  private pageCount: number = 0;
  private currentPageIndex: number = -1;

  private timerId: any;

  constructor(
    navigator: Navigator,
    navigable: Navigable,
    project: ProjectInfo
  ) {
    this.navigator = navigator;
    this.navigable = navigable;
    this.project = project;
  }

  public setCameraName(cameraName: string) {
    this.cameraNamePreferred = cameraName;
    this.cameraName = cameraName;
  }

  public onStop: any;

  public async move(navigatorPosition: NavigatorPosition) {
    this.logService.log("playback:move", navigatorPosition);

    this.currentRoadLocatorSegmentIndex =
      this.navigator.roadLocatorSegmentIndex;
    this.currentRoadLocatorSegment = this.navigator.roadLocatorSegment;

    if (this.currentIdSession === navigatorPosition.idSession) {
      this.logService.log("playback:sameSession", this.currentIdSession);

      // console.log(
      //   "playback-move-this.currentBatchImageFrameIndex before",
      //   this.currentBatchImageFrameIndex
      // );

      this.currentBatchImageFrameIndex =
        NavigatorPosition.getClosestNavigatorPositionIndexExact(
          navigatorPosition,
          this.currentBatchImageFrames
        );

      if (this.currentBatchImageFrameIndex !== -1) {
        this.currentBatchImageFrameIndex++;

        if (
          this.currentBatchImageFrameIndex >=
          this.currentBatchImageFrames.length
        ) {
          this.currentBatchImageFrameIndex =
            this.currentBatchImageFrames.length - 1;
        }
      }

      // console.log(
      //   "playback-move-this.currentBatchImageFrameIndex after",
      //   this.currentBatchImageFrameIndex
      // );
    } else {
      this.logService.log(
        "playback:differentSession",
        navigatorPosition.idSession
      );
      this.currentBatchImageFrameIndex = -1;
      this.currentIdSession = navigatorPosition.idSession;
      this.cameraName = this.cameraNamePreferred;
    }

    if (this.currentBatchImageFrameIndex === -1) {
      this.logService.log("playback:move:loadData");
      await this.loadData(navigatorPosition);
    }
  }

  private async loadData(navigatorPosition: NavigatorPosition) {
    if (!this.currentRoadLocatorSegment) {
      return;
    }

    // console.log("loadData() called");

    let sessionImageFramesCount = 0;

    const { data: sessionImageFramesCountResponse } =
      await imageService.getImageFramesCount(
        this.project.idProject,
        navigatorPosition.idSession,
        this.cameraName,
        this.currentRoadLocatorSegment.startDistance,
        this.currentRoadLocatorSegment.endDistance
      );
    sessionImageFramesCount = sessionImageFramesCountResponse;
    this.logService.log(
      "playback:loadData:getImageFramesCount",
      sessionImageFramesCount
    );

    if (sessionImageFramesCount === 0) {
      const { data: imageStreamFramesCounts } =
        await imageService.getImageStreamsFramesCount(
          this.project.idProject,
          navigatorPosition.idSession,
          this.currentRoadLocatorSegment.startDistance,
          this.currentRoadLocatorSegment.endDistance
        );
      this.logService.log(
        "playback:loadData:getImageStreamsFramesCount",
        imageStreamFramesCounts
      );

      const imageStreamWithImages = imageStreamFramesCounts.filter(
        (s) => s.framesCount > 0
      );
      if (imageStreamWithImages.length > 0) {
        this.logService.log("imageStreamWithImages", imageStreamWithImages[0]);
        this.cameraName = imageStreamWithImages[0].cameraName;
        sessionImageFramesCount = imageStreamWithImages[0].framesCount;
      }
    }

    const { data: sessionImageFrameIndex } =
      await imageService.getImageFrameIndex(
        this.project.idProject,
        navigatorPosition.idSession,
        navigatorPosition.distance,
        this.cameraName,
        this.currentRoadLocatorSegment.startDistance,
        this.currentRoadLocatorSegment.endDistance
      );
    // console.log("playback:loadData:getImageFrameIndex", sessionImageFrameIndex);

    this.pageCount = Math.ceil(sessionImageFramesCount / playbackBatchSize);
    this.currentPageIndex = Math.floor(
      sessionImageFrameIndex / playbackBatchSize
    );

    this.logService.log("playback:loadData:loadPage");
    await this.loadPage(this.currentPageIndex);

    this.currentBatchImageFrameIndex =
      sessionImageFrameIndex - this.currentPageIndex * playbackBatchSize;

    // if (sessionImageFrameIndex === 0) {
    //   this.currentBatchImageFrameIndex = 0;
    // } else {
    //   this.currentBatchImageFrameIndex =
    //     sessionImageFrameIndex - this.currentPageIndex * playbackBatchSize;
    //   //sessionImageFrameIndex - this.currentPageIndex * playbackBatchSize - 1; // playback fix to start from first pos, don't skip first image
    // }

    // console.log(
    //   "loaddata-this.currentBatchImageFrameIndex",
    //   this.currentBatchImageFrameIndex
    // );
  }

  private async loadPage(pageIndex: number) {
    if (!this.currentRoadLocatorSegment) {
      return;
    }

    const startIndex = pageIndex * playbackBatchSize;
    const stopIndex = startIndex + playbackBatchSize;

    const { data: frames } = await imageService.getImageFrames(
      this.project.idProject,
      this.currentRoadLocatorSegment.idSession,
      this.cameraName,
      startIndex,
      stopIndex,
      this.currentRoadLocatorSegment.startDistance,
      this.currentRoadLocatorSegment.endDistance
    );

    this.currentBatchImageFrames = frames;

    this.setCurrentBatchImageFramesChainage();

    this.logService.log("playback:loadPage:pageIndex:frames", frames);
  }

  private setCurrentBatchImageFramesChainage() {
    if (!this.currentRoadLocatorSegment) {
      return;
    }

    for (const sessionFrame of this.currentBatchImageFrames) {
      sessionFrame.idRoad = this.currentRoadLocatorSegment.idRoad;
      sessionFrame.chainage = interpolation.linearInterpolation(
        this.currentRoadLocatorSegment.startDistance,
        this.currentRoadLocatorSegment.startChainage,
        this.currentRoadLocatorSegment.endDistance,
        this.currentRoadLocatorSegment.endChainage,
        sessionFrame.distance
      );
    }
  }

  public async next() {
    // console.log(
    //   "playback:next(), this.currentBatchImageFrameIndex",
    //   this.currentBatchImageFrameIndex
    // );
    const index = this.currentBatchImageFrameIndex + 1;
    if (index < this.currentBatchImageFrames.length) {
      this.currentBatchImageFrameIndex = index;
      await this.moveToIndex(index);
    } else {
      this.logService.log("playback:next:nextEnd");
      await this.nextEnd();
    }
  }

  private async moveToIndex(index: number) {
    let navigatorPosition = new NavigatorPosition();
    navigatorPosition.idProject = this.project.idProject;
    navigatorPosition.idRoad = this.currentBatchImageFrames[index].idRoad;
    navigatorPosition.idSession = this.currentBatchImageFrames[index].idSession;
    navigatorPosition.distance = this.currentBatchImageFrames[index].distance;
    navigatorPosition.chainage = this.currentBatchImageFrames[index].chainage;
    this.logService.log("playback:moveToIndex:index", index);
    this.logService.log(
      "playback:moveToIndex:navigatorPosition",
      navigatorPosition
    );
    await this.navigator.move(navigatorPosition, this.navigable);
  }

  private async nextEnd() {
    let pageIndex = this.currentPageIndex + 1;

    if (pageIndex < this.pageCount) {
      this.logService.log("playback:nextEnd:nextPage");

      this.currentPageIndex = pageIndex;

      await this.loadPage(pageIndex);

      let navigatorPosition = new NavigatorPosition();
      const index = 0;
      navigatorPosition.idProject = this.project.idProject;
      navigatorPosition.idRoad = this.currentBatchImageFrames[index].idRoad;
      navigatorPosition.idSession =
        this.currentBatchImageFrames[index].idSession;
      navigatorPosition.distance = this.currentBatchImageFrames[index].distance;
      navigatorPosition.chainage = this.currentBatchImageFrames[index].chainage;

      await this.navigator.move(navigatorPosition);
      return;
    }

    let roadLocatorSegmentIndex = this.currentRoadLocatorSegmentIndex + 1;

    if (roadLocatorSegmentIndex < this.navigator.roadLocatorSegments.length) {
      this.logService.log("playback:nextEnd:nextRoadLocatorSegment");

      this.currentRoadLocatorSegmentIndex = roadLocatorSegmentIndex;

      const roadLocatorSegment =
        this.navigator.roadLocatorSegments[this.currentRoadLocatorSegmentIndex];

      let navigatorPosition = new NavigatorPosition();
      navigatorPosition.idProject = this.project.idProject;
      navigatorPosition.idRoad = roadLocatorSegment.idRoad;
      navigatorPosition.idSession = roadLocatorSegment.idSession;
      navigatorPosition.distance = roadLocatorSegment.startDistance;
      navigatorPosition.chainage = roadLocatorSegment.startChainage;

      this.currentRoadLocatorSegment = roadLocatorSegment;

      await this.loadData(navigatorPosition);

      navigatorPosition = new NavigatorPosition();
      const index = 0;
      navigatorPosition.idProject = this.project.idProject;
      navigatorPosition.idRoad = this.currentBatchImageFrames[index].idRoad;
      navigatorPosition.idSession =
        this.currentBatchImageFrames[index].idSession;
      navigatorPosition.distance = this.currentBatchImageFrames[index].distance;
      navigatorPosition.chainage = this.currentBatchImageFrames[index].chainage;

      await this.navigator.move(navigatorPosition);
      return;
    }

    this.stop();
  }

  public async prev() {
    // console.log(
    //   "playback:prev()-this.currentBatchImageFrameIndex",
    //   this.currentBatchImageFrameIndex
    // );
    const index = this.currentBatchImageFrameIndex - 1;
    if (index >= 0) {
      this.currentBatchImageFrameIndex = index;
      await this.moveToIndex(index);
    } else {
      // console.log("playback:prev:prevEnd");
      await this.prevEnd();
    }
  }

  private async prevEnd() {
    let pageIndex = this.currentPageIndex - 1;

    // console.log("prevEnd-pageIndex", pageIndex);

    if (pageIndex >= 0) {
      this.logService.log("playback:prevEnd:nextPage");

      this.currentPageIndex = pageIndex;

      await this.loadPage(pageIndex);

      let navigatorPosition = new NavigatorPosition();
      const index = this.currentBatchImageFrames.length - 1;
      navigatorPosition.idProject = this.project.idProject;
      navigatorPosition.idRoad = this.currentBatchImageFrames[index].idRoad;
      navigatorPosition.idSession =
        this.currentBatchImageFrames[index].idSession;
      navigatorPosition.distance = this.currentBatchImageFrames[index].distance;
      navigatorPosition.chainage = this.currentBatchImageFrames[index].chainage;

      await this.navigator.move(navigatorPosition);
      return;
    }

    let roadLocatorSegmentIndex = this.currentRoadLocatorSegmentIndex - 1;

    // console.log("prevEnd-roadLocatorSegmentIndex", roadLocatorSegmentIndex);

    if (roadLocatorSegmentIndex >= 0) {
      this.logService.log("playback:prevEnd:nextRoadLocatorSegment");

      this.currentRoadLocatorSegmentIndex = roadLocatorSegmentIndex;

      const roadLocatorSegment =
        this.navigator.roadLocatorSegments[this.currentRoadLocatorSegmentIndex];

      let navigatorPosition = new NavigatorPosition();
      navigatorPosition.idProject = this.project.idProject;
      navigatorPosition.idRoad = roadLocatorSegment.idRoad;
      navigatorPosition.idSession = roadLocatorSegment.idSession;
      navigatorPosition.distance = roadLocatorSegment.endDistance;
      navigatorPosition.chainage = roadLocatorSegment.endChainage;

      this.currentRoadLocatorSegment = roadLocatorSegment;

      await this.loadData(navigatorPosition);

      navigatorPosition = new NavigatorPosition();
      const index = this.currentBatchImageFrames.length - 1;
      navigatorPosition.idProject = this.project.idProject;
      navigatorPosition.idRoad = this.currentBatchImageFrames[index].idRoad;
      navigatorPosition.idSession =
        this.currentBatchImageFrames[index].idSession;
      navigatorPosition.distance = this.currentBatchImageFrames[index].distance;
      navigatorPosition.chainage = this.currentBatchImageFrames[index].chainage;

      await this.navigator.move(navigatorPosition);
      return;
    }

    this.stop();

    // const roadLocatorSegment = this.navigator.roadLocatorSegments[0];

    // let navigatorPosition = new NavigatorPosition();
    // navigatorPosition.idProject = this.project.idProject;
    // navigatorPosition.idRoad = roadLocatorSegment.idRoad;
    // navigatorPosition.idSession = roadLocatorSegment.idSession;
    // navigatorPosition.distance = roadLocatorSegment.startDistance;
    // navigatorPosition.chainage = roadLocatorSegment.startChainage;

    // await this.navigator.move(navigatorPosition);
  }

  public async play() {
    if (this.isPlaying) {
      return;
    }

    this.isPlaying = true;
    this.navigator.playbackIsPlaying(true, this.navigable);

    let isBusy = false;
    this.timerId = setInterval(async () => {
      if (isBusy) {
        return;
      }

      isBusy = true;

      this.logService.log("playbackManager:play:next");

      await this.next();

      //   const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
      //   await sleep(3000);

      isBusy = false;
    }, this.timerIntervalMs);
  }

  public stop(callOnStop: boolean = true) {
    this.isPlaying = false;
    this.navigator.playbackIsPlaying(false, this.navigable);

    clearInterval(this.timerId);
    if (callOnStop && this.onStop) {
      this.onStop();
    }
  }

  public async fist() {
    this.currentRoadLocatorSegmentIndex = 0;
    this.currentRoadLocatorSegment =
      this.navigator.roadLocatorSegments[this.currentRoadLocatorSegmentIndex];

    // let navigatorPosition = new NavigatorPosition();
    // navigatorPosition.idProject = this.project.idProject;
    // navigatorPosition.idRoad = this.currentRoadLocatorSegment.idRoad;
    // navigatorPosition.idSession = this.currentRoadLocatorSegment.idSession;
    // navigatorPosition.distance = this.currentRoadLocatorSegment.startDistance;
    // navigatorPosition.chainage = this.currentRoadLocatorSegment.startChainage;

    const roadLocatorSegment =
      this.navigator.roadLocatorSegments[this.currentRoadLocatorSegmentIndex];

    let navigatorPosition = new NavigatorPosition();
    navigatorPosition.idProject = this.project.idProject;
    navigatorPosition.idRoad = roadLocatorSegment.idRoad;
    navigatorPosition.idSession = roadLocatorSegment.idSession;
    navigatorPosition.distance = roadLocatorSegment.startDistance;
    navigatorPosition.chainage = roadLocatorSegment.startChainage;

    this.currentRoadLocatorSegment = roadLocatorSegment;

    await this.loadData(navigatorPosition);

    navigatorPosition = new NavigatorPosition();
    const index = 0;
    navigatorPosition.idProject = this.project.idProject;
    navigatorPosition.idRoad = this.currentBatchImageFrames[index].idRoad;
    navigatorPosition.idSession = this.currentBatchImageFrames[index].idSession;
    navigatorPosition.distance = this.currentBatchImageFrames[index].distance;
    navigatorPosition.chainage = this.currentBatchImageFrames[index].chainage;

    await this.navigator.move(navigatorPosition);
  }

  public async last() {
    this.currentRoadLocatorSegmentIndex =
      this.navigator.roadLocatorSegments.length - 1;
    this.currentRoadLocatorSegment =
      this.navigator.roadLocatorSegments[this.currentRoadLocatorSegmentIndex];

    // let navigatorPosition = new NavigatorPosition();
    // navigatorPosition.idProject = this.project.idProject;
    // navigatorPosition.idRoad = this.currentRoadLocatorSegment.idRoad;
    // navigatorPosition.idSession = this.currentRoadLocatorSegment.idSession;
    // navigatorPosition.distance = this.currentRoadLocatorSegment.endDistance;
    // navigatorPosition.chainage = this.currentRoadLocatorSegment.endChainage;

    const roadLocatorSegment =
      this.navigator.roadLocatorSegments[this.currentRoadLocatorSegmentIndex];

    let navigatorPosition = new NavigatorPosition();
    navigatorPosition.idProject = this.project.idProject;
    navigatorPosition.idRoad = roadLocatorSegment.idRoad;
    navigatorPosition.idSession = roadLocatorSegment.idSession;
    navigatorPosition.distance = roadLocatorSegment.endDistance;
    navigatorPosition.chainage = roadLocatorSegment.endChainage;

    this.currentRoadLocatorSegment = roadLocatorSegment;

    await this.loadData(navigatorPosition);

    navigatorPosition = new NavigatorPosition();
    const index = this.currentBatchImageFrames.length - 1;
    navigatorPosition.idProject = this.project.idProject;
    navigatorPosition.idRoad = this.currentBatchImageFrames[index].idRoad;
    navigatorPosition.idSession = this.currentBatchImageFrames[index].idSession;
    navigatorPosition.distance = this.currentBatchImageFrames[index].distance;
    navigatorPosition.chainage = this.currentBatchImageFrames[index].chainage;

    await this.navigator.move(navigatorPosition);
  }

  public setPlaybackSpeed(playbackSpeed: number) {
    const minDelay = 140; // ms
    const maxDelay = 1000;

    let delay = interpolation.linearInterpolation(
      100,
      minDelay,
      1,
      maxDelay,
      playbackSpeed
    );

    let playbackDelay = Math.round(delay);
    this.timerIntervalMs = playbackDelay;

    if (this.isPlaying) {
      this.stop(false);
      this.play();
    }
  }
}
