enum Measurement {
  Length = "Length",
  Area = "Area",
  Volume = "Volume",
  Slope = "Slope",
  Speed = "Speed",
  Acceleration = "Acceleration",
  Temperature = "Temperature",
  Angle = "Angle",
  ElectricTension = "ElectricTension",
}

export default Measurement;
