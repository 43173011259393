import Navigator from "../../models/navigator";

import {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import tableService from "../../services/tableService";
import ProjectInfo from "../../models/projectInfo";
import NavigatorPosition from "../../models/navigatorPosition";
import LogService from "../../services/logService";
import LogDirectory from "../../services/logDirectory";
import Navigable, { NavigatorMove, SetFilters } from "../../models/navigable";
import TableManager from "../../models/tableManager";
import VirtualizedTable, {
  VirtualizedTableRef,
} from "../common/VirtulizedTable";
import Filter from "../../models/filter";
import Settings from "../../models/settings";

const logService = new LogService(LogDirectory.EnableTableLogging);

export interface TableRef {
  refresh: () => void;
}

interface Props {
  navigator: Navigator;
  project: ProjectInfo;
  settings: Settings;
  //children: React.ReactNode;
}

const Table = forwardRef((props: Props, ref: Ref<TableRef>) => {
  const { navigator, project, settings } = props;

  const [isDisponsed, setIsDisposed] = useState<boolean>(false);

  const virtualizedTableRef = useRef<VirtualizedTableRef>(null);

  useImperativeHandle(ref, () => ({ refresh }));

  const [selectedRowIndex, setSelectedRowIndex] = useState<
    number | undefined
  >();

  const [scrollToRowIndex, setScrollToRowIndex] = useState<
    Number | undefined
  >();

  const [tableManager, setTableManager] = useState<TableManager>();

  const [navigable, setNavigable] = useState<Navigable>();

  const [showHelperColumns, setShowHelperColumns] = useState<boolean>(
    settings.showTableHelperColumns
  );
  const [helperColumnNames, setHelperColumnNames] = useState<string[]>([]);

  const refresh = () => {
    logService.log("tableRefresh()");
    virtualizedTableRef.current?.refresh();
  };

  useEffect(() => {
    const navigable = new Navigable();
    navigable.id = "table";
    setNavigable(navigable);

    const tableManager = new TableManager();
    setTableManager(tableManager);

    setHelperColumnNames(tableManager.getHelperColumnNames());

    settings.onShowTableHelperColumnsChanged = (checked: boolean) => {
      setShowHelperColumns(settings.showTableHelperColumns);

      // refresh table

      setIsDisposed(true);

      setTimeout(() => {
        setIsDisposed(false);

        setTimeout(async () => {
          await virtualizedTableRef.current?.init();
        }, 100);
      }, 100);
    };

    const navigatorMove: NavigatorMove = async (
      navigatorPositon: NavigatorPosition
    ) => {
      let itemIndex = tableManager.getItemIndex(
        navigatorPositon.idRoad,
        navigatorPositon.idSession,
        navigatorPositon.distance
      );

      logService.log("move-index-client-side", itemIndex);

      if (itemIndex === -1) {
        logService.log("table: index not found in view, getting from server");

        const { data: rowIndex } = await tableService.getTableRowIndex(
          project.idProject,
          navigatorPositon.idRoad,
          navigatorPositon.idSession,
          navigatorPositon.distance,
          navigator.filters
        );
        itemIndex = rowIndex;
      }

      if (itemIndex !== -1) {
        setSelectedRowIndex(itemIndex);

        // eslint-disable-next-line
        const newNumber = new Number(itemIndex); // need it like this reference type, boxed, so data binding (inside the VirtualizedTable) kicks in even if the value type number is the same

        setScrollToRowIndex(newNumber);
      } else {
        logService.log("table: index not found");
      }
    };
    navigable.navigatorMove = navigatorMove;

    const setFilters: SetFilters = async (filters: Filter[]) => {
      // destory and recreate the control as it's internal caching is broken
      //
      // InfiniteLoader internal caching is broken, doens't call onLoadMoreItems on filters changed,
      // infinteLoaderRef.current.resetLoadMoreRowsCache(true) - loads old data, etc..
      // but killing the control and bringing it back on works - as it practically resets the internals properly!

      const promise = new Promise<void>((resolve, reject) => {
        tableManager.clearCurrentItems();

        setIsDisposed(true);

        setTimeout(() => {
          setIsDisposed(false);

          setTimeout(async () => {
            await virtualizedTableRef.current?.init();
            logService.log("filters set");
            resolve();
            // if (navigator.navigatorPosition) {
            //   await navigatorMove(navigator.navigatorPosition);
            // }
          }, 100);
        }, 100);
      });

      return promise;
    };
    navigable.setFilters = setFilters;

    //console.log("table-attached");
    navigator.attach(navigable);

    const init = async () => {
      await virtualizedTableRef.current?.init();

      // this sould be called on open/close window
      if (
        navigator.navigatorPosition &&
        navigator.navigatorPosition.idProject === project.idProject
      ) {
        logService.log("table:navigator:move()-init");
        navigatorMove(navigator.navigatorPosition);
      }
    };

    init();

    return () => {
      navigator.detach(navigable);
    };
  }, [navigator, project, settings]);

  const onGetTableColumns = useCallback(async (): Promise<any[]> => {
    const { data: tableColumns } = await tableService.getTableColumns(
      project.idProject
    );
    return Promise.resolve(tableColumns);
  }, [project.idProject]);

  const onGetTableRowsCount = useCallback(async (): Promise<number> => {
    const { data: tableRowsCount } = await tableService.getTableRowsCount(
      project.idProject,
      navigator.filters
    );
    return Promise.resolve(tableRowsCount);
  }, [project.idProject, navigator.filters]);

  const onLoadMoreItems = useCallback(
    async (startIndex: number, stopIndex: number): Promise<any[]> => {
      const { data: tableData } = await tableService.getTableData(
        project.idProject,
        startIndex,
        stopIndex,
        navigator.filters
      );

      tableManager?.setCurrentItems(tableData, startIndex, stopIndex);

      return Promise.resolve(tableData);
    },
    [project.idProject, tableManager, navigator.filters]
  );

  const onRowSelected = useCallback(
    async (rowIndex: number, items: any[]) => {
      logService.log("table:tableRowSelected:rowIndex", rowIndex);

      const tableHelperData = tableManager?.getTableHelperData(items[rowIndex]);
      if (tableHelperData) {
        const navigatorPoistion = new NavigatorPosition();
        navigatorPoistion.idProject = project.idProject;
        navigatorPoistion.idRoad = tableHelperData.idRoad;
        navigatorPoistion.idSession = tableHelperData.idSession;
        navigatorPoistion.distance = tableHelperData.startDistance;

        await navigator.move(navigatorPoistion, navigable);
      }
    },
    [project.idProject, navigator, tableManager, navigable]
  );

  return (
    <>
      {!isDisponsed && (
        <VirtualizedTable
          ref={virtualizedTableRef}
          selectedRowIndexValue={selectedRowIndex}
          scrollToRowIndexValue={scrollToRowIndex}
          onGetTableColumns={onGetTableColumns}
          onGetTableRowsCount={onGetTableRowsCount}
          onLoadMoreItems={onLoadMoreItems}
          onRowSelected={onRowSelected}
          showUnitsInColumnHeaders={project.showUnitsInTableColumnHeaders}
          showHelperColumns={showHelperColumns}
          helperColumnNames={helperColumnNames}
        />
      )}
    </>
  );
});

export default Table;
