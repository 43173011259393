import { useContext, useEffect } from "react";
import MapContext from "../contexts/mapContext";
import OLTileLayer from "ol/layer/Tile";

const TileLayer: React.FC<{ source: any; zIndex: number }> = ({
  source,
  zIndex = 0,
}) => {
  const map = useContext(MapContext);

  useEffect(() => {
    if (!map) {
      return;
    }

    let tileLayer = new OLTileLayer({
      source,
      zIndex,
    });

    map.addLayer(tileLayer);

    return () => {
      if (map) {
        map.removeLayer(tileLayer);
      }
    };
  }, [map, source, zIndex]);

  return null;
};

export default TileLayer;
