import { Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import MilepostManager from "../../models/milepostManager";
import Navigator from "../../models/navigator";
import ProjectInfo from "../../models/projectInfo";
import ProjectLocatorValue from "../../models/projectLocatorValue";
import locatorSerice from "../../services/locatorService";
import imageService from "../../services/imageService";
import numberHelper from "../../util/numberHelper";
import unitConverter from "../../util/units/unitConverter";
import CheckboxItem from "../common/checkboxItem";
import ImageDownload from "../../models/imageDownload";
import Job from "../../models/job";
import ImageDownloadIncludeLocator from "../../models/imageDownloadIncludeLocator";
import ImageDownloadIncludeCamera from "../../models/imageDownloadIncludeCamera";
import ImageDownloadLocator from "../../models/imageDownloadLocator";
import stringHelper from "../../util/stringHelper";
import FormMessage from "../common/FormMessage";
import roadService from "../../services/roadService";

const ImageDownloadEdit: React.FC<{
  imageDownload: ImageDownload;
  isNew: boolean;
  isEdit: boolean;
  isView: boolean;
  onScheduleDownload: any;
  onCancel: any;
  navigator: Navigator;
  project: ProjectInfo;
}> = ({
  imageDownload,
  isNew,
  isEdit,
  isView,
  onScheduleDownload,
  onCancel,
  navigator,
  project,
}) => {
  const distanceRangeLeftRightMiles: number = 1;

  const [errorMessage, setErrorMessage] = useState<string>("");

  const clearError = () => {
    setErrorMessage("");
  };

  const [downloadName, setDownloadName] =
    useState<string>("New Image Download");

  const [imageName, setImageName] = useState<string>();

  const [includeImageBanner, setIncludeImageBanner] = useState<boolean>(false);

  const [includeLocatorsInFilename, setIncludeLocatorsInFileName] =
    useState<boolean>(true);

  const [locatorCheckboxes, setLocatorCheckboxes] = useState<CheckboxItem[]>(
    []
  );
  const [cameraCheckboxes, setCameraCheckboxes] = useState<CheckboxItem[]>([]);

  const [locatorValues, setLocatorValues] = useState<ProjectLocatorValue[]>([]);

  const [locatorValuesString, setlocatorValuesString] = useState<string>();

  const [milepost, setMilepost] = useState<string>();
  const [offset, setOffset] = useState<number>();

  const [chainage, setChainage] = useState<number>();
  const [startChainage, setStartChainage] = useState<number>();
  const [endChainage, setEndChainage] = useState<number>();

  const [distances, setDistances] = useState<number[]>([0, 0.2]);

  const metersToMilesWithDigits = (meters: number) => {
    const miles = unitConverter.metersToMiles(meters);
    return numberHelper.numberTo3Digits(miles);
  };

  useEffect(() => {
    const calculateStartEndChainage = () => {
      if (!chainage) {
        return;
      }

      let startChainage: number | undefined;
      let endChainage: number | undefined;

      if (navigator.roadLocatorSegment?.isUpChainage) {
        startChainage = chainage + distances[0];
        endChainage = chainage + distances[1];
      } else {
        startChainage = chainage - distances[0];
        endChainage = chainage - distances[1];
      }

      startChainage = numberHelper.numberTo3Digits(startChainage);
      endChainage = numberHelper.numberTo3Digits(endChainage);

      setStartChainage(startChainage);
      setEndChainage(endChainage);
    };

    calculateStartEndChainage();
  }, [chainage, distances, navigator.roadLocatorSegment]);

  useEffect(() => {
    const buildImageName = () => {
      let camera = cameraCheckboxes.find((cam) => cam.isChecked);
      if (!camera) {
        return;
      }

      const forwardCamera = cameraCheckboxes.find(
        (cam) => cam.isChecked && cam.name === "Forward"
      );
      if (forwardCamera) {
        camera = forwardCamera;
      }

      const cameraFolderName = camera.name;

      let imageName = `${cameraFolderName}\\${chainage}`;

      const selectedLocatorValues = [];

      if (includeLocatorsInFilename) {
        for (const locatorChekbox of locatorCheckboxes) {
          if (locatorChekbox.isChecked) {
            const locatorValue = locatorValues.find(
              (l) => l.projectLocatorField?.displayName === locatorChekbox.name
            );
            if (locatorValue) {
              let locatorValueFixed = stringHelper
                .replaceConsecutiveSpaces(locatorValue.value.trim())
                .replaceAll(" ", "_");

              selectedLocatorValues.push(locatorValueFixed);
            }
            if (locatorChekbox.name === "Milepost") {
              selectedLocatorValues.push(milepost);
            }
            if (locatorChekbox.name === "Offset") {
              selectedLocatorValues.push(offset);
            }
          }
        }
      }

      if (selectedLocatorValues.length > 0) {
        imageName += "_" + selectedLocatorValues.join("_");
      }

      imageName += "_" + camera.name + ".jpg";

      setImageName(imageName);
    };

    buildImageName();
  }, [
    includeLocatorsInFilename,
    locatorCheckboxes,
    cameraCheckboxes,
    chainage,
    locatorValues,
    milepost,
    offset,
  ]);

  useEffect(() => {
    const initLocators = async () => {
      if (!isNew) {
        setIncludeLocatorsInFileName(imageDownload.includeLocatorsInFilename);
      }

      const { data: locatorFields } = await locatorSerice.getLocatorFields(
        project.idProject
      );

      const locatorNames = [];

      for (const locatorField of locatorFields) {
        locatorNames.push(locatorField.displayName);
      }

      if (project.hasMilepostNavigation) {
        locatorNames.push("Milepost");
        locatorNames.push("Offset");
      }

      const locatorCheckboxes = [];

      for (const locatorName of locatorNames) {
        const checkbox = new CheckboxItem();
        checkbox.name = locatorName;

        if (!isNew) {
          if (
            imageDownload.imageDownloadIncludeLocators.find(
              (l) => l.locatorName === locatorName
            )
          ) {
            checkbox.isChecked = true;
          }
        }

        locatorCheckboxes.push(checkbox);
      }

      setLocatorCheckboxes(locatorCheckboxes);
    };

    const initCameras = async () => {
      const { data: imageFames } = await imageService.getImageStreams(
        project.idProject
      );

      const cameraNames = [];

      for (const imageFrame of imageFames) {
        cameraNames.push(imageFrame.cameraName);
      }

      const cameraCheckboxes = [];

      for (const cameraName of cameraNames) {
        const checkbox = new CheckboxItem();
        checkbox.name = cameraName;

        if (!isNew) {
          if (
            imageDownload.imageDownloadIncludeCameras.find(
              (c) => c.cameraName === cameraName
            )
          ) {
            checkbox.isChecked = true;
          }
        }

        cameraCheckboxes.push(checkbox);
      }

      ensureOneCameraIsChecked(cameraCheckboxes);

      setCameraCheckboxes(cameraCheckboxes);
    };

    const initChainageAndDistance = () => {
      let chainageMeters = 0;

      if (!isNew) {
        chainageMeters = imageDownload.chainage;
      } else {
        chainageMeters = navigator.navigatorPosition?.chainage ?? 0;
      }

      const chainageMiles = metersToMilesWithDigits(chainageMeters);
      setChainage(chainageMiles);

      if (!isNew) {
        const startDistanceMeters = imageDownload.startDistance;
        const endDistanceMeters = imageDownload.endDistance;

        const startDistanceMiles = metersToMilesWithDigits(startDistanceMeters);
        const endDistanceMiles = metersToMilesWithDigits(endDistanceMeters);

        if (
          startDistanceMiles !== undefined &&
          endDistanceMiles !== undefined
        ) {
          const loadedDistances = [startDistanceMiles, endDistanceMiles];

          // this also triggers the startEndChainage calculation useEffect
          setDistances(loadedDistances);
        }
      }
    };

    const initLocatorValues = async () => {
      if (!navigator.navigatorPosition) {
        return;
      }

      const locatorValues = roadService.getLocatorValues(
        navigator.navigatorPosition,
        navigator.roadLocatorSegments
      );

      if (!isNew) {
        for (const locatorValue of locatorValues) {
          const lv = imageDownload.imageDownloadLocators.find(
            (l) =>
              l.locatorName === locatorValue.projectLocatorField?.displayName
          );
          if (lv) {
            locatorValue.value = lv.locatorValue;
          }
        }
      }

      setLocatorValues(locatorValues);

      const currentLocatorValues = [];

      for (const locatorValue of locatorValues) {
        currentLocatorValues.push(locatorValue.value);
      }

      setlocatorValuesString(currentLocatorValues.join(", "));
    };

    const initMilepost = async () => {
      if (!isNew) {
        const milepost = imageDownload.imageDownloadLocators.find(
          (l) => l.locatorName === "Milepost"
        );
        if (milepost) {
          setMilepost(milepost.locatorValue);
        }

        const offset = imageDownload.imageDownloadLocators.find(
          (l) => l.locatorName === "Offset"
        );
        if (offset) {
          const offsetNumber = +offset.locatorValue;
          setOffset(offsetNumber);
        }
      } else {
        const milepostManager = new MilepostManager(navigator, project);
        milepostManager.onMilepostChanged = (mp: string | undefined) =>
          setMilepost(mp);
        milepostManager.onOffsetChanged = (o: number | undefined) =>
          setOffset(numberHelper.numberTo4Digits(o));

        if (navigator.navigatorPosition) {
          await milepostManager.move(navigator.navigatorPosition);
        }
      }
    };

    const init = async () => {
      if (!isNew) {
        setDownloadName(imageDownload.job.jobName);
        setIncludeImageBanner(imageDownload.includeImageBanner);
      }
      await initLocators();
      await initCameras();
      initChainageAndDistance();
      await initLocatorValues();
      if (project.hasMilepostNavigation) {
        await initMilepost();
      }
    };

    init();
  }, [navigator, project, imageDownload, isNew]);

  const onDownloadNameChanged = (event: any) => {
    const newValue = event.target.value;
    setDownloadName(newValue);
  };

  const onImageNameChanged = (event: any) => {
    const newValue = event.target.value;
    setImageName(newValue);
  };

  const onIncludeImageBannerChanged = (event: any) => {
    const checked = event.target.checked;
    setIncludeImageBanner(checked);
  };

  const onIncludeLocatorsInFilenameChanged = (event: any) => {
    const checked = event.target.checked;
    setIncludeLocatorsInFileName(checked);
  };

  const getLocatorCheckboxChecked = (index: number): boolean | undefined => {
    return locatorCheckboxes[index].isChecked;
  };

  const setLocatorCheckboxChecked = (event: any, index: number) => {
    const checked = event.target.checked;

    const newLocatorCheckboxes = [...locatorCheckboxes];
    newLocatorCheckboxes[index].isChecked = checked;
    setLocatorCheckboxes(newLocatorCheckboxes);
  };

  const getCameraCheckboxChecked = (index: number): boolean | undefined => {
    return cameraCheckboxes[index].isChecked;
  };

  const setCameraCheckboxChecked = (event: any, index: number) => {
    const checked = event.target.checked;

    const newCameraCheckboxes = [...cameraCheckboxes];
    newCameraCheckboxes[index].isChecked = checked;

    ensureOneCameraIsChecked(newCameraCheckboxes);

    setCameraCheckboxes(newCameraCheckboxes);
  };

  const ensureOneCameraIsChecked = (cameraCheckboxes: CheckboxItem[]) => {
    const anyCameraChecked = cameraCheckboxes.find((c) => c.isChecked);

    if (anyCameraChecked) {
      return;
    }

    const forwardCamera = cameraCheckboxes.find((c) => c.name === "Forward");
    if (forwardCamera) {
      forwardCamera.isChecked = true;
    } else {
      cameraCheckboxes[0].isChecked = true;
    }
  };

  const onDistanceSliderChanged = (
    event: Event,
    newValue: number | number[]
  ) => {
    setDistances(newValue as []);
  };

  const getChainageDisplayName = () => {
    if (project.chainageDisplayNameShort) {
      return project.chainageDisplayNameShort;
    }

    if (project.chainageDisplayName) {
      return project.chainageDisplayName;
    }

    return "Chainage";
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    const newImageDownload = new ImageDownload();

    newImageDownload.job = new Job();
    newImageDownload.job.idProject = project.idProject;
    newImageDownload.job.jobName = downloadName;

    newImageDownload.includeImageBanner = includeImageBanner;

    newImageDownload.includeLocatorsInFilename = includeLocatorsInFilename;
    for (const checkbox of locatorCheckboxes) {
      if (checkbox.isChecked) {
        const imageDownloadIncludeLocator = new ImageDownloadIncludeLocator();
        imageDownloadIncludeLocator.locatorName = checkbox.name;
        newImageDownload.imageDownloadIncludeLocators.push(
          imageDownloadIncludeLocator
        );
      }
    }

    for (const checkbox of cameraCheckboxes) {
      if (checkbox.isChecked) {
        const imageDownloadIncludeCamera = new ImageDownloadIncludeCamera();
        imageDownloadIncludeCamera.cameraName = checkbox.name;
        newImageDownload.imageDownloadIncludeCameras.push(
          imageDownloadIncludeCamera
        );
      }
    }

    for (const locatorValue of locatorValues) {
      if (locatorValue.projectLocatorField) {
        const imageDownloadLocator = new ImageDownloadLocator();
        imageDownloadLocator.locatorName =
          locatorValue.projectLocatorField.displayName;
        imageDownloadLocator.locatorValue = locatorValue.value;
        newImageDownload.imageDownloadLocators.push(imageDownloadLocator);
      }
    }

    if (milepost) {
      const imageDownloadLocator = new ImageDownloadLocator();
      imageDownloadLocator.locatorName = "Milepost";
      imageDownloadLocator.locatorValue = milepost;
      newImageDownload.imageDownloadLocators.push(imageDownloadLocator);
    }

    if (offset) {
      const imageDownloadLocator = new ImageDownloadLocator();
      imageDownloadLocator.locatorName = "Offset";
      imageDownloadLocator.locatorValue = offset.toString();
      newImageDownload.imageDownloadLocators.push(imageDownloadLocator);
    }

    if (chainage) {
      const chainageMeters = unitConverter.milesToMeters(chainage);
      newImageDownload.chainage = chainageMeters;
    }

    newImageDownload.startDistance = unitConverter.milesToMeters(distances[0]);
    newImageDownload.endDistance = unitConverter.milesToMeters(distances[1]);

    clearError();

    try {
      await imageService.scheduleImageDownload(newImageDownload);
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    onScheduleDownload(newImageDownload);
  };

  return (
    <div style={{ width: "600px" }}>
      <h6 style={{ fontWeight: "400" }} className="mb-3">
        {!isNew ? "Image Download" : "New Image Download"}
      </h6>
      <div className="mb-2">
        <form className="input-form" onSubmit={onSubmit}>
          <div className="row">
            <div className="d-flex mb-3">
              <label
                style={{ width: "140px" }}
                htmlFor="downloadName"
                className="form-label"
              >
                Download Name
              </label>
              <input
                type="text"
                className="form-control"
                id="downloadName"
                value={downloadName ?? ""}
                onChange={onDownloadNameChanged}
                disabled={isView}
              />
            </div>
          </div>
          <div className="row">
            <div className="d-flex mb-2">
              <label
                style={{ width: "140px" }}
                htmlFor="imageName"
                className="form-label"
              >
                Image Name
              </label>
              <input
                type="text"
                className="form-control"
                id="imageName"
                value={imageName ?? ""}
                onChange={onImageNameChanged}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row" style={{ display: "none" }}>
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                style={{
                  marginLeft: "0px",
                  paddingLeft: "0px",
                  marginRight: "8px",
                }}
                type="checkbox"
                checked={includeImageBanner}
                onChange={onIncludeImageBannerChanged}
                id="includeImageBanner"
                disabled={isView}
              />
              <label className="form-check-label" htmlFor="includeImageBanner">
                Include Image Banner
              </label>
            </div>
          </div>
          <div className="row">
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                style={{
                  marginLeft: "-10px",
                  paddingLeft: "0px",
                  marginRight: "8px",
                }}
                type="checkbox"
                checked={includeLocatorsInFilename}
                onChange={onIncludeLocatorsInFilenameChanged}
                id="includeLocatorsInFilename"
                disabled={isView}
              />
              <label
                className="form-check-label"
                htmlFor="includeLocatorsInFilename"
              >
                Include Locators in Filename
              </label>
            </div>
            <div
              className="btn-group mb-2"
              role="group"
              aria-label="Basic checkbox toggle button group"
            >
              {locatorCheckboxes.map((checkboxItem: CheckboxItem, index) => (
                <React.Fragment key={index.toString()}>
                  <input
                    type="checkbox"
                    checked={getLocatorCheckboxChecked(index)}
                    onChange={(e) => setLocatorCheckboxChecked(e, index)}
                    className="btn-check"
                    id={`btncheck_locator_${index}`}
                    autoComplete="off"
                    disabled={!includeLocatorsInFilename || isView}
                  />
                  <label
                    className="btn btn-outline-primary btn-sm"
                    htmlFor={`btncheck_locator_${index}`}
                  >
                    {checkboxItem.name}
                  </label>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="mb-2">Cameras</div>
            <div
              className="btn-group mb-2"
              role="group"
              aria-label="Basic checkbox toggle button group"
            >
              {cameraCheckboxes.map((cameraCheckbox: CheckboxItem, index) => (
                <React.Fragment key={index.toString()}>
                  <input
                    type="checkbox"
                    checked={getCameraCheckboxChecked(index)}
                    onChange={(e) => setCameraCheckboxChecked(e, index)}
                    className="btn-check"
                    id={`btncheck_cam_${index}`}
                    autoComplete="off"
                    disabled={isView}
                  />
                  <label
                    className="btn btn-outline-primary btn-sm"
                    htmlFor={`btncheck_cam_${index}`}
                  >
                    {cameraCheckbox.name}
                  </label>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="d-flex mb-2">
              <div>Location</div>
              <div className="ms-2">{locatorValuesString}</div>
              {project.hasMilepostNavigation && (
                <div style={{ marginLeft: "100px" }} className="d-flex">
                  <div>Milepost</div>
                  {milepost !== undefined && (
                    <div className="ms-2">{`${milepost}, ${offset}`}</div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            style={{ marginTop: "30px", position: "relative" }}
            className="d-flex justify-content-between gap-2 mb-2"
          >
            <div>Distance</div>
            <span>
              <b>-{distanceRangeLeftRightMiles}</b>
            </span>
            <div style={{ width: "100%" }}>
              <Slider
                step={0.01}
                min={-distanceRangeLeftRightMiles}
                max={distanceRangeLeftRightMiles}
                value={distances}
                onChange={onDistanceSliderChanged}
                aria-labelledby="input-slider"
                valueLabelDisplay="on"
                disabled={isView}
              />
              <span className="d-flex justify-content-between mb-2">
                <span style={{ position: "absolute", left: "0px" }}>
                  {getChainageDisplayName()}
                </span>
                <span>Start {startChainage}</span>
                <span>{chainage}</span>
                <span>End {endChainage}</span>
              </span>
            </div>
            <span style={{ whiteSpace: "nowrap" }}>
              <b>+{distanceRangeLeftRightMiles}</b> mile
            </span>
          </div>
          {isNew && (
            <div>
              <button
                style={{ marginLeft: "-1px" }}
                type="submit"
                className="btn btn-primary btn-sm"
              >
                Schedule Download
              </button>
              <button
                type="button"
                className="btn btn-secondary btn-sm ms-2"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          )}
          {isView && (
            <div>
              <button
                style={{ marginLeft: "-1px" }}
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={onCancel}
              >
                Close
              </button>
            </div>
          )}
          <FormMessage
            message={""}
            errorMessage={errorMessage}
            isBusy={false}
          />
        </form>
      </div>
    </div>
  );
};

export default ImageDownloadEdit;
