import Measurement from "./measurement";
import Unit from "./unit";
import unitConverter from "./unitConverter";

export default class UnitMeasure {
  public measurement: Measurement = Measurement.Length;
  public unit: Unit = Unit.Meter;
  public abbreviation: string = "";
  public scale: number = 0;
  public offset: number = 0;

  public getSymbol = () => {
    return `(${this.abbreviation})`;
  };

  public static create = (
    measurement: Measurement,
    unit: Unit,
    abbreviation: string,
    scale: number = 1.0,
    offset = 0.0
  ): UnitMeasure => {
    var unitMeasure = new UnitMeasure();
    unitMeasure.measurement = measurement;
    unitMeasure.unit = unit;
    unitMeasure.abbreviation = abbreviation;
    unitMeasure.scale = scale;
    unitMeasure.offset = offset;

    return unitMeasure;
  };

  public fromBaseUnit(valueInBaseUnit: number): number {
    switch (this.unit) {
      case Unit.AnglePercent:
        const unitMeasure = unitConverter.getUnit(Unit.Radian);
        if (unitMeasure) {
          const rad = unitMeasure.fromBaseUnit(valueInBaseUnit);
          return Math.tan(rad) * 100;
        }
        return valueInBaseUnit;
      default:
        return valueInBaseUnit * this.scale + this.offset;
    }
  }

  public toBaseUnit(valueInCurrentUnit: number): number {
    switch (this.unit) {
      case Unit.AnglePercent:
        var atan = Math.atan(valueInCurrentUnit / 100.0);
        const unitMeasure = unitConverter.getUnit(Unit.Radian);
        if (unitMeasure) {
          return unitMeasure.toBaseUnit(atan);
        }
        return valueInCurrentUnit;
      default:
        return (valueInCurrentUnit - this.offset) / this.scale;
    }
  }
}
