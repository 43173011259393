import { useEffect, useState } from "react";
import Moment from "react-moment";
import Navigable, { NavigatorMove } from "../../models/navigable";
import Navigator from "../../models/navigator";
import NavigatorPosition from "../../models/navigatorPosition";
import SessionInfoModel from "../../models/sessionInfo";
import LogDirectory from "../../services/logDirectory";
import LogService from "../../services/logService";
import roadService from "../../services/roadService";
import dateTimeHelper from "../../util/dateTimeHelper";

const logService = new LogService(LogDirectory.EnableSessionInfoLogging);

const SessionInfo: React.FC<{
  navigator: Navigator;
}> = ({ navigator }) => {
  const [sessionInfo, setSessionInfo] = useState<SessionInfoModel>();

  useEffect(() => {
    logService.log("sessionInfo:init()");

    const navigable = new Navigable();
    navigable.id = "sessionInfo";

    let currentIdSession: number | null = null;

    const navigatorMove: NavigatorMove = async (
      navigatorPosition: NavigatorPosition
    ) => {
      logService.log("sessionInfo:navigator:move()");

      if (currentIdSession !== navigatorPosition.idSession) {
        logService.log("sessionInfo:getSessionInfo()-fromServer");

        const { data: sessionInfo } = await roadService.getSessionInfo(
          navigatorPosition.idProject,
          navigatorPosition.idSession
        );

        setSessionInfo(sessionInfo);

        currentIdSession = navigatorPosition.idSession;
      }
    };
    navigable.navigatorMove = navigatorMove;

    navigator.attach(navigable);

    return () => navigator.detach(navigable);
  }, [navigator]);

  return (
    <div>
      Collection Date
      {sessionInfo && sessionInfo.sessionDate && (
        <>
          :{" "}
          <Moment format={dateTimeHelper.dateFormat}>
            {sessionInfo.sessionDate}
          </Moment>
        </>
      )}
      {(!sessionInfo || !sessionInfo.sessionDate) && " -"}
    </div>
  );
};

export default SessionInfo;
