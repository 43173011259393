const hexArgbToRgbAHex = (hexArgb: string) => {
  if (hexArgb.at(0) !== "#") {
    return hexArgb;
  }

  const a = parseInt(hexArgb.slice(1, 3), 16);
  const r = parseInt(hexArgb.slice(3, 5), 16);
  const g = parseInt(hexArgb.slice(5, 7), 16);
  const b = parseInt(hexArgb.slice(7, 9), 16);

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

const colorHelper = {
  hexArgbToRgbAHex,
};

export default colorHelper;
