export default class LogService {
  private isLoggingEnabled: boolean = false;

  constructor(enableLogging: boolean) {
    this.isLoggingEnabled = enableLogging;
  }

  public enableLogging(enable: boolean) {
    this.isLoggingEnabled = enable;
  }

  public log(message: any, ...args: any[]) {
    if (this.isLoggingEnabled) {
      if (args.length > 0) {
        console.log(message, args[0]);
      } else {
        console.log(message);
      }
    }
  }
}
