const is4k = () => {
  const windowHeight = window.innerHeight;
  // my scr 948 on 4k without bookmarks bar, on 4k with bookmarks bar 916
  // on full hd 746 without bookmarks bar, on full hd with bookmarks bar 714
  // so it would result that bookmars bar would be 32px on any resolution
  const is4k = windowHeight > 800;
  return is4k;
};

const screenHelper = {
  is4k,
};

export default screenHelper;
