import interpolation from "../util/interpolation";
import NavigatorPosition from "./navigatorPosition";
import RoadLocatorSegment from "./roadLocatorSegment";

const getRoadLocatorSegmentIndex = (
  navigatorPosition: NavigatorPosition,
  roadLocatorSegments: RoadLocatorSegment[]
): number => {
  let roadLocatorSegmentIndex = -1;

  const foundIndexes: number[] = [];

  for (let i = 0; i < roadLocatorSegments.length; i++) {
    const roadLocatorSegment = roadLocatorSegments[i];
    if (
      roadLocatorSegment.idSession === navigatorPosition.idSession &&
      navigatorPosition.distance >= roadLocatorSegment.startDistance &&
      navigatorPosition.distance <= roadLocatorSegment.endDistance
    ) {
      foundIndexes.push(i);
    }
  }

  if (foundIndexes.length > 0) {
    if (foundIndexes.length === 1) {
      roadLocatorSegmentIndex = foundIndexes[0];
      //console.log("just one");
    } else {
      //console.log("multiple", foundIndexes);
      for (const foundIndex of foundIndexes) {
        const roadLocatorSegment = roadLocatorSegments[foundIndex];
        if (roadLocatorSegment.startDistance === navigatorPosition.distance) {
          roadLocatorSegmentIndex = foundIndex;
          //console.log("startDistance segment", foundIndex);
          break;
        }
      }
    }
  } else {
    //console.log("none");
  }

  //console.log("roadLocatorSegmentIndex", roadLocatorSegmentIndex);

  return roadLocatorSegmentIndex;
};

const getNavigatorPositionForChainage = (
  chainage: number,
  roadLocatorSegments: RoadLocatorSegment[]
): NavigatorPosition | undefined => {
  let roadLocatorSegment = undefined;
  for (const segment of roadLocatorSegments) {
    if (segment.isUpChainage) {
      if (
        chainage >= segment.startChainage &&
        chainage <= segment.endChainage
      ) {
        roadLocatorSegment = segment;
        break;
      }
    } else {
      if (
        chainage <= segment.startChainage &&
        chainage >= segment.endChainage
      ) {
        roadLocatorSegment = segment;
        break;
      }
    }
  }

  if (!roadLocatorSegment) {
    return;
  }

  const navigatorPosition = new NavigatorPosition();
  navigatorPosition.idProject = roadLocatorSegment.idProject;
  navigatorPosition.idRoad = roadLocatorSegment.idRoad;
  navigatorPosition.idSession = roadLocatorSegment.idSession;
  navigatorPosition.chainage = chainage;
  navigatorPosition.distance = interpolation.linearInterpolation(
    roadLocatorSegment.startChainage,
    roadLocatorSegment.startDistance,
    roadLocatorSegment.endChainage,
    roadLocatorSegment.endDistance,
    chainage
  );

  return navigatorPosition;
};

const getChainageForDistance = (
  idSession: number,
  distance: number,
  roadLocatorSegments: RoadLocatorSegment[]
): number => {
  let roadLocatorSegment: RoadLocatorSegment | undefined = undefined;

  for (const segment of roadLocatorSegments) {
    if (segment.idSession === idSession) {
      const isBeween =
        distance >= segment.startDistance && distance <= segment.endDistance;
      if (isBeween) {
        roadLocatorSegment = segment;
      }
    }
  }

  if (!roadLocatorSegment) {
    for (const segment of roadLocatorSegments) {
      if (segment.idSession === idSession) {
        roadLocatorSegment = segment;
        break;
      }
    }
  }

  if (!roadLocatorSegment) {
    return 0;
  }

  const chainage = interpolation.linearInterpolation(
    roadLocatorSegment.startDistance,
    roadLocatorSegment.startChainage,
    roadLocatorSegment.endDistance,
    roadLocatorSegment.endChainage,
    distance
  );

  return chainage;
};

const roadManager = {
  getRoadLocatorSegmentIndex,
  getNavigatorPositionForChainage,
  getChainageForDistance,
};

export default roadManager;
