export default class RoutePaths {
  public static readonly AccountConfirmationRoutePath: string =
    "/account-confirmation";
  public static readonly ResetPasswordRoutePath: string = "/reset-password";

  private static getBaseUrl(): string {
    return window.location.origin;
  }

  public static getAccountConfirmationUrl(): string {
    return this.getBaseUrl() + this.AccountConfirmationRoutePath;
  }

  public static getResetPasswordRouteUrl(): string {
    return this.getBaseUrl() + this.ResetPasswordRoutePath;
  }

  public static getFullUrl(
    relativeUrl: string | undefined
  ): string | undefined {
    if (!relativeUrl) {
      return undefined;
    }
    return this.getBaseUrl() + "/" + relativeUrl;
  }
}
