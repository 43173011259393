import { useEffect, useState } from "react";
import { Slider } from "@mui/material";
import PlaybackManager from "../../models/playbackManager";
import Settings from "../../models/settings";

const PlaybackOptions: React.FC<{
  playbackManager: PlaybackManager | undefined;
  settings: Settings;
}> = ({ playbackManager, settings }) => {
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);

  useEffect(() => {
    // const defaultPlayabckSpeed = 100;

    const playbackSpeed = settings.playbackSpeed;

    playbackManager?.setPlaybackSpeed(playbackSpeed);
    setPlaybackSpeed(playbackSpeed);
  }, [playbackManager, settings.playbackSpeed]);

  const onPlaybackSpeedChanged = (
    event: Event,
    newValue: number | number[]
  ) => {
    const playbackSpeed = newValue as number;

    setPlaybackSpeed(playbackSpeed);

    playbackManager?.setPlaybackSpeed(playbackSpeed);

    settings.playbackSpeed = playbackSpeed;
    settings.onSettingsChanged(settings);
  };

  return (
    <div className="navbar-nav btn-group align-items-center">
      <div
        className="nav-link dropdown-toggle hidden-arrow"
        style={{ padding: "0px" }}
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
      >
        <div
          style={{ marginTop: "-4px" }}
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Playback Options"
        >
          <i className="navbar-nav-fa fa fa-sliders fa-lg"></i>
        </div>
      </div>
      <ul className="dropdown-menu dropdown-menu-end">
        <div className="dropdown-item-padding">
          <label htmlFor="customRange1" className="form-label">
            Playback Speed
          </label>
          <div className="d-flex" style={{ width: "200px" }}>
            <span className="slider-mark-hide slider-mark-left">0</span>
            <Slider
              min={1}
              max={100}
              value={playbackSpeed}
              onChange={onPlaybackSpeedChanged}
              aria-label="Default"
              valueLabelDisplay="auto"
            />
            {/* <input type="range" className="form-range" id="customRange1" /> */}
            <span className="slider-mark-hide slider-mark-right">100</span>
          </div>
        </div>
      </ul>
    </div>
  );
};

export default PlaybackOptions;
