import RoadMilepostSegment from "./roadMilepostSegment";
import NavigatorPosition from "./navigatorPosition";
import roadService from "../services/roadService";
import interpolation from "../util/interpolation";
import Navigator from "./navigator";
import ProjectInfo from "./projectInfo";
import Filter from "./filter";

export default class MilepostManager {
  private navigator: Navigator;
  private project: ProjectInfo;

  private currentIdRoad: number | null = null;
  private currentRoadMilepostSegments: RoadMilepostSegment[] = [];
  private currentMilepost: string | null = null;

  public onMilepostsChanged: any;
  public onMilepostChanged: any;
  public onOffsetChanged: any;

  constructor(navigator: Navigator, project: ProjectInfo) {
    this.navigator = navigator;
    this.project = project;
  }

  private findSegmentIndex(
    navigatorPosition: NavigatorPosition,
    segments: any[]
  ): number {
    let segmentIndex = -1;

    let index = 0;
    for (const segment of segments) {
      if (
        navigatorPosition.idSession === segment.idSession &&
        navigatorPosition.distance >= segment.startDistance &&
        navigatorPosition.distance < segment.endDistance
      ) {
        segmentIndex = index;
        break;
      }
      index++;
    }

    if (segmentIndex === -1) {
      index = 0;
      for (const segment of segments) {
        if (
          navigatorPosition.idSession === segment.idSession &&
          navigatorPosition.distance >= segment.startDistance &&
          navigatorPosition.distance <= segment.endDistance
        ) {
          segmentIndex = index;
          break;
        }
        index++;
      }
    }

    return segmentIndex;
  }

  public move = async (navigatorPosition: NavigatorPosition) => {
    if (this.currentIdRoad !== navigatorPosition.idRoad) {
      const { data: segments } = await roadService.getRoadMilepostSegments(
        this.project.idProject,
        navigatorPosition.idRoad,
        this.navigator.filters
      );
      this.currentIdRoad = navigatorPosition.idRoad;
      this.currentRoadMilepostSegments = segments;
      this.initCurrentMileposts();
    }

    let currentRoadMilepostSegment: RoadMilepostSegment | undefined;

    const roadMilepostSegmentIndex = this.findSegmentIndex(
      navigatorPosition,
      this.currentRoadMilepostSegments
    );

    if (roadMilepostSegmentIndex === -1) {
      return;
    }

    currentRoadMilepostSegment =
      this.currentRoadMilepostSegments[roadMilepostSegmentIndex];

    if (
      currentRoadMilepostSegment.isNullStartMilepost ||
      currentRoadMilepostSegment.isNullEndMilepost
    ) {
      this.onOffsetChanged?.(undefined);

      this.currentMilepost = null;
      this.onMilepostChanged?.(this.currentMilepost);
      return;
    }

    let offset = 0;
    let milepost = "";

    if (
      currentRoadMilepostSegment.startMilepost ===
      currentRoadMilepostSegment.endMilepost
    ) {
      offset = interpolation.linearInterpolation(
        currentRoadMilepostSegment.startDistance,
        currentRoadMilepostSegment.startOffset,
        currentRoadMilepostSegment.endDistance,
        currentRoadMilepostSegment.endOffset,
        navigatorPosition.distance
      );
      milepost = currentRoadMilepostSegment.startMilepost;
    } else {
      // Arizona 22-1_ArizonaDOT_2022 // reporting._NavigationReport
      // and ISNULL(RoadName ,'') = '  S 085                        2'   // asc - IsUpCh = 1
      // and ISNULL(RoadName ,'') = '  S 085                       02'   // asc - IsUpCh = 0
      // IDRoad IDSeg IDSess StartDistance EndDistance StartChainage EndChainage IsUpCh FromMP_Num ToMP_Num  FromMP ToMP FromMPOff ToMPOff
      // 1009   47030 110    2162.678      2324.021    804.672       965.606     1      120.952    121.052   M120   M121 0.952     0.052
      // 118    48151 97     186.622	     268.377     55776.199     55683.141   0      154.943    155.001   M154   M155 0.943     0.001
      // milepost downchainage fix - new
      // 118    48151 97     186.622	     268.377     55776.199     55683.141   0      155.001    154.943   M155   M154 0.001     0.943

      // if IsUpChainage    120.952    121.052
      //                      0.952      0.052
      // virtualOffset        0.952      1.052
      //                  left side  0  right side

      // downchainage
      // 155.001    154.943   M155   M154 0.001     0.943
      //                      0.001     0.943
      // virtualOffset        1.001     0.943
      //                  left side  0  right side

      if (currentRoadMilepostSegment.isUpChainage) {
        const virtualOffset = interpolation.linearInterpolation(
          currentRoadMilepostSegment.startDistance,
          currentRoadMilepostSegment.startOffset,
          currentRoadMilepostSegment.endDistance,
          currentRoadMilepostSegment.endOffset + 1,
          navigatorPosition.distance
        );

        if (virtualOffset > 1) {
          // right side
          offset = virtualOffset - 1;
          milepost = currentRoadMilepostSegment.endMilepost;
        } else {
          // left side
          offset = virtualOffset;
          milepost = currentRoadMilepostSegment.startMilepost;
        }
      } else {
        const virtualOffset = interpolation.linearInterpolation(
          currentRoadMilepostSegment.startDistance,
          currentRoadMilepostSegment.startOffset + 1,
          currentRoadMilepostSegment.endDistance,
          currentRoadMilepostSegment.endOffset,
          navigatorPosition.distance
        );

        if (virtualOffset > 1) {
          // left side
          offset = virtualOffset - 1;
          milepost = currentRoadMilepostSegment.startMilepost;
        } else {
          // right side
          offset = virtualOffset;
          milepost = currentRoadMilepostSegment.endMilepost;
        }
      }
    }

    this.onOffsetChanged?.(offset);

    this.currentMilepost = milepost;
    this.onMilepostChanged?.(this.currentMilepost);
  };

  public moveToMilepost = async (milepost: string) => {
    if (milepost !== this.currentMilepost) {
      let roadMilepostSegment = this.currentRoadMilepostSegments.find(
        (s) => s.startMilepost === milepost
      );

      // there could be that some segment end on a mp that doesn't have a start
      if (!roadMilepostSegment) {
        roadMilepostSegment = this.currentRoadMilepostSegments.find(
          (s) => s.endMilepost === milepost
        );
      }

      if (roadMilepostSegment) {
        const navigatorPosition = new NavigatorPosition();
        navigatorPosition.idProject = this.project.idProject;
        navigatorPosition.idRoad = roadMilepostSegment.idRoad;
        navigatorPosition.idSession = roadMilepostSegment.idSession;
        navigatorPosition.distance = roadMilepostSegment.startDistance;
        navigatorPosition.chainage = roadMilepostSegment.startChainage;
        await this.navigator.move(navigatorPosition);
      }
    }
  };

  public setFilters = async (filters: Filter[]) => {
    // make the mileposts reload on the next move
    this.currentIdRoad = 0;
  };

  public moveToOffset = async (offset: number) => {
    const offsetSegmentIndex = this.findSegmentIndexForOffset(
      offset,
      this.currentMilepost,
      this.currentRoadMilepostSegments
    );

    if (offsetSegmentIndex === -1) {
      return;
    }

    const offsetSegment = this.currentRoadMilepostSegments[offsetSegmentIndex];

    const navigatorPosition = new NavigatorPosition();
    navigatorPosition.idProject = this.project.idProject;
    navigatorPosition.idRoad = offsetSegment.idRoad;
    navigatorPosition.idSession = offsetSegment.idSession;

    if (offsetSegment.startMilepost === offsetSegment.endMilepost) {
      navigatorPosition.distance = interpolation.linearInterpolation(
        offsetSegment.startOffset,
        offsetSegment.startDistance,
        offsetSegment.endOffset,
        offsetSegment.endDistance,
        offset
      );
    } else {
      if (offsetSegment.isUpChainage) {
        navigatorPosition.distance = interpolation.linearInterpolation(
          offsetSegment.startOffset,
          offsetSegment.startDistance,
          offsetSegment.endOffset + 1,
          offsetSegment.endDistance,
          offset
        );
      } else {
        navigatorPosition.distance = interpolation.linearInterpolation(
          offsetSegment.startOffset + 1,
          offsetSegment.startDistance,
          offsetSegment.endOffset,
          offsetSegment.endDistance,
          offset
        );
      }
    }

    const isDistanceInsideSegment =
      navigatorPosition.distance >= offsetSegment.startDistance &&
      navigatorPosition.distance <= offsetSegment.endDistance;

    if (!isDistanceInsideSegment) {
      return;
    }

    await this.navigator.move(navigatorPosition);
  };

  private findSegmentIndexForOffset(
    offset: number,
    milepost: string | null,
    segments: RoadMilepostSegment[]
  ): number {
    let segmentIndex = -1;

    let index = 0;
    for (const segment of segments) {
      if (segment.isUpChainage) {
        if (
          milepost === segment.startMilepost &&
          offset >= segment.startOffset
        ) {
          segmentIndex = index;
          break;
        }
      } else {
        if (milepost === segment.startMilepost && offset >= segment.endOffset) {
          segmentIndex = index;
          break;
        }
      }
      index++;
    }

    if (segmentIndex === -1) {
      index = 0;
      for (const segment of segments) {
        if (segment.isUpChainage) {
          if (milepost === segment.endMilepost) {
            segmentIndex = index;
            break;
          }
        } else {
          if (milepost === segment.startMilepost) {
            segmentIndex = index;
            break;
          }
        }
        index++;
      }
    }

    return segmentIndex;
  }

  private initCurrentMileposts = () => {
    const mileposts: string[] = [];
    for (const roadMilepostSegment of this.currentRoadMilepostSegments) {
      if (
        roadMilepostSegment.isNullStartMilepost ||
        roadMilepostSegment.isNullEndMilepost
      ) {
        continue;
      }

      let milepost = roadMilepostSegment.startMilepost;
      if (!mileposts.find((mp) => mp === milepost)) {
        mileposts.push(milepost);
      }

      // there could be that some segment end on a mp that doesn't have a start
      if (
        roadMilepostSegment.startMilepost !== roadMilepostSegment.endMilepost
      ) {
        milepost = roadMilepostSegment.endMilepost;
        if (!mileposts.find((mp) => mp === milepost)) {
          mileposts.push(milepost);
        }
      }
    }

    this.onMilepostsChanged?.(mileposts);

    this.onOffsetChanged?.(undefined);
  };
}
