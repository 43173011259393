import { useEffect, useState } from "react";
import Organization from "../../models/organization";
import organizationService from "../../services/organizationService";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import AdminBoxConent from "../../components/admin/AdminBoxContent";

const Organizations: React.FC = () => {
  const navigate = useNavigate();

  const [organizations, setOrganizations] = useState<Organization[]>([]);

  useEffect(() => {
    const init = async () => {
      const { data: organizations } =
        await organizationService.getOrganizations();
      setOrganizations(organizations);
    };

    init();
  }, []);

  const onNewOrganization = () => {
    navigate("/admin/organizations/new");
  };

  const onEditOrganization = (organization: Organization) => {
    navigate(`/admin/organizations/${organization.idOrganization}`);
  };

  return (
    <AdminBoxConent
      title="Organizations"
      onAddNew={() => onNewOrganization()}
      count={organizations.length}
      countType="organizations"
      hasCurrentOrganizationDropdown={false}
    >
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Organization Name</th>
            <th scope="col">Organization Code</th>
            <th style={{ width: "50px" }} />
          </tr>
        </thead>
        <tbody>
          {organizations.map((organization: Organization) => (
            <tr key={organization.idOrganization.toString()}>
              <td>{organization.organizationName}</td>
              <td>{organization.organizationCode}</td>
              <td>
                <EditIcon
                  style={{ cursor: "pointer" }}
                  fontSize="small"
                  onClick={() => onEditOrganization(organization)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AdminBoxConent>
  );
};

export default Organizations;
