import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import appSettings from "../appSettings";
import FormMessage from "../components/common/FormMessage";
import LoginBoxContent from "../components/common/LoginBoxContent";
import MotionDiv from "../components/common/MotionDiv";
import userService from "../services/userService";

import pageHelper from "../util/pageHelper";

const AccountConfirmation: React.FC = () => {
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [searchParams] = useSearchParams();

  const clearError = () => {
    setErrorMessage("");
    setMessage("");
  };

  useEffect(() => {
    console.log("accountConfirmationPageInit");
    pageHelper.hideScrollbars();

    const init = async () => {
      const accountConfirmationToken = searchParams.get("t");

      if (!accountConfirmationToken) {
        setErrorMessage("Invalid token");
        return;
      }

      // all good

      try {
        const { data } = await userService.confirmAccount(
          accountConfirmationToken
        );
        setIsBusy(false);
        setMessage(data);
      } catch (error: any) {
        setIsBusy(false);
        const errorMessage = error.response.data;
        setErrorMessage(errorMessage);
      }
    };

    let isDisposed = false;

    clearError();

    setIsBusy(true);

    if (appSettings.useProd) {
      init();
    } else {
      // bypass the useStrict react mechaims of add/remove compoent on firts run in dev
      // so that init() executes only once even in this case - in prod, it's executed 1 time anyways
      setTimeout(() => {
        if (isDisposed) {
          return;
        }
        init();
      }, 100);
    }

    return () => {
      isDisposed = true;
    };
  }, [searchParams]);

  return (
    <MotionDiv>
      <LoginBoxContent>
        <div className="mt-4">
          <h4>Account Confirmation</h4>
        </div>
        <div>
          <div className="row input-form">
            <FormMessage
              message={message}
              errorMessage={errorMessage}
              isBusy={isBusy}
            />
          </div>
          <Link to="/login">Back to Login</Link>
        </div>
      </LoginBoxContent>
    </MotionDiv>
  );
};

export default AccountConfirmation;
