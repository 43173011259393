import { Route, Routes, useLocation } from "react-router-dom";
import SideBar from "./components/admin/SideBar";
import NavBar from "./components/navbar/NavBar";
import Admin from "./pages/admin/Admin";
import Organizations from "./pages/admin/Organizations";
import { OrganizationEdit } from "./pages/admin/OrganizationEdit";
import SignupDomains from "./pages/admin/SignupDomains";
import { useContext, useEffect, useState } from "react";
import Organization from "./models/organization";
import OrganizationContext from "./contexts/organizationContext";
import OrganizationContextModel from "./contexts/organizationContextModel";
import { SignupDomainEdit } from "./pages/admin/SignupDomainEdit";
import Projects from "./pages/admin/Projects";
import ProjectCollectionEdit from "./pages/admin/ProjectCollectionEdit";
import ProjectEdit from "./pages/admin/ProjectEdit";
import ActiveUsers from "./pages/admin/ActiveUsers";
import SignupPendings from "./pages/admin/SignupPendings";
import SignupApprovals from "./pages/admin/SignupApprovals";
import PortalAdminUsers from "./pages/admin/PortalAdminUsers";
import Users from "./pages/admin/Users";
import UserEdit from "./pages/admin/UserEdit";

const AppAdmin: React.FC = () => {
  const location = useLocation();

  const [organization, setOrganization] = useState<Organization | undefined>();

  const organizationContext = useContext<OrganizationContextModel | undefined>(
    OrganizationContext
  );

  // useEffect(() => {
  //   console.log("appAdminInit");
  // }, []);

  useEffect(() => {
    if (!organizationContext) {
      return;
    }

    const onOrganizationChanged = () => {
      if (!organizationContext) {
        return;
      }

      setOrganization(organizationContext.currentOrganization);
    };

    organizationContext.onCurrentOrganizationChanged = onOrganizationChanged;

    const init = async () => {
      await organizationContext.init();
      setOrganization(organizationContext.currentOrganization);
    };

    init();

    return () => {
      organizationContext.onCurrentOrganizationChanged = undefined;
    };
  }, [organizationContext]);

  return (
    <>
      <NavBar />
      <div className="d-flex">
        <div>
          <SideBar />
        </div>
        <div className="flex-fill">
          <Routes location={location} key={location.pathname}>
            <Route path="/organizations/:id" element={<OrganizationEdit />} />
            <Route path="/organizations" element={<Organizations />} />
            <Route
              path="/signup-domains/:id"
              element={<SignupDomainEdit organization={organization} />}
            />
            <Route
              path="/signup-domains"
              element={<SignupDomains organization={organization} />}
            />
            <Route path="/signup-pendings" element={<SignupPendings />} />
            <Route path="/signup-approvals" element={<SignupApprovals />} />
            <Route
              path="/projects"
              element={<Projects organization={organization} />}
            />
            <Route
              path="/projects/:id"
              element={<ProjectEdit organization={organization} />}
            />
            <Route
              path="/project-collections/:id"
              element={<ProjectCollectionEdit organization={organization} />}
            />
            <Route
              path="/live-users"
              element={<ActiveUsers title="Live Users" showLiveUsers={true} />}
            />
            <Route
              path="/active-users"
              element={
                <ActiveUsers title="Active Users" showLiveUsers={false} />
              }
            />
            <Route path="/portal-admin-users" element={<PortalAdminUsers />} />
            <Route path="/users/:id" element={<UserEdit />} />
            <Route
              path="/users"
              element={<Users organization={organization} />}
            />
            <Route path="/" element={<Admin />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default AppAdmin;
