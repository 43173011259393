import { motion } from "framer-motion";

const MotionDiv: React.FC<{ children: any }> = ({ children }) => {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.2 } }}
      //initial={{ scaleY: 0 }}
      //animate={{ scaleY: 1 }}
      //exit={{ scaleY: 0 }}
      //transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  );
};

export default MotionDiv;
