import { useEffect, useState } from "react";
import BoxContent from "../../components/common/BoxContent";
import User from "../../models/user";
import { useNavigate, useParams } from "react-router-dom";
import userService from "../../services/userService";
import ModalWindow from "../../components/common/ModalWindow";

const UserEdit: React.FC = () => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>();

  const [user, setUser] = useState<User>(new User());

  const [showResetPasswordWindow, setShowResetPasswordWindow] =
    useState<boolean>(false);

  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");

  const [errorMessage, setErrorMessage] = useState<string>("");

  const onNewPasswordChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setNewPassword(newValue);
  };

  const onNewPasswordConfirmChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setNewPasswordConfirm(newValue);
  };

  const clearMessages = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    const initEdit = async (idUser: number) => {
      const { data: user } = await userService.getUserByIdUser(idUser);
      if (user) {
        setUser(user);
      }
    };

    const isNew = idParam === "new";
    setIsNew(isNew);

    if (!isNew) {
      const idUser = Number(idParam);
      initEdit(idUser);
    }
  }, [idParam]);

  const getUserOrganizationName = (user: User) => {
    if (user.userGroups.length === 0) {
      return "";
    }

    return user.userGroups[0].organizations[0].organizationName;
  };

  const onCancel = () => {
    navigate("/admin/users");
  };

  const clearPassword = () => {
    setNewPassword("");
    setNewPasswordConfirm("");
  };

  const onResetPassword = () => {
    clearMessages();
    clearPassword();
    setShowResetPasswordWindow(true);
  };

  const onApproveAccountOk = async () => {
    clearMessages();

    if (newPassword.trim().length === 0) {
      setErrorMessage("New Password can not be empty");
      return false;
    }

    if (newPassword !== newPasswordConfirm) {
      setErrorMessage("Passwords don't match");
      return false;
    }

    await userService.resetPasswordByIdUser(user.idUser, newPassword);

    setShowResetPasswordWindow(false);
  };

  const onApproveAccountCancel = () => {
    setShowResetPasswordWindow(false);
  };

  return (
    <BoxContent title={(isNew ? "New" : "Edit") + " User"} useFullWidth>
      <form className="input-form">
        <div className="row">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Organization Name
            </label>
            <div className="input-group">{getUserOrganizationName(user)}</div>
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="email"
                value={user.email}
                disabled={true}
              />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="firstName" className="form-label">
              First Name
            </label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="firstName"
                value={user.firstName}
                disabled={true}
              />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="lastName" className="form-label">
              Last Name
            </label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                id="lastName"
                value={user.lastName}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </form>
      <div className="d-flex justify-content-between">
        <div>
          <button
            type="button"
            className="btn btn-secondary"
            // style={{ marginLeft: "10px" }}
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            // style={{ marginLeft: "10px" }}
            onClick={onResetPassword}
          >
            Reset Password
          </button>
        </div>
      </div>
      {showResetPasswordWindow && (
        <ModalWindow
          title="Reset Password"
          onOk={onApproveAccountOk}
          onCancel={onApproveAccountCancel}
        >
          <div className="input-form">
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">
                New Password
              </label>
              <div className="input-group">
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  value={newPassword}
                  onChange={onNewPasswordChanged}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="newPasswordConfirm" className="form-label">
                Confirm New Password
              </label>
              <div className="input-group">
                <input
                  type="password"
                  className="form-control"
                  id="newPasswordConfirm"
                  value={newPasswordConfirm}
                  onChange={onNewPasswordConfirmChanged}
                />
              </div>
            </div>
            <div className="col-md-12 text-center mt-3">
              {errorMessage !== "" && (
                <div className="error-message">{errorMessage}</div>
              )}
            </div>
          </div>
        </ModalWindow>
      )}
    </BoxContent>
  );
};

export default UserEdit;
