export default class Settings {
  public idSettings: number = 0;
  public idUser: number = 0;
  public showChainage: boolean = false;
  public showChainageUnit: boolean = false;
  public onShowChainageChanged: any;
  public onShowChainageUnitChanged: any;
  public onSettingsChanged: any;
  public playbackSpeed: number = 100;
  public showTableHelperColumns: boolean = false;
  public onShowTableHelperColumnsChanged: any;
  public isChainageInMeters: boolean = false;
  public onIsChainageInMetersChanged: any;

  public showCameraImageUrl: boolean = false;
  public onShowCameraImageUrlChangedCallbacks: any[] = [];
  public addOnShowCameraImageUrlChangedCallback(callback: any) {
    this.onShowCameraImageUrlChangedCallbacks.push(callback);
  }

  public showDistress: boolean = false; // portal admins temp override
  public showPavementDistress: boolean = false;
  public showPavementDistressBoxes: boolean = false;
  public showPavementDistressLabels: boolean = false;
  public showPavementEvents: boolean = false;
  public showPavementMarkingsDuringPlayback: boolean = false;

  public onShowDistressChanged: any; // portal admins temp override
  public onShowPavementDistressChanged: any;
  public onShowPavementDistressBoxesChanged: any;
  public onShowPavementDistressLabelsChanged: any;
  public onShowPavementEventsChanged: any;
  public onShowPavementMarkingsDuringPlaybackChanged: any;
}
