import JobStatus from "./jobStatus";

export default class Job {
  public idJob: number = 0;
  public idUser: number = 0;
  public idProject: number = 0;
  public jobGuid: string = "00000000-0000-0000-0000-000000000000";
  public jobName: string = "";
  public jobStatus: string = JobStatus.Scheduled;
  public statusMessage: string = "";
  public isCancelled: boolean = false;
  public createDate: Date | undefined = undefined;
  public startDate: Date | undefined = undefined;
  public endDate: Date | undefined = undefined;
  public expiryDate: Date | undefined = undefined;
  public expiryDateUtc: Date | undefined = undefined;
}
