import axios from "axios";
import { toast } from "react-toastify";
import appSettings from "../appSettings";
import LogService from "./logService";

//axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = `${appSettings.serverUrl}/api`;

axios.interceptors.response.use(undefined, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    const logger = new LogService(true);
    logger.log("Logging the error", error);
    toast.error("An unexpected error occured.");
  }
  return Promise.reject(error); // return control to the catch blocks
});

const setAccessToken = (accessToken: string) => {
  axios.defaults.headers.common = {
    Authorization: `Bearer ${accessToken}`,
  };
};

const removeAccessToken = () => {
  delete axios.defaults.headers.common["Authorization"];
};

const httpService = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setAccessToken,
  removeAccessToken,
};

export default httpService;
