import { useNavigate } from "react-router-dom";
import Organization from "../../models/organization";
import { useEffect, useState } from "react";
import SignupDomain from "../../models/signupDomain";
import organizationService from "../../services/organizationService";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import AdminBoxConent from "../../components/admin/AdminBoxContent";

const SignupDomains: React.FC<{ organization: Organization | undefined }> = ({
  organization,
}) => {
  const navigate = useNavigate();

  const [signupDomains, setSignupDomains] = useState<SignupDomain[]>([]);

  useEffect(() => {
    if (!organization) {
      return;
    }

    init(organization);
  }, [organization]);

  const init = async (organization: Organization) => {
    const { data: signupDomains } = await organizationService.getSignupDomains(
      organization.idOrganization
    );
    setSignupDomains(signupDomains);
  };

  const onNewSignupDomain = () => {
    navigate("/admin/signup-domains/new");
  };

  const onEditSignupDomain = (signupDomain: SignupDomain) => {
    navigate(`/admin/signup-domains/${signupDomain.idSignupDomain}`);
  };

  return (
    <AdminBoxConent
      title="Signup Domains"
      onAddNew={() => onNewSignupDomain()}
      count={signupDomains.length}
      countType="signup domains"
      hasCurrentOrganizationDropdown={true}
    >
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Domain Name</th>
            <th scope="col">Is Auto Approved</th>
            <th style={{ width: "50px" }} />
          </tr>
        </thead>
        <tbody>
          {signupDomains.map((signupDomain: SignupDomain) => (
            <tr key={signupDomain.idSignupDomain.toString()}>
              <td>@{signupDomain.domainName}</td>
              <td>{_.upperFirst(signupDomain.isAutoApproved.toString())}</td>
              <td>
                <EditIcon
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  fontSize="small"
                  onClick={() => onEditSignupDomain(signupDomain)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AdminBoxConent>
  );
};

export default SignupDomains;
