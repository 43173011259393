import WorkspaceLayout from "./workspaceLayout";

export default class Workspace {
  public idWorkspace: number = 0;
  public idUser: number = 0;
  public idProject: number = 0;
  public workspaceName: string = "";
  public layout: string = "";
  public workspaceLayout: WorkspaceLayout = new WorkspaceLayout();

  public setWorkspaceLayoutFromLayout() {
    this.workspaceLayout = JSON.parse(this.layout);
  }

  public setLayoutFromWorkspaceLayout() {
    this.layout = JSON.stringify(this.workspaceLayout);
  }
}
