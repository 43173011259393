import * as olSource from "ol/source";

function xyz({
  url,
  attributions,
  minZoom,
  maxZoom,
}: {
  url: any;
  attributions?: any | undefined;
  minZoom?: number | undefined;
  maxZoom?: number | undefined;
}): olSource.XYZ {
  return new olSource.XYZ({ url, attributions, minZoom, maxZoom });
}

export default xyz;
