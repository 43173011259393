import { useContext, useEffect } from "react";
import MapContext from "../contexts/mapContext";
import OLVectorTileLayer from "ol/layer/VectorTile";

const VectorTileLayer: React.FC<{
  source: any;
  style?: any;
  zIndex?: number;
}> = ({ source, style, zIndex = 0 }) => {
  const map = useContext(MapContext);
  useEffect(() => {
    if (!map) {
      return;
    }

    let vectorTileLayer = new OLVectorTileLayer({
      source,
      style,
    });

    map.addLayer(vectorTileLayer);

    vectorTileLayer.setZIndex(zIndex);

    return () => {
      if (map) {
        map.removeLayer(vectorTileLayer);
      }
    };
  }, [map, source, style, zIndex]);
  return null;
};

export default VectorTileLayer;
