import { useLocation, useNavigate } from "react-router-dom";
import Logo from "./../../assets/images/logo.png";
import HomeIcon from "./../../assets/images/home.png";
import UserNavBarItem from "./UserNavBarItem";
import { useEffect, useRef, useState } from "react";
import { Tooltip } from "bootstrap";
import appSettings from "../../appSettings";

const NavBar: React.FC<{
  underLogoContent?: any | undefined;
  leftContent?: any | undefined;
  rightContent?: any | undefined;
}> = ({ underLogoContent, leftContent, rightContent }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showHomeButton, setShowHomeButton] = useState<boolean>(false);
  const [homeButtonTooltip, setHomeButtonTooltip] = useState<Tooltip>();
  const homeButtonRef = useRef<HTMLDivElement>(null);

  if (!leftContent) {
    leftContent = <div></div>;
  }

  useEffect(() => {
    const showHomeButton = location.pathname !== "/";
    setShowHomeButton(showHomeButton);

    return () => {
      if (homeButtonTooltip) {
        if ((homeButtonTooltip as any)._element !== null) {
          // console.log("homeButtonTooltip-dispose");
          homeButtonTooltip.dispose();
        }
      }
    };
  }, [location.pathname, homeButtonTooltip]);

  useEffect(() => {
    if (homeButtonRef.current) {
      // console.log("homeButtonTooltip-new");
      const homeButtonTooltip = new Tooltip(homeButtonRef.current, {
        trigger: "hover",
      });
      setHomeButtonTooltip(homeButtonTooltip);
    }
  }, [showHomeButton]);

  return (
    <>
      <div className="version">{appSettings.appVersion}</div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="d-flex flex-column">
            <div
              className="navbar-nav-fa navbar-brand d-flex align-items-center logo-title"
              onClick={() => navigate("/")}
            >
              <div style={{ height: "30px" }}>
                <img
                  src={Logo}
                  style={{
                    height: "30px",
                    marginRight: "0px",
                    marginLeft: "-5px",
                    marginTop: "0px",
                  }}
                  alt=""
                ></img>
              </div>
            </div>
            <div>{underLogoContent}</div>
          </div>
          <div
            className="navbar-nav justify-content-between"
            style={{ width: "100%" }}
          >
            {leftContent}
            <div className="navbar-nav">
              {rightContent}
              {showHomeButton && (
                <div className="navbar-nav">
                  <div
                    className="navbar-nav-fa nav-link nav-link-image"
                    style={{ paddingLeft: "0px" }}
                    onClick={() => navigate("/")}
                  >
                    <div
                      ref={homeButtonRef}
                      // data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Home"
                    >
                      <img
                        src={HomeIcon}
                        style={{
                          height: "23px",
                          marginTop: "-3px",
                        }}
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              )}
              <UserNavBarItem />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
