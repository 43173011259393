enum Unit {
  None = "None",
  //Length
  Meter = "Meter",
  Centimeter = "Centimeter",
  Millimeter = "Millimeter",
  Micron = "Micron",
  Hectometer = "Hectometer",
  Kilometer = "Kilometer",
  Yard = "Yard",
  Foot = "Foot",
  Inch = "Inch",
  Mile = "Mile",
  //Area
  SquareMeter = "SquareMeter",
  SquareFoot = "SquareFoot",
  SquareKilometer = "SquareKilometer",
  SquareMile = "SquareMile",
  //Slope
  RisePerRun = "RisePerRun",
  SlopePercent = "SlopePercent",
  MillimeterPerMeter = "MillimeterPerMeter",
  MeterPerKilometer = "MeterPerKilometer",
  InchPerMile = "InchPerMile",
  //Speed
  MetersPerSecond = "MetersPerSecond",
  KilometersPerHour = "KilometersPerHour",
  MilesPerHour = "MilesPerHour",
  //Acceleration
  MetersPerSecondsSquared = "MetersPerSecondsSquared",
  FeetPerSecondsSquared = "FeetPerSecondsSquared",
  Gal = "Gal",
  GravitationalAcceleration = "GravitationalAcceleration",
  //Temperature
  Celsius = "Celsius",
  Fahrenheit = "Fahrenheit",
  Kelvin = "Kelvin",
  //Angle
  AngleDegree = "AngleDegree",
  Radian = "Radian",
  AnglePercent = "AnglePercent",
  //ElectricTension
  Volt = "Volt",
  Millivolt = "Millivolt",
}

export default Unit;
