import { AxiosHeaders, AxiosResponse } from "axios";

const exportFile = (response: AxiosResponse<any, any>) => {
  const { data: fileData } = response;

  let reponseContentType: string | undefined;

  const headers = response.headers;
  if (headers instanceof AxiosHeaders && headers.has("content-type")) {
    reponseContentType = headers["content-type"];
  }

  const downloadFileName = reponseContentType?.replace("application/", "");

  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = downloadFileName ?? "file.txt";
  link.href = url;
  link.click();
  URL.revokeObjectURL(url);
};

const fileService = {
  exportFile,
};

export default fileService;
