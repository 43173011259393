import {
  Grid as _Grid,
  GridProps,
  InfiniteLoader as _InfiniteLoader,
  InfiniteLoaderProps,
  ScrollSync as _ScrollSync,
  ScrollSyncProps,
} from "react-virtualized";
import { scrollbarSize } from "dom-helpers";
import {
  FC,
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import unitConverter from "../../util/units/unitConverter";
import numberHelper from "../../util/numberHelper";
import stringHelper from "../../util/stringHelper";
import moment from "moment";
import dateTimeHelper from "../../util/dateTimeHelper";

export interface VirtualizedTableRef {
  init: () => Promise<void>;
  refresh: () => void;
}

const Grid = _Grid as unknown as FC<GridProps>;
const InfiniteLoader = _InfiniteLoader as unknown as FC<InfiniteLoaderProps>;
const ScrollSync = _ScrollSync as unknown as FC<ScrollSyncProps>;

type onGetTableColumnsType = () => Promise<any[]>;
type onGetTableRowsCountType = () => Promise<number>;
type onLoadMoreItemsType = (
  startIndex: number,
  stopIndex: number
) => Promise<any[]>;
type onRowSelectedType = (index: number, items: any[]) => void;

interface Props {
  selectedRowIndexValue: number | undefined;
  scrollToRowIndexValue: Number | undefined;
  onGetTableColumns: onGetTableColumnsType;
  onGetTableRowsCount: onGetTableRowsCountType;
  onLoadMoreItems: onLoadMoreItemsType;
  onRowSelected: onRowSelectedType;
  showUnitsInColumnHeaders: boolean;
  showHelperColumns: boolean;
  helperColumnNames: string[];
  //children: React.ReactNode;
}

const VirtualizedTable = forwardRef(
  (props: Props, ref: Ref<VirtualizedTableRef>) => {
    const {
      selectedRowIndexValue,
      scrollToRowIndexValue,
      onGetTableColumns,
      onGetTableRowsCount,
      onLoadMoreItems,
      onRowSelected,
      showUnitsInColumnHeaders,
      showHelperColumns,
      helperColumnNames,
    } = props;

    useImperativeHandle(ref, () => ({ init, refresh }));

    const [itemsCount, setItemsCount] = useState<number>(0);

    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState<number>(0);

    let gridRef = useRef<HTMLDivElement>(null);

    let headerGridRef = useRef<any>(null);
    let infinteLoaderRef = useRef<any>(null);

    const [header, setHeader] = useState<any[]>([]);
    const [items, setItems] = useState<any[]>([]);

    const [itemStatusMap, setItemStatusMap] = useState<number[]>([]);

    const [selectedRowIndex, setSelectedRowIndex] = useState<
      number | undefined
    >();

    const [scrollToRowIndex, setScrollToRowIndex] = useState<
      Number | undefined
    >();

    const [isInvokeRefreshSelectedRow, setIsInvokeRefreshSelectedRow] =
      useState<boolean>(false);

    useEffect(() => {
      setSelectedRowIndex(selectedRowIndexValue);
    }, [selectedRowIndexValue]);

    useEffect(() => {
      // set the scrollToRowIndex from input param so it can be passed to the table, and then clear it below
      setScrollToRowIndex(scrollToRowIndexValue?.valueOf()); // this is reference type using boxing so that even if the number is the same, it needs to bring it back in view all the time
      //   console.log(
      //     "virtualizedTable:scrollToRowIndexValue",
      //     scrollToRowIndexValue
      //   );
    }, [scrollToRowIndexValue]);

    useEffect(() => {
      // console.log("vrtualizedTable:useEffect:clearScrollToRowIndex");

      // clear scrollToRowIndex (as it was previously set by the table, else table will bring selected row in view on any scroll)
      if (scrollToRowIndex !== undefined) {
        setScrollToRowIndex(undefined);
      }
    }, [scrollToRowIndex]);

    const init = async () => {
      // console.log("init");
      const tableColumns = await onGetTableColumns();
      // console.log("colums loaded");

      const header: any[] = [];

      for (const tableColumn of tableColumns) {
        header.push({
          label: tableColumn.displayName,
          dataKey: tableColumn.columnName,
          unit: tableColumn.unitMeasure,
          decimals: tableColumn.decimals,
        });
      }

      if (showHelperColumns) {
        for (const helperColumnName of helperColumnNames) {
          header.push({
            label: helperColumnName,
            dataKey: helperColumnName,
            unit: "",
            decimals: 3,
          });
        }
      }

      setHeader(header);

      const tableRowsCount = await onGetTableRowsCount();
      // console.log("row count done");

      let _itemsCount = tableRowsCount;
      setItemsCount(_itemsCount);

      let placeHolder = [];
      for (let i = 0; i < _itemsCount; i++) {
        placeHolder[i] = {};
      }
      setItems(placeHolder);

      setItemStatusMap([]);

      // setSelectedRowIndex(undefined);

      // console.log("placeholder cleared");

      setSize();

      // if (filtersChanged) {
      //   // bad - super bad all the time on filters changed, random effects as it reloads the old data sometimes... and all sort of strange stuff
      //   //infinteLoaderRef.current.resetLoadMoreRowsCache(true);
      //   // bad after a couple of filters changed, sometimes when setting back filter to * again it doesn't jump to row and table looks wierd
      //   // 0 ... minimumBatchSize
      //   //await loadMoreItems({ startIndex: 0, stopIndex: 99 });
      // }

      return Promise.resolve();
    };

    // useEffect(() => {
    //   console.log("useeffect-init");
    //   init();

    //   setSize();
    // }, [init]);

    // useEffect(() => {
    //   console.log("VirtulizedTable:init");
    //   // init();

    //   // setSize();

    //   return () => console.log("VirtulizedTable:uninit");
    // }, []);

    // const LOADING = 1;
    const LOADED = 2;

    let timeoutId: any;

    const isItemLoaded = (index: any) => !!itemStatusMap[index];
    const loadMoreItems = ({
      startIndex,
      stopIndex,
    }: {
      startIndex: any;
      stopIndex: any;
    }) => {
      // console.log(
      //   `table: load more items startIndex: ${startIndex}, stopIndex: ${stopIndex}`
      // );

      // for (let index = startIndex; index <= stopIndex; index++) {
      //   itemStatusMap[index] = LOADING;
      // }

      return new Promise<void>(async (resolve) => {
        if (timeoutId) {
          clearInterval(timeoutId);
        }

        timeoutId = setTimeout(async () => {
          const tableData = await onLoadMoreItems(startIndex, stopIndex);

          // if (selectedRowIndex === undefined) {
          //   // first time app load, we should always have a selected row, so don't load the first page all the time until a selected row is set
          //   return;
          // }

          const newItems = [...items];

          // var chunkSize = stopIndex - startIndex + 1;
          // for (let i = 0; i < chunkSize; i++) {
          //   const itemIndex = startIndex + i;
          //   newItems[itemIndex] = tableData[i];
          // }

          for (let index = startIndex; index <= stopIndex; index++) {
            newItems[index] = tableData[index - startIndex];
          }

          setItems(newItems);

          const newItemStatusMap = [...itemStatusMap];

          for (let index = startIndex; index <= stopIndex; index++) {
            newItemStatusMap[index] = LOADED;
          }

          setItemStatusMap(newItemStatusMap);

          setIsInvokeRefreshSelectedRow(true);

          measureColumnWidths();

          resolve();
        }, 100);
      });
    };

    const headerRenderer = ({
      columnIndex,
      key,
      rowIndex,
      style,
    }: {
      columnIndex: any;
      key: any;
      rowIndex: any;
      style: any;
    }) => {
      const columnHeaderDisplayLabel = getColumnHeaderDisplayLabel(columnIndex);
      return (
        <div key={key} style={{ ...style, fontWeight: "bold" }}>
          {columnHeaderDisplayLabel}
        </div>
      );
    };

    const cellRenderer = ({
      columnIndex,
      key,
      rowIndex,
      style,
    }: {
      columnIndex: any;
      key: any;
      rowIndex: any;
      style: any;
    }) => {
      const rowClass = rowIndex % 2 === 0 ? "evenRow" : "oddRow";

      // console.log("columnName", columnName);
      // console.log("rowIndex", rowIndex);
      // console.log("items[rowIndex]", items[rowIndex]);
      // console.log("items.length", items.length);
      // console.log("items", items);

      const cellValue = getCellValue(rowIndex, columnIndex);

      if (itemStatusMap[rowIndex] === LOADED) {
        return (
          <div
            key={key}
            style={style}
            className={rowClass + " rowCell row" + rowIndex.toString()}
            onMouseEnter={() => onMouseEneter(rowIndex)}
            onMouseLeave={() => onMouseLeave(rowIndex)}
            onMouseDown={() => onMouseClick(rowIndex)}
          >
            {items[rowIndex] && (
              <div style={{ marginTop: "2px" }}>{cellValue}</div>
            )}
          </div>
        );
      } else {
        return (
          <div key={key} style={style}>
            <div className="placeholder" style={{ width: "100%" }} />
          </div>
        );
      }
    };

    const getCellValue = (rowIndex: number, columnIndex: number) => {
      let cellValue = items[rowIndex][header[columnIndex]["dataKey"]];

      if (cellValue === undefined || cellValue === null) {
        return cellValue;
      }

      const unit = header[columnIndex].unit;
      const decimals = header[columnIndex].decimals;

      cellValue = unitConverter.convertValue(cellValue, unit);

      if (decimals !== -1) {
        cellValue = numberHelper.numberToNDigits(cellValue, decimals);
      }

      const isDateTimeValue = dateTimeHelper.isDateTimeValue(cellValue);
      if (isDateTimeValue) {
        const dateValue = new Date(cellValue);
        cellValue = moment(dateValue).format(dateTimeHelper.dateTimeFormat);
      }

      const isDateOnlyValue = dateTimeHelper.isDateOnlyValue(cellValue);
      if (isDateOnlyValue) {
        const dateValue = new Date(cellValue);
        cellValue = moment(dateValue).format(dateTimeHelper.dateFormat);
      }

      cellValue = stringHelper.replaceConsecutiveSpaces(
        cellValue.toString().trim()
      );

      return cellValue;
    };

    const refreshSelectedRow = () => {
      if (selectedRowIndex !== undefined) {
        selectRow(selectedRowIndex);
      }
    };

    const onScrollChange = () => {
      // setIsInvokeRefreshSelectedRow(true); // doesn't work here, causes exception
      refreshSelectedRow();

      // clear scrollToRowIndex
      // if (scrollToRowIndex !== undefined) {
      //   setTimeout(() => {
      //     setScrollToRowIndex(undefined);
      //   }, 100);
      // }
    };

    const selectRow = useCallback((rowIndex: number) => {
      let grid = gridRef.current;
      let rows = grid?.querySelectorAll(".rowCell");
      if (rows) {
        Array.from(rows).forEach((e) =>
          (e as HTMLElement).classList.remove("rowSelected")
        );
      }
      var row = getRow(rowIndex);
      for (let el of row) {
        el.classList.add("rowSelected");
      }
    }, []);

    const onMouseClick = (rowIndex: any) => {
      setSelectedRowIndex(rowIndex);
      onRowSelected(rowIndex, items);
    };

    const getRow = (rowIndex: number): Array<HTMLElement> => {
      let grid = gridRef.current;
      let rows = grid?.querySelectorAll(".row" + rowIndex);
      let elements: Array<HTMLElement> = [];
      if (rows) {
        Array.from(rows).forEach((e) => elements.push(e as HTMLElement));
      }
      return elements;
    };

    const onMouseEneter = (rowIndex: any) => {
      for (let el of getRow(rowIndex)) {
        el.classList.add("rowHover");
      }
    };

    const onMouseLeave = (rowIndex: any) => {
      for (let el of getRow(rowIndex)) {
        el.classList.remove("rowHover");
      }
    };

    useEffect(() => {
      if (isInvokeRefreshSelectedRow) {
        setIsInvokeRefreshSelectedRow(false);
      }

      if (selectedRowIndex !== undefined) {
        selectRow(selectedRowIndex);
      }
    }, [selectedRowIndex, selectRow, isInvokeRefreshSelectedRow]);

    useEffect(() => {
      // console.log("table:useEffect:clearScrollToRowIndex");

      // clear scrollToRowIndex
      if (scrollToRowIndex !== undefined) {
        setScrollToRowIndex(undefined);
      }
    }, [scrollToRowIndex]);

    const refresh = () => {
      // console.log("virtializedTableRefresh()");
      setTimeout(() => {
        setSize();
      }, 100);
    };

    const setSize = () => {
      setHeight(gridRef.current?.offsetHeight ?? 0);
      setWidth(gridRef.current?.offsetWidth ?? 0);
    };

    const columnCount = header.length;
    // const height: number = gridRef.current?.offsetHeight ?? 0;
    // const width: number = gridRef.current?.offsetWidth ?? 0;
    const overscanColumnCount = 0;
    const overscanRowCount = 5;
    const headerHeight = 24;
    const rowHeight = 24;
    let rowCount = items.length;

    const paddingPx = 2; //px

    const getColumnHeaderDisplayLabel = (columnIndex: number): string => {
      let columnHeaderDisplayLabel = header[columnIndex]["label"];
      const unit = unitConverter.getSymbol(header[columnIndex]["unit"]);

      if (unit && showUnitsInColumnHeaders) {
        columnHeaderDisplayLabel += " " + unit;
      }

      return columnHeaderDisplayLabel;
    };

    const calculateColumnWidh = (columnIndex: number) => {
      const charSizePixels = 10;
      const colHeaderLen = getColumnHeaderDisplayLabel(columnIndex).length;

      let maxColLen = 0;

      let currentStartIndex =
        selectedRowIndex !== undefined ? selectedRowIndex : 0;
      let currentStopIndex = currentStartIndex;

      currentStartIndex -= 100;
      currentStopIndex += 100;

      if (currentStartIndex < 0) {
        currentStartIndex = 0;
      }

      if (currentStopIndex > itemsCount) {
        currentStopIndex = itemsCount;
      }

      for (
        let rowIndex = currentStartIndex;
        rowIndex < currentStopIndex;
        rowIndex++
      ) {
        const cellValue = getCellValue(rowIndex, columnIndex);
        const cellValueLen =
          cellValue !== undefined && cellValue !== null
            ? cellValue.toString().length
            : 0;
        maxColLen = Math.max(maxColLen, cellValueLen);
      }

      const colWidth = charSizePixels * Math.max(colHeaderLen, maxColLen);

      return colWidth;
    };

    const onScrolling = () => {
      measureColumnWidths();
    };

    const measureColumnWidths = () => {
      // console.log("measureAllCells");
      headerGridRef.current?.recomputeGridSize();
      infinteLoaderRef.current?._registeredChild.measureAllCells();
    };

    return (
      <div
        ref={gridRef}
        style={{ height: "100%", width: "100%", fontSize: "12px" }}
      >
        <ScrollSync>
          {({
            clientHeight,
            clientWidth,
            onScroll,
            scrollHeight,
            scrollLeft,
            scrollTop,
            scrollWidth,
          }) => {
            onScrollChange();
            return (
              <div style={{ padding: paddingPx }}>
                <div>
                  <Grid
                    ref={headerGridRef}
                    className="HeaderGrid"
                    columnWidth={({ index }) => calculateColumnWidh(index)}
                    columnCount={columnCount}
                    height={headerHeight}
                    overscanColumnCount={overscanColumnCount}
                    cellRenderer={headerRenderer}
                    rowHeight={headerHeight}
                    rowCount={1}
                    scrollLeft={scrollLeft}
                    width={width - scrollbarSize() - paddingPx}
                  />
                </div>
                <div>
                  <InfiniteLoader
                    ref={infinteLoaderRef}
                    isRowLoaded={isItemLoaded}
                    loadMoreRows={loadMoreItems}
                    rowCount={itemsCount}
                    minimumBatchSize={100}
                  >
                    {({ onRowsRendered, registerChild }) => (
                      <Grid
                        ref={registerChild}
                        className="BodyGrid"
                        columnWidth={({ index }) => calculateColumnWidh(index)}
                        columnCount={columnCount}
                        height={height - headerHeight - paddingPx}
                        onScroll={(e) => {
                          onScrolling();
                          onScroll(e);
                        }}
                        overscanColumnCount={overscanColumnCount}
                        overscanRowCount={overscanRowCount}
                        cellRenderer={cellRenderer}
                        rowHeight={rowHeight}
                        rowCount={rowCount}
                        width={width - paddingPx}
                        scrollToRow={scrollToRowIndex?.valueOf()}
                        onSectionRendered={({
                          rowStartIndex,
                          rowStopIndex,
                        }: {
                          rowStartIndex: any;
                          rowStopIndex: any;
                        }) =>
                          onRowsRendered({
                            startIndex: rowStartIndex,
                            stopIndex: rowStopIndex,
                          })
                        }
                      />
                    )}
                  </InfiniteLoader>
                </div>
              </div>
            );
          }}
        </ScrollSync>
      </div>
    );
  }
);

export default VirtualizedTable;
