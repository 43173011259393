import Filter from "../models/filter";
import GpsPosition from "../models/gpsPosition";
import NavigatorPosition from "../models/navigatorPosition";
import RoadLayer from "../models/roadLayer";
import SessionGpsPosition from "../models/sessionGpsPosition";
import httpService from "./httpService";

const apiEndpoint = "/map";

const batchSize = 1000;

const getRoadLayer = (idProject: number, idRoad: number) => {
  return httpService.get<RoadLayer>(
    `${apiEndpoint}/getRoadLayer?idProject=${idProject}&idRoad=${idRoad}`
  );
};

const getSessionGpsPositions = (
  idProject: number,
  idSession: number,
  startIndex: number,
  stopIndex: number
) => {
  return httpService.get<SessionGpsPosition[]>(
    `${apiEndpoint}/getSessionGpsPositions?idProject=${idProject}&idSession=${idSession}&startIndex=${startIndex}&stopIndex=${stopIndex}`
  );
};

const getSessionGpsPositionIndex = (
  idProject: number,
  idSession: number,
  distance: number
) => {
  return httpService.get<number>(
    `${apiEndpoint}/getSessionGpsPositionIndex?idProject=${idProject}&idSession=${idSession}&distance=${distance}`
  );
};

const getSessionGpsPositionsCount = (idProject: number, idSession: number) => {
  return httpService.get<number>(
    `${apiEndpoint}/getSessionGpsPositionsCount?idProject=${idProject}&idSession=${idSession}`
  );
};

const getClosestNavigatorPositionForGpsPosition = (
  idProject: number,
  gpsPosition: GpsPosition,
  zoomLevel: number,
  filters: Filter[]
) => {
  return httpService.post<NavigatorPosition>(
    `${apiEndpoint}/getClosestNavigatorPositionForGpsPosition`,
    {
      idProject,
      gpsPosition,
      zoomLevel,
      filters,
    }
  );
};

const getUturnPositionForLocation = (
  idProject: number,
  navigatorPosition: NavigatorPosition
) => {
  return httpService.post<NavigatorPosition>(
    `${apiEndpoint}/getUturnPositionForLocation`,
    {
      idProject,
      navigatorPosition,
    }
  );
};

const mapService = {
  batchSize,
  getRoadLayer,
  getSessionGpsPositions,
  getSessionGpsPositionIndex,
  getSessionGpsPositionsCount,
  getClosestNavigatorPositionForGpsPosition,
  getUturnPositionForLocation,
};

export default mapService;
