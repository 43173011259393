import ProjectCollection from "../models/projectCollection";
import ProjectCollectionInfo from "../models/projectCollectionInfo";
import httpService from "./httpService";

const apiEndpoint = "/projects";

const getProjectCollectionsInfos = () => {
  return httpService.get<ProjectCollectionInfo[]>(
    `${apiEndpoint}/getProjectCollectionsInfos`
  );
};

const getProjectCollections = (idOrganization: number) => {
  return httpService.get<ProjectCollection[]>(
    `${apiEndpoint}/getProjectCollections?idOrganization=${idOrganization}`
  );
};

const getProjectCollectionById = (idProjectCollection: number) => {
  return httpService.get<ProjectCollection>(
    `${apiEndpoint}/getProjectCollectionById?idProjectCollection=${idProjectCollection}`
  );
};

const createProjectCollection = (projectCollection: ProjectCollection) => {
  return httpService.post<ProjectCollection>(
    `${apiEndpoint}/createProjectCollection`,
    projectCollection
  );
};

const updateProjectCollection = (projectCollection: ProjectCollection) => {
  return httpService.post(
    `${apiEndpoint}/updateProjectCollection`,
    projectCollection
  );
};

const deleteProjectCollection = (idProjectCollection: number) => {
  var data = new FormData();
  data.append("idProjectCollection", idProjectCollection.toString());
  return httpService.post(`${apiEndpoint}/deleteProjectCollection`, data);
};

const projectService = {
  getProjectCollectionsInfos,
  getProjectCollections,
  getProjectCollectionById,
  createProjectCollection,
  updateProjectCollection,
  deleteProjectCollection,
};

export default projectService;
