import React, { useContext, useEffect, useState } from "react";
import BoxContent from "../../components/common/BoxContent";
import { useNavigate, useParams } from "react-router-dom";
import organizationService from "../../services/organizationService";
import Validator from "../../components/common/Validator";
import ValidatorManager from "../../components/common/validationManager";
import UserGroup from "../../models/userGroup";
import userGroupService from "../../services/userGroupService";
import Organization from "../../models/organization";
import OrganizationContextModel from "../../contexts/organizationContextModel";
import OrganizationContext from "../../contexts/organizationContext";
import formHelper from "../../util/formHelper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const validator = {
  organizationName: "organizationName",
  organizationCode: "organizationCode",
  signupUserGroupName: "signupUserGroupName",
};

export const OrganizationEdit: React.FC = (props: any) => {
  const { id: idParam } = useParams();
  const navigate = useNavigate();

  const [isNew, setIsNew] = useState<boolean>();

  const [validatorManager] = useState<ValidatorManager>(new ValidatorManager());

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [organization, setOrganization] = useState<Organization>(
    new Organization()
  );
  const [signupUserGroupName, setSignupUserGroupName] = useState<string>("");
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);

  const organizationContext = useContext<OrganizationContextModel | undefined>(
    OrganizationContext
  );

  useEffect(() => {
    const initEdit = async (idOrganization: number) => {
      const { data: organization } =
        await organizationService.getOrganizationById(idOrganization);

      setOrganization(organization);

      validatorManager.setIsValid(validator.organizationName, true);
      validatorManager.setIsValid(validator.organizationCode, true);

      const { data: userGroups } =
        await userGroupService.getOrganizationUserGroups(idOrganization);

      setUserGroups(userGroups);
    };

    const isNew = idParam === "new";
    setIsNew(isNew);

    if (!isNew) {
      const idOrganization = Number(idParam);
      initEdit(idOrganization);
    }
  }, [idParam, validatorManager]);

  const onOrganizaionNameChanged = (event: any) => {
    const newOrganizationName = event.target.value;

    let newOrganization = {
      ...organization,
      organizationName: newOrganizationName,
    };

    const isValid = newOrganizationName.length !== 0;
    validatorManager.setIsValid(validator.organizationName, isValid);

    if (isNew) {
      const organizationCode = newOrganizationName.toLowerCase();
      newOrganization.organizationCode = organizationCode;
      validatorManager.setIsValid(
        validator.organizationCode,
        organizationCode.length !== 0
      );

      const signupUserGroupName = newOrganizationName
        ? `${newOrganizationName} Users`
        : "";
      setSignupUserGroupName(signupUserGroupName);
      validatorManager.setIsValid(
        validator.signupUserGroupName,
        signupUserGroupName.length !== 0
      );
    }

    setOrganization(newOrganization);
  };

  const onOrganizaionCodeChanged = (event: any) => {
    const newValue = event.target.value;
    const newOrganization = { ...organization, organizationCode: newValue };
    setOrganization(newOrganization);
    validatorManager.setIsValid(
      validator.organizationCode,
      newValue.length !== 0
    );
  };

  const onSignupUserGroupNameChanged = (event: any) => {
    const newValue = event.target.value;
    setSignupUserGroupName(newValue);
    validatorManager.setIsValid(
      validator.signupUserGroupName,
      newValue.length !== 0
    );
  };

  const onSignupUserGroupChanged = (event: any) => {
    const newValue = event.target.value;
    const idUserGroup = Number(newValue);
    const userGroup = userGroups.find((ug) => ug.idUserGroup === idUserGroup);
    if (userGroup) {
      const newOrganization = {
        ...organization,
        idUserGroupSignup: userGroup.idUserGroup,
        signupUserGroup: userGroup,
      };
      setOrganization(newOrganization);
    }
  };

  const onIsPaidSubscriptionChanged = () => {
    const newValue = !organization.isPaidSubscription;
    let newOrganization = {
      ...organization,
      isPaidSubscription: newValue,
    };
    setOrganization(newOrganization);
  };

  const onSubscriptionRenewalDateChanged = (date: Date) => {
    // note that date from react DatePicker is local time, and we need and save utc
    // else, if we leave it like that, the date when it's sent to the server, the server thinks it's utc and converts it to local (practically 2x conv) and it's a mess

    let newDate = date;

    if (date) {
      newDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
    }

    let newOrganization = {
      ...organization,
      subscriptionRenewalDate: newDate,
    };
    setOrganization(newOrganization);
  };

  const onGoLiveDateChanged = (date: Date) => {
    // note that date from react DatePicker is local time, and we need and save utc
    // else, if we leave it like that, the date when it's sent to the server, the server thinks it's utc and converts it to local (practically 2x conv) and it's a mess

    let newDate = date;

    if (date) {
      newDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
    }

    let newOrganization = {
      ...organization,
      goLiveDate: newDate,
    };
    setOrganization(newOrganization);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    setErrorMessage("");

    if (!validatorManager.validate()) {
      return;
    }

    try {
      if (isNew) {
        await organizationService.createOrganization(
          organization.organizationName,
          organization.organizationCode,
          signupUserGroupName,
          organization.isPaidSubscription,
          organization.subscriptionRenewalDate,
          organization.goLiveDate
        );
      } else {
        await organizationService.updateOrganization(organization);
      }
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    await organizationContext?.loadOrganizations();

    navigate("/admin/organizations");
  };

  const onCancel = () => {
    navigate("/admin/organizations");
  };

  return (
    <BoxContent
      title={isNew ? "New Organization" : "Edit Organization"}
      useFullWidth
    >
      <form
        className="input-form"
        onSubmit={onSubmit}
        onKeyDown={formHelper.onEnterKeyDownPreventSubmit}
      >
        <div className="row">
          <div className="mb-3">
            <label htmlFor="organizationName" className="form-label">
              Organization Name
            </label>
            <div className="input-group">
              <Validator
                name={validator.organizationName}
                errorMessage="Please enter an organization name."
                validationManager={validatorManager}
              >
                <input
                  type="text"
                  className="form-control"
                  id="organizationName"
                  value={organization.organizationName}
                  onChange={onOrganizaionNameChanged}
                />
              </Validator>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="organizationCode" className="form-label">
              Organization Code
            </label>
            <div className="input-group">
              <Validator
                name={validator.organizationCode}
                errorMessage="Please enter an organization code."
                validationManager={validatorManager}
              >
                <input
                  type="text"
                  className="form-control"
                  id="organizationCode"
                  value={organization.organizationCode}
                  onChange={onOrganizaionCodeChanged}
                />
              </Validator>
            </div>
          </div>
          {isNew && (
            <div className="mb-3">
              <label htmlFor="signupUserGroupName" className="form-label">
                Signup User Group Name
              </label>
              <div className="input-group">
                <Validator
                  name={validator.signupUserGroupName}
                  errorMessage="Please enter an signup user group name."
                  validationManager={validatorManager}
                >
                  <input
                    type="text"
                    className="form-control"
                    id="signupUserGroupName"
                    value={signupUserGroupName}
                    onChange={onSignupUserGroupNameChanged}
                  />
                </Validator>
              </div>
            </div>
          )}
          {!isNew && (
            <div className="mb-3">
              <label htmlFor="signupUserGroup" className="form-label">
                Signup User Group
              </label>
              <select
                className="form-select"
                aria-label="select"
                id="signupUserGroup"
                value={organization.signupUserGroup?.idUserGroup ?? ""}
                onChange={onSignupUserGroupChanged}
              >
                {userGroups.map((userGroup, index) => (
                  <option key={index.toString()} value={userGroup.idUserGroup}>
                    {userGroup.userGroupName}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="mb-3">
            <div className="d-flex align-items-center">
              <label className="form-check-label" htmlFor="goLiveDate">
                Go Live Date
              </label>
              <div className="ms-2">
                <DatePicker
                  selected={organization.goLiveDate}
                  onChange={(date: Date) => onGoLiveDateChanged(date)}
                  dateFormat="MMM do yyyy"
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex align-items-center">
              <label className="form-check-label" htmlFor="isPaidSubscription">
                Is Paid Subscription
              </label>
              <div className="form-switch form-switch-right-text">
                <input
                  className="form-check-input form-switch-right-text"
                  type="checkbox"
                  checked={organization.isPaidSubscription}
                  onChange={onIsPaidSubscriptionChanged}
                  id="isPaidSubscription"
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="d-flex align-items-center">
              <label
                className="form-check-label"
                htmlFor="subscriptionRenewalDate"
              >
                Subscription Renewal Date
              </label>
              <div className="ms-2">
                <DatePicker
                  selected={organization.subscriptionRenewalDate}
                  onChange={(date: Date) =>
                    onSubscriptionRenewalDateChanged(date)
                  }
                  dateFormat="MMM do yyyy"
                />
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary">
          {isNew ? "Create" : "Save"}
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          style={{ marginLeft: "10px" }}
          onClick={onCancel}
        >
          Cancel
        </button>
        <div className="col-md-12 text-center mt-3">
          {errorMessage !== "" && (
            <div className="error-message">{errorMessage}</div>
          )}
        </div>
      </form>
    </BoxContent>
  );
};
