import UserGroup from "../models/userGroup";
import httpService from "./httpService";

const apiEndpoint = "/userGroups";

const getOrganizationUserGroups = (idOrganization: number) => {
  return httpService.get<UserGroup[]>(
    `${apiEndpoint}/getOrganizationUserGroups?idOrganization=${idOrganization}`
  );
};

const userGroupService = {
  getOrganizationUserGroups,
};

export default userGroupService;
