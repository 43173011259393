import Filter from "./filter";
import NavigatorPosition from "./navigatorPosition";

export type NavigatorMove = (
  navigatorPosition: NavigatorPosition
) => Promise<void>;
export type NavigatorMoveAllCompleted = () => void;

export type PlaybackIsPlaying = (isPlaying: boolean) => void;

export type SetFilters = (filters: Filter[]) => Promise<void>;

export default class Navigable {
  public id: string | undefined = undefined;
  public navigatorMove: NavigatorMove | undefined = undefined;
  public navigatorMoveAllCompleted: NavigatorMoveAllCompleted | undefined =
    undefined;
  public playbackIsPlayingChanged: PlaybackIsPlaying | undefined = undefined;
  public setFilters: SetFilters | undefined = undefined;
}
