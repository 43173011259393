import TableHelperData from "./tableHelperData";

export default class TableManager {
  private readonly idRoadColumnName: string = "IDRoad_Helper";
  private readonly idSessionColumnName: string = "IDSession_Helper";
  private readonly startDistanceColumnName: string = "StartDistance_Helper";
  private readonly endDistanceColumnName: string = "EndDistance_Helper";
  private readonly startChainageColumnName: string = "StartChainage_Helper";
  private readonly endChainageColumnName: string = "EndChainage_Helper";

  private currentItems: any[] = [];
  private currentStartIndex: number = 0;
  private currentStopIndex: number = 0;

  public setCurrentItems(items: any[], startIndex: number, stopIndex: number) {
    //console.log("table:manager:setCurrentItems", startIndex, stopIndex, items);
    this.currentItems = items;
    this.currentStartIndex = startIndex;
    this.currentStopIndex = stopIndex;
  }

  public clearCurrentItems() {
    this.currentItems = [];
    this.currentStartIndex = 0;
    this.currentStopIndex = 0;
  }

  public getItemIndex(
    idRoad: number,
    idSession: number,
    distance: number
  ): number {
    let index = -1;

    for (let i = 0; i < this.currentItems.length; i++) {
      const tableHelperData = this.getTableHelperData(this.currentItems[i]);
      if (
        idRoad === tableHelperData.idRoad &&
        idSession === tableHelperData.idSession &&
        distance >= tableHelperData.startDistance &&
        distance <= tableHelperData.endDistance
      ) {
        index = i + this.currentStartIndex;

        // console.log(
        //   "index-found",
        //   i,
        //   index,
        //   idRoad,
        //   idSession,
        //   distance,
        //   tableHelperData.startDistance,
        //   tableHelperData.endDistance
        // );

        break;
      }
    }

    return index;
  }

  public getTableHelperData(item: any): TableHelperData {
    const tableHelperData = new TableHelperData();
    tableHelperData.idRoad = item[this.idRoadColumnName];
    tableHelperData.idSession = item[this.idSessionColumnName];
    tableHelperData.startDistance = item[this.startDistanceColumnName];
    tableHelperData.endDistance = item[this.endDistanceColumnName];
    tableHelperData.startChainage = item[this.startChainageColumnName];
    tableHelperData.endChainage = item[this.endChainageColumnName];
    return tableHelperData;
  }

  public getHelperColumnNames(): string[] {
    return [
      this.idRoadColumnName,
      this.idSessionColumnName,
      this.startDistanceColumnName,
      this.endDistanceColumnName,
      this.startChainageColumnName,
      this.endChainageColumnName,
    ];
  }
}
