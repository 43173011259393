import { useState, useEffect, useRef } from "react";
import NavigatorPosition from "../../models/navigatorPosition";
import Navigator from "../../models/navigator";
import Navigable from "../../models/navigable";
import LogService from "../../services/logService";
import LogDirectory from "../../services/logDirectory";
import PlaybackManager from "../../models/playbackManager";
import ProjectInfo from "../../models/projectInfo";
import { Tooltip } from "bootstrap";
import PlaybackOptions from "./PlaybackOptions";
import Settings from "../../models/settings";
import ImageStream from "../../models/imageStream";

const logService = new LogService(LogDirectory.EnablePlaybackLogging);

const Playback: React.FC<{
  navigator: Navigator;
  project: ProjectInfo;
  cameras: ImageStream[];
  settings: Settings;
}> = ({ navigator, project, cameras, settings }) => {
  const [playbackManager, setPlaybackManager] = useState<PlaybackManager>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const playButtonRef = useRef<HTMLButtonElement>(null);
  const stopButtonRef = useRef<HTMLButtonElement>(null);

  const [playButtonTooltip, setPlayButtonTooltip] = useState<Tooltip>();
  const [stopButtonTooltip, setStopButtonTooltip] = useState<Tooltip>();

  useEffect(() => {
    logService.log("playback:init()");

    if (cameras.length === 0) {
      return;
    }

    const navigable = new Navigable();
    navigable.id = "playback";

    const playbackManager = new PlaybackManager(navigator, navigable, project);

    let playbackCameraName = "Forward";
    let camera = cameras.find((c) => c.cameraName === playbackCameraName);
    if (!camera) {
      playbackCameraName = cameras[0].cameraName;
    }

    // console.log("playbackCameraName", playbackCameraName);

    playbackManager.setCameraName(playbackCameraName);

    setPlaybackManager(playbackManager);

    playbackManager.onStop = () => {
      setIsPlaying(false);
    };

    const navigatorMove = async (navigatorPosition: NavigatorPosition) => {
      logService.log("playback:navigator:move()");

      await playbackManager.move(navigatorPosition);

      logService.log("playback:navigator:move()-done");
    };
    navigable.navigatorMove = navigatorMove;

    navigator.attach(navigable);

    return () => {
      navigator.detach(navigable);
    };
  }, [navigator, project, cameras]);

  useEffect(() => {
    //logService.log("playback:playstop:set-playback-tooltips1");

    if (isPlaying) {
      logService.log("playback:playstop:set-playback-tooltips-isPlaying");

      // distory play button, show stop button

      if (playButtonTooltip) {
        if ((playButtonTooltip as any)._element !== null) {
          logService.log("playback:playstop:playButtonTooltip-dispose");
          playButtonTooltip.dispose();
        }
      }

      if (stopButtonRef.current) {
        logService.log("playback:playstop:stopButtonTooltip-new");
        const stopButtonTooltip = new Tooltip(stopButtonRef.current, {
          trigger: "hover",
        });
        setStopButtonTooltip(stopButtonTooltip);
      }
    }
  }, [isPlaying, playButtonTooltip]);

  useEffect(() => {
    //logService.log("playback:playstop:set-playback-tooltips2");

    if (!isPlaying) {
      logService.log("playback:playstop:set-playback-tooltips-!isPlaying");

      // distory stop button, show play button

      if (stopButtonTooltip) {
        if ((stopButtonTooltip as any)._element !== null) {
          logService.log("playback:playstop:stopButtonTooltip-dispose");
          stopButtonTooltip.dispose();
        }
      }

      if (playButtonRef.current) {
        logService.log("playback:playstop:playButtonTooltip-new");
        const playButtonTooltip = new Tooltip(playButtonRef.current, {
          trigger: "hover",
        });
        setPlayButtonTooltip(playButtonTooltip);
      }
    }
  }, [isPlaying, stopButtonTooltip]);

  const onFirst = async () => {
    if (isBusy) {
      return;
    }
    setIsBusy(true);
    await playbackManager?.fist();
    setIsBusy(false);
  };

  const onPrev = async () => {
    if (isBusy) {
      return;
    }
    setIsBusy(true);
    await playbackManager?.prev();
    setIsBusy(false);
  };

  const onPlay = async () => {
    if (isPlaying) {
      return;
    }
    setIsPlaying(true);
    playbackManager?.play();
  };

  const onStop = async () => {
    setIsPlaying(false);
    playbackManager?.stop();
  };

  const onNext = async () => {
    if (isBusy) {
      return;
    }
    setIsBusy(true);
    await playbackManager?.next();
    setIsBusy(false);
  };

  const onLast = async () => {
    if (isBusy) {
      return;
    }
    setIsBusy(true);
    await playbackManager?.last();
    setIsBusy(false);
  };

  return (
    <div className="d-flex">
      <div className="btn-group-playback btn-group align-items-center menu-item-spacing-right">
        <button
          onClick={onFirst}
          className="btn btn-primary playback-button"
          type="button"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="First"
        >
          <i className="fa fa-angle-left"></i>
          <i className="fa fa-angle-left"></i>
        </button>
        <button
          onClick={onPrev}
          className="btn btn-primary playback-button"
          type="button"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Previous"
        >
          <i className="fa fa-angle-left"></i>
        </button>
        {!isPlaying && (
          <button
            ref={playButtonRef}
            onClick={onPlay}
            className={"btn btn-primary playback-button"}
            type="button"
            // data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Play"
          >
            <i className="fa fa-play"></i>
          </button>
        )}
        {isPlaying && (
          <button
            ref={stopButtonRef}
            onClick={onStop}
            className="btn btn-primary playback-button"
            type="button"
            // data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Stop"
          >
            <i className="fa fa-stop"></i>
          </button>
        )}
        <button
          onClick={onNext}
          className="btn btn-primary playback-button"
          type="button"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Next"
        >
          <i className="fa fa-angle-right"></i>
        </button>
        <button
          onClick={onLast}
          className="btn btn-primary playback-button"
          type="button"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Last"
        >
          <i className="fa fa-angle-right"></i>
          <i className="fa fa-angle-right"></i>
        </button>
      </div>
      <PlaybackOptions playbackManager={playbackManager} settings={settings} />
    </div>
  );
};

export default Playback;
