import AdminBoxConent from "../../components/admin/AdminBoxContent";
import Moment from "react-moment";
import dateTimeHelper from "../../util/dateTimeHelper";
import { useEffect, useState } from "react";
import User from "../../models/user";
import userService from "../../services/userService";

const PortalAdminUsers: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const init = async () => {
      const { data: users } = await userService.getPortalAdminUsers();
      setUsers(users);
    };

    init();
  }, []);

  return (
    <AdminBoxConent
      title="Portal Admin Users"
      hasCount={true}
      count={users.length}
      countType="portal admin users"
      hasAddNew={false}
      hasCurrentOrganizationDropdown={false}
    >
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Organization Name</th>
            <th scope="col">Email</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Last Login Date</th>
            <th scope="col">Last Activity Date</th>
            <th scope="col">Create Date</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user: User, index) => (
            <tr key={index.toString()}>
              <td>{user.userGroups[0].organizations[0].organizationName}</td>
              <td>{user.email}</td>
              <td>{user.firstName}</td>
              <td>{user.lastName}</td>
              <td>
                {user.lastLoginDate && (
                  <Moment format={dateTimeHelper.dateTimeFormat}>
                    {user.lastLoginDate}
                  </Moment>
                )}
              </td>
              <td>
                {user.lastActivityDate && (
                  <Moment format={dateTimeHelper.dateTimeFormat}>
                    {user.lastActivityDate}
                  </Moment>
                )}
              </td>
              <td>
                {user.createDate && (
                  <Moment format={dateTimeHelper.dateTimeFormat}>
                    {user.createDate}
                  </Moment>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </AdminBoxConent>
  );
};

export default PortalAdminUsers;
