import imageService from "../services/imageService";
import LogDirectory from "../services/logDirectory";
import LogService from "../services/logService";
import ImageCacheItem from "./imageCacheItem";

const objectURL = window.URL || window.webkitURL;

export default class ImagePreloader {
  private logService: LogService = new LogService(
    LogDirectory.EnableImagePreloaderLogging
  );

  private cameraName: string = "";

  private imageUrls: string[] = [];

  private imageCacheSize = imageService.preloaderCacheSize;
  public imageCache: ImageCacheItem[] = [];

  public setCameraName(cameraName: string) {
    this.cameraName = cameraName;
  }

  public setImageUrls(imageUrls: string[]): void {
    this.imageUrls = imageUrls;
    this.clearCache();
  }

  private getCacheImage(imageIndex: number): ImageCacheItem | undefined {
    const imageCacheItem = this.imageCache.find(
      (b) => b.imageIndex === imageIndex
    );
    return imageCacheItem;
  }

  public getImageOriginalUrl(imageIndex: number): string {
    return this.imageUrls[imageIndex];
  }

  public async getImage(imageIndex: number): Promise<string | null> {
    this.logService.log(
      `imagePreloader:[${this.cameraName}]:getImage`,
      imageIndex
    );

    this.preloadImages(imageIndex);

    let imageCacheItem = this.getCacheImage(imageIndex);

    // while (!imageCacheItem || imageCacheItem.imageObjectUrl === ImageCacheItem.loading) {
    //   if (!imageCacheItem) {
    //     imageCacheItem = await this.addCacheImage(imageIndex);
    //   } else if (imageCacheItem.imageObjectUrl === ImageCacheItem.loading) {
    //     const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    //     await sleep(100);
    //     imageCacheItem = this.getCacheImage(imageIndex);
    //   }
    // }

    let retryCount = 50;

    while (
      (!imageCacheItem ||
        imageCacheItem.imageObjectUrl === ImageCacheItem.LOADING) &&
      retryCount > 0
    ) {
      this.logService.log(
        `imagePreloader:[${this.cameraName}]:getImage:waiting`,
        imageIndex
      );

      // if (imageIndex !== 0) {
      //   alert("not-in-cache-yet");
      // }

      const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
      await sleep(100);
      imageCacheItem = this.getCacheImage(imageIndex);

      retryCount--;

      if (retryCount === 0) {
        this.logService.log(
          `imagePreloader:[${this.cameraName}]:getImage:waiting:freeze:failsafe`,
          imageIndex
        );
      }
    }

    let imageObjectUrl = null;

    if (imageCacheItem) {
      imageObjectUrl = imageCacheItem.imageObjectUrl;

      if (!imageCacheItem.imageObjectUrl) {
        this.logService.log(
          `imagePreloader:[${this.cameraName}]:getImage:error:null`,
          imageIndex
        );
      }
    }

    return imageObjectUrl;
  }

  //public async preloadImages(imageIndex: number): Promise<void> {
  private async preloadImages(imageIndex: number): Promise<void> {
    let startCacheIndex = imageIndex - imageService.preloaderBackCacheSize;
    // console.log(
    //   `imagePreloader:[${this.cameraName}]:preloadImages:startCacheIndex ${startCacheIndex}`
    // );
    if (startCacheIndex < 0) {
      startCacheIndex = 0;
    }

    let endCacheIndex = startCacheIndex + this.imageCacheSize;
    // console.log(
    //   `imagePreloader:[${this.cameraName}]:preloadImages:endCacheIndex ${endCacheIndex}`
    // );
    if (endCacheIndex >= this.imageUrls.length) {
      endCacheIndex = this.imageUrls.length;
    }

    const missingCacheIndexes = [];

    for (
      let cacheIndex = startCacheIndex;
      cacheIndex < endCacheIndex;
      cacheIndex++
    ) {
      const imageCacheItem = this.getCacheImage(cacheIndex);
      if (!imageCacheItem) {
        missingCacheIndexes.push(cacheIndex);
      }
    }

    //console.log("missingCacheIndexes", missingCacheIndexes);

    for (const missingCacheIndex of missingCacheIndexes) {
      await this.addCacheImage(missingCacheIndex);
    }
  }

  private async addCacheImage(imageIndex: number): Promise<ImageCacheItem> {
    this.logService.log(
      `imagePreloader:[${this.cameraName}]:addCacheImage ${imageIndex}`
    );

    this.logService.log(
      `imagePreloader:[${this.cameraName}]:imageCache.length ${this.imageCache.length}`
    );

    this.freeCache(imageIndex);

    const imageUrl = this.imageUrls[imageIndex];

    const imageCacheItem = new ImageCacheItem();
    imageCacheItem.imageIndex = imageIndex;
    this.imageCache.push(imageCacheItem);

    let imageData = await this.loadImage(imageUrl);

    this.logService.log(
      `imagePreloader:[${this.cameraName}]:loaded ${imageIndex}`
    );

    if (imageData) {
      imageCacheItem.imageObjectUrl = this.createImage(imageData);
    } else {
      imageCacheItem.imageObjectUrl = null;
    }

    return imageCacheItem;
  }

  private freeCache(addCacheImageIndex: number) {
    // console.log("freeCache");
    if (this.imageCache.length >= this.imageCacheSize) {
      const minIndex = Math.min(...this.imageCache.map((i) => i.imageIndex));
      const maxIndex = Math.max(...this.imageCache.map((i) => i.imageIndex));

      let removedImageCacheItem: ImageCacheItem | undefined = undefined;

      if (addCacheImageIndex > maxIndex) {
        // remove first
        removedImageCacheItem = this.imageCache.find(
          (i) => i.imageIndex === minIndex
        );
      } else {
        // remove last
        removedImageCacheItem = this.imageCache.find(
          (i) => i.imageIndex === maxIndex
        );
      }

      this.imageCache = this.imageCache.filter(
        (i) => i !== removedImageCacheItem
      );

      if (removedImageCacheItem?.imageObjectUrl) {
        // console.log(
        //   `imagePreloader:[${this.cameraName}]:freeCache:destroyImage ${removedImageCacheItem.imageIndex}, ${removedImageCacheItem.imageObjectUrl}`
        // );
        this.destroyImage(removedImageCacheItem.imageObjectUrl);
        removedImageCacheItem.imageObjectUrl = null;
      }
    }
  }

  public clearCache() {
    //console.log(`imagePreloader:[${this.cameraName}]:clearCache:destroyImage`);
    while (this.imageCache.length > 0) {
      const imageCacheItem = this.imageCache.pop();
      if (imageCacheItem?.imageObjectUrl) {
        this.destroyImage(imageCacheItem.imageObjectUrl);
        imageCacheItem.imageObjectUrl = null;
      }
    }
  }

  // memory leaks
  // private loadImage(imageUrl: string): HTMLImageElement {
  //   const image: HTMLImageElement = new Image();
  //   image.src = imageUrl;
  //   return image;
  // }

  private async loadImage(imageUrl: string): Promise<any | undefined> {
    let responseData = null;

    try {
      const response = await fetch(imageUrl);

      if (response.status === 404) {
        throw new Error(`Error - Not Found 404: ${imageUrl}`);
      }

      responseData = await response.arrayBuffer();

      // this adds the auth header and Azure doesn't like that
      // const reponse = await httpService.get(imageUrl, {
      //   responseType: "arraybuffer",
      // });
      //responseData = reponse.data;
    } catch (error: any) {
      this.logService.log(error);
    }

    return responseData;
  }

  private createImage(imageData: any): string {
    const array = new Uint8Array(imageData);
    let imageDataBlob: Blob | null = new Blob([array], { type: "image/jpeg" });
    const imageObjectUrl = objectURL.createObjectURL(imageDataBlob);
    imageDataBlob = null;
    return imageObjectUrl;
  }

  private destroyImage(imageObjectUrl: string) {
    objectURL.revokeObjectURL(imageObjectUrl);
  }
}
