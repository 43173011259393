import LogoComplete from "../../assets/images/logo-complete.png";

const LoginBoxContent: React.FC<{ children: any }> = ({ children }) => {
  return (
    <div className="container">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <div className="box-shadow" style={{ width: "400px", padding: "30px" }}>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ marginBottom: "30px" }}
          >
            <img
              src={LogoComplete}
              style={{
                height: "120px",
              }}
              alt=""
            ></img>
            {/* <div
          style={{
            fontSize: "30px",
            fontWeight: "600",
            marginLeft: "15px",
          }}
        >
          Inform
        </div> */}
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ marginBottom: "15px" }}
          >
            <div
              style={{
                fontSize: "30px",
                // fontWeight: "600",
                // fontWeight: "500",
              }}
            >
              Inform
            </div>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default LoginBoxContent;
