import ImageDownloadIncludeCamera from "./imageDownloadIncludeCamera";
import ImageDownloadIncludeLocator from "./imageDownloadIncludeLocator";
import ImageDownloadLocator from "./imageDownloadLocator";
import Job from "./job";

export default class ImageDownload {
  public idImageDownload: number = 0;
  public job: Job = new Job();
  public includeImageBanner: boolean = false;
  public includeLocatorsInFilename: boolean = false;
  public imageDownloadIncludeLocators: ImageDownloadIncludeLocator[] = [];
  public imageDownloadIncludeCameras: ImageDownloadIncludeCamera[] = [];
  public imageDownloadLocators: ImageDownloadLocator[] = [];
  public chainage: number = 0;
  public startDistance: number = 0;
  public endDistance: number = 0;
  public imageArchivePath: string | undefined = undefined;
  public imageArchiveUrl: string | undefined = undefined;
  public imageArchiveSize: number = 0;

  public hasImageArchiveLink(): boolean {
    if (this.imageArchiveUrl) {
      return true;
    }
    return false;
  }
}
