import { useEffect } from "react";
import BoxContent from "../components/common/BoxContent";
import NavBar from "../components/navbar/NavBar";
import UserProfile from "../components/user/UserProfile";
import pageHelper from "../util/pageHelper";
import tooltips from "../util/tooltips";

const Profile: React.FC = () => {
  useEffect(() => {
    pageHelper.setAutoScrollbars();
    tooltips.initTooltips();
  }, []);

  return (
    <>
      <NavBar />
      <BoxContent title="Profile">
        <UserProfile />
      </BoxContent>
    </>
  );
};

export default Profile;
