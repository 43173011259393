import LoadingIndicator from "./LoadingIndicator";

const FormMessage: React.FC<{
  message: string;
  errorMessage: string;
  isBusy: boolean;
}> = ({ message, errorMessage, isBusy }) => {
  const hasMessage = message !== "";
  const hasError = errorMessage !== "";

  return (
    <>
      {isBusy && (
        <div className="text-center">
          <LoadingIndicator className="mt-3 mb-3" />
        </div>
      )}
      {hasMessage && (
        <div className="col-md-12 text-center mt-3 mb-3">
          <div className="submit-ok">{message}</div>
        </div>
      )}
      {hasError && (
        <div className="col-md-12 text-center mt-3 mb-3">
          <div className="error-message">{errorMessage}</div>
        </div>
      )}
    </>
  );
};

export default FormMessage;
