import { useEffect, useState } from "react";
import AdminBoxConent from "../../components/admin/AdminBoxContent";
import userService from "../../services/userService";
import Moment from "react-moment";
import dateTimeHelper from "../../util/dateTimeHelper";
import ModalWindow from "../../components/common/ModalWindow";
import SignupApproval from "../../models/signupApproval";

const SignupApprovals: React.FC<{}> = () => {
  const [signupApprovals, setSignupApprovals] = useState<SignupApproval[]>([]);
  const [allSignupApprovals, setAllSignupApprovals] = useState<
    SignupApproval[]
  >([]);

  const [searchText, setSearchText] = useState<string>("");

  const [showApproveAccountWindow, setShowApproveAccountWindow] =
    useState<boolean>(false);

  const [signupApprovalDelete, setSignupApprovalDelete] =
    useState<SignupApproval>();

  const init = async () => {
    const { data: signupApprovals } = await userService.getSignupApprovals();
    setSignupApprovals(signupApprovals);
    setAllSignupApprovals(signupApprovals);
    setSearchText("");
  };

  useEffect(() => {
    init();
  }, []);

  const onApproveAccountConfirm = (signupApproval: SignupApproval) => {
    setShowApproveAccountWindow(true);
    setSignupApprovalDelete(signupApproval);
  };

  const onApproveAccountOk = async () => {
    setShowApproveAccountWindow(false);

    if (signupApprovalDelete?.user) {
      await userService.approveAccountByUsername(
        signupApprovalDelete.user.username
      );
    }

    await init();

    setSignupApprovalDelete(undefined);
  };

  const onApproveAccountCancel = () => {
    setShowApproveAccountWindow(false);
    setSignupApprovalDelete(undefined);
  };

  const onSearchTextChange = (event: any) => {
    const value = event.target.value;
    setSearchText(value);

    const searchToken = event.target.value.toLowerCase();

    const filteredSignupApprovals = allSignupApprovals.filter(
      (sa) =>
        sa.organization?.organizationName.toLowerCase().includes(searchToken) ||
        sa.user?.email.toLowerCase().includes(searchToken) ||
        sa.user?.firstName.toLowerCase().includes(searchToken) ||
        sa.user?.lastName.toLowerCase().includes(searchToken)
    );

    setSignupApprovals(filteredSignupApprovals);
  };

  return (
    <AdminBoxConent
      title="Signup Approvals - Not Auto Approved Domains (Emails Confirmed)"
      hasCount={true}
      count={signupApprovals.length}
      countType="signup approvals"
      hasAddNew={false}
      hasCurrentOrganizationDropdown={false}
    >
      <div className="mb-3">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={searchText}
            onChange={onSearchTextChange}
          />
          <button type="submit" className="btn btn-primary">
            Search
          </button>
        </div>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Organization Name</th>
            <th scope="col">Organization Auto Approved Domains</th>
            <th scope="col">Email</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Create Date</th>
            <th style={{ width: "200px" }} />
          </tr>
        </thead>
        <tbody>
          {signupApprovals.map((signupApproval: SignupApproval, index) => (
            <tr key={index.toString()}>
              <td>{signupApproval.organization?.organizationName}</td>
              <td>
                {signupApproval.organization?.signupDomains
                  .filter((sd) => sd.isAutoApproved)
                  .map((sd) => `@${sd.domainName}`)
                  .join(", ")}
              </td>
              <td>{signupApproval.user?.email}</td>
              <td>{signupApproval.user?.firstName}</td>
              <td>{signupApproval.user?.lastName}</td>
              <td>
                {signupApproval.user?.createDate && (
                  <Moment format={dateTimeHelper.dateTimeFormat}>
                    {signupApproval.user?.createDate}
                  </Moment>
                )}
              </td>
              <td>
                <button
                  className="btn btn-primary"
                  onClick={() => onApproveAccountConfirm(signupApproval)}
                >
                  Approve Account
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showApproveAccountWindow && (
        <ModalWindow
          title="Approve Account"
          onOk={onApproveAccountOk}
          onCancel={onApproveAccountCancel}
        >
          <div>
            Are you sure you want to approve account for:{" "}
            <b>{signupApprovalDelete?.user?.email}</b>?
            <br />
            <br />
            <b style={{ color: "red", fontSize: "13px" }}>
              Note that the email domain is NOT in Auto Approved list.
            </b>
          </div>
        </ModalWindow>
      )}
    </AdminBoxConent>
  );
};

export default SignupApprovals;
