import ImageDownload from "../models/imageDownload";
import ImageFrame from "../models/imageFrame";
import ImageStream from "../models/imageStream";
import ImageStreamFramesCount from "../models/imageStreamFramesCount";
import httpService from "./httpService";

const apiEndpoint = "/images";

const batchSize = 1000;
const preloaderBackCacheSize = 5;
const preloaderCacheSize = 30 + preloaderBackCacheSize;

const getImageStreams = (idProject: number) => {
  return httpService.get<ImageStream[]>(
    `${apiEndpoint}/getImageStreams?idProject=${idProject}`
  );
};

const getImageFrames = (
  idProject: number,
  idSession: number,
  cameraName: string,
  startIndex: number,
  stopIndex: number,
  startDistance: number = -1,
  endDistance: number = -1
) => {
  return httpService.get<ImageFrame[]>(
    `${apiEndpoint}/getImageFrames?idProject=${idProject}&idSession=${idSession}&cameraName=${cameraName}&startIndex=${startIndex}&stopIndex=${stopIndex}&startDistance=${startDistance}&endDistance=${endDistance}`
  );
};

const getImageFrameIndex = (
  idProject: number,
  idSession: number,
  distance: number,
  cameraName: string,
  startDistance: number = -1,
  endDistance: number = -1
) => {
  return httpService.get<number>(
    `${apiEndpoint}/getImageFrameIndex?idProject=${idProject}&idSession=${idSession}&distance=${distance}&cameraName=${cameraName}&startDistance=${startDistance}&endDistance=${endDistance}`
  );
};

const getImageFramesCount = (
  idProject: number,
  idSession: number,
  cameraName: string,
  startDistance: number = -1,
  endDistance: number = -1
) => {
  return httpService.get<number>(
    `${apiEndpoint}/getImageFramesCount?idProject=${idProject}&idSession=${idSession}&cameraName=${cameraName}&startDistance=${startDistance}&endDistance=${endDistance}`
  );
};

const getImageStreamsFramesCount = (
  idProject: number,
  idSession: number,
  startDistance: number = -1,
  endDistance: number = -1
) => {
  return httpService.get<ImageStreamFramesCount[]>(
    `${apiEndpoint}/getImageStreamsFramesCount?idProject=${idProject}&idSession=${idSession}&startDistance=${startDistance}&endDistance=${endDistance}`
  );
};

const getImageDownloads = async (
  idProject: number
): Promise<ImageDownload[]> => {
  const { data: imageDownloads } = await httpService.get<ImageDownload[]>(
    `${apiEndpoint}/getImageDownloads?idProject=${idProject}`
  );

  for (const imageDownload of imageDownloads) {
    Object.setPrototypeOf(imageDownload, ImageDownload.prototype);
  }

  // TODO, try catch and if this fails, then Promise.reject(data)?? to pass in the data.error custom error!?

  return Promise.resolve(imageDownloads);
};

const scheduleImageDownload = (imageDownload: ImageDownload) => {
  return httpService.post(
    `${apiEndpoint}/scheduleImageDownload`,
    imageDownload
  );
};

const removeImageDownload = (idImageDownload: number) => {
  return httpService.post(`${apiEndpoint}/removeImageDownload`, {
    idImageDownload,
  });
};

const cancelImageDownload = (idImageDownload: number) => {
  return httpService.post(`${apiEndpoint}/cancelImageDownload`, {
    idImageDownload,
  });
};

const imageService = {
  batchSize,
  preloaderBackCacheSize,
  preloaderCacheSize,
  getImageStreams,
  getImageFrames,
  getImageFrameIndex,
  getImageFramesCount,
  getImageStreamsFramesCount,
  getImageDownloads,
  scheduleImageDownload,
  removeImageDownload,
  cancelImageDownload,
};

export default imageService;
