import SignupApproval from "../models/signupApproval";
import SignupInfo from "../models/signupInfo";
import SignupPending from "../models/signupPending";
import User from "../models/user";
import UserStat from "../models/userStat";
import httpService from "./httpService";
import localStorageService from "./localStorageService";

const apiEndpoint = "/users";

const usersActiveLiveSeconds: number = 60; // last minute

const login = async (username: string, password: string): Promise<any> => {
  const { data }: any = await httpService.post(`${apiEndpoint}/login`, {
    username,
    password,
  });
  if (data.user) {
    Object.setPrototypeOf(data.user, User.prototype);
  }
  return Promise.resolve(data);
};

const getLoggedinUser = async (): Promise<User> => {
  const { data: user } = await httpService.get<any>(
    `${apiEndpoint}/getLoggedinUser`
  );
  if (user) {
    Object.setPrototypeOf(user, User.prototype);
  }
  return Promise.resolve(user);
};

const logout = () => {
  localStorageService.clearIdProjectCollection();
  localStorageService.clearLoginData();
  httpService.removeAccessToken();
};

const updateProfile = (
  firstName: string,
  lastName: string,
  isChangePassword: boolean,
  oldPassword: string | undefined,
  newPassword: string | undefined
) => {
  return httpService.post(`${apiEndpoint}/updateProfile`, {
    firstName,
    lastName,
    isChangePassword,
    oldPassword,
    newPassword,
  });
};

const signup = (signupInfo: SignupInfo) => {
  return httpService.post(`${apiEndpoint}/signup`, {
    ...signupInfo,
  });
};

const resendAccountConfirmation = (signupInfo: SignupInfo) => {
  return httpService.post(`${apiEndpoint}/resendAccountConfirmation`, {
    ...signupInfo,
  });
};

const confirmAccount = (accountConfirmationToken: string) => {
  return httpService.post(`${apiEndpoint}/confirmAccount`, {
    accountConfirmationToken,
  });
};

const confirmAccountByUsername = (username: string) => {
  return httpService.post(`${apiEndpoint}/confirmAccountByUsername`, {
    username,
  });
};

const approveAccountByUsername = (username: string) => {
  return httpService.post(`${apiEndpoint}/approveAccountByUsername`, {
    username,
  });
};

const forgotPassword = (email: string, resetPasswordUrl: string) => {
  return httpService.post(`${apiEndpoint}/forgotPassword`, {
    email,
    resetPasswordUrl,
  });
};

const resetPassword = (resetPasswordToken: string, newPassword: string) => {
  return httpService.post(`${apiEndpoint}/resetPassword`, {
    resetPasswordToken,
    newPassword,
  });
};

const getActiveUsers = (numberOfSecondsAgo: number) => {
  return httpService.get<UserStat[]>(
    `${apiEndpoint}/getActiveUsers?numberOfSecondsAgo=${numberOfSecondsAgo}`
  );
};

const getActiveUsersPastDays = (numberOfDaysAgo: number) => {
  return httpService.get<UserStat[]>(
    `${apiEndpoint}/getActiveUsersPastDays?numberOfDaysAgo=${numberOfDaysAgo}`
  );
};

const getSignupPendings = () => {
  return httpService.get<SignupPending[]>(`${apiEndpoint}/getSignupPendings`);
};

const getSignupApprovals = () => {
  return httpService.get<SignupApproval[]>(`${apiEndpoint}/getSignupApprovals`);
};

const getPortalAdminUsers = () => {
  return httpService.get<User[]>(`${apiEndpoint}/getPortalAdminUsers`);
};

const getUsers = () => {
  return httpService.get<User[]>(`${apiEndpoint}/getUsers`);
};

const getUserByIdUser = (idUser: number) => {
  return httpService.get<User>(
    `${apiEndpoint}/getUserByIdUser?idUser=${idUser}`
  );
};

const resetPasswordByIdUser = (idUser: number, newPassword: string) => {
  return httpService.post(`${apiEndpoint}/resetPasswordByIdUser`, {
    idUser,
    newPassword,
  });
};

const userService = {
  login,
  getLoggedinUser,
  logout,
  updateProfile,
  signup,
  resendAccountConfirmation,
  confirmAccount,
  confirmAccountByUsername,
  approveAccountByUsername,
  forgotPassword,
  resetPassword,
  usersActiveLiveSeconds,
  getActiveUsers,
  getActiveUsersPastDays,
  getSignupPendings,
  getSignupApprovals,
  getPortalAdminUsers,
  getUsers,
  getUserByIdUser,
  resetPasswordByIdUser,
};

export default userService;
