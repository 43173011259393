import BoxContent from "../common/BoxContent";
import CurrentOrganization from "./CurrentOrganization";

const AdminBoxConent: React.FC<{
  title: string;
  hasAddNew?: boolean;
  addNewTitle?: string;
  onAddNew?: any;
  addNew2Title?: string;
  onAddNew2?: any;
  isAddNew2Enabled?: boolean;
  hasCount?: boolean;
  count?: number;
  countType?: string;
  hasCurrentOrganizationDropdown: boolean;
  children: any;
}> = ({
  title,
  hasAddNew = true,
  addNewTitle = "Add New",
  onAddNew,
  addNew2Title,
  onAddNew2,
  isAddNew2Enabled = true,
  hasCount = true,
  count,
  countType,
  hasCurrentOrganizationDropdown,
  children,
}) => {
  return (
    <BoxContent
      title={title}
      useFullWidth
      leftChildren={
        <div>
          <div
            className="d-flex justify-content-end"
            style={{ visibility: hasAddNew ? "visible" : "hidden" }}
          >
            <button
              className="btn btn-primary"
              style={{ marginTop: "5px", marginBottom: "10px" }}
              onClick={() => onAddNew()}
            >
              {addNewTitle}
            </button>
            {addNew2Title && (
              <button
                className="btn btn-primary"
                style={{
                  marginTop: "5px",
                  marginBottom: "10px",
                  marginLeft: "10px",
                }}
                disabled={!isAddNew2Enabled}
                onClick={() => onAddNew2()}
              >
                {addNew2Title}
              </button>
            )}
          </div>
          {hasCount && (
            <div className="d-flex justify-content-end">
              <p>{`${count} ${countType}`}</p>
            </div>
          )}
        </div>
      }
    >
      {hasCurrentOrganizationDropdown && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginTop: "-15px", marginBottom: "10px" }}
        >
          <div style={{ marginRight: "10px", fontWeight: "bold" }}>
            Organization
          </div>
          <CurrentOrganization />
        </div>
      )}
      {children}
    </BoxContent>
  );
};

export default AdminBoxConent;
