import * as olSource from "ol/source";
import * as olFormat from "ol/format";

function mvt({
  url,
  minZoom,
  maxZoom,
}: {
  url: any;
  minZoom?: number | undefined;
  maxZoom?: number | undefined;
}): olSource.VectorTile {
  return new olSource.VectorTile({
    url,
    format: new olFormat.MVT(),
    minZoom,
    maxZoom,
  });
}

export default mvt;
