import Workspace from "../models/workspace";
import httpService from "./httpService";

const apiEndpoint = "/workspaces";

const getWorkspace = async (
  defaultWorkspace: Workspace
): Promise<Workspace> => {
  const { data: workspace } = await httpService.post<Workspace>(
    `${apiEndpoint}/getWorkspace`,
    defaultWorkspace
  );
  if (workspace) {
    Object.setPrototypeOf(workspace, Workspace.prototype);
    workspace.setWorkspaceLayoutFromLayout();
  }
  return Promise.resolve(workspace);
};

const saveWorkspace = (workspace: Workspace) => {
  return httpService.post(`${apiEndpoint}/saveWorkspace`, workspace);
};

const getProjectDefaultWorkspace = async (
  idProject: number
): Promise<Workspace> => {
  const { data: workspace } = await httpService.get<Workspace>(
    `${apiEndpoint}/getProjectDefaultWorkspace?idProject=${idProject}`
  );
  if (workspace) {
    Object.setPrototypeOf(workspace, Workspace.prototype);
    workspace.setWorkspaceLayoutFromLayout();
  }
  return Promise.resolve(workspace);
};

const setProjectDefaultWorkspace = (defaultWorkspace: Workspace) => {
  return httpService.post(
    `${apiEndpoint}/setProjectDefaultWorkspace`,
    defaultWorkspace
  );
};

const workspaceService = {
  getWorkspace,
  saveWorkspace,
  getProjectDefaultWorkspace,
  setProjectDefaultWorkspace,
};

export default workspaceService;
