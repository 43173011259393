import ImageStream from "../../models/imageStream";
import WindowIds from "../../models/layout/windowIds";
import WindowVisibility from "../../models/layout/windowVisibility";
import Navigator from "../../models/navigator";
import ProjectCollectionInfo from "../../models/projectCollectionInfo";
import ProjectInfo from "../../models/projectInfo";
import Settings from "../../models/settings";
import Chainage from "../chainage/Chainage";
import Locators from "../locators/Locators";
import Milepost from "../milepost/Milepost";
import Playback from "../playback/Playback";
import Uturn from "../uturn/Uturn";
import Year from "../year/Year";
import NavBar from "./NavBar";
import SettingsControl from "../settings/Settings";
import { useEffect, useState, useRef, useCallback } from "react";
import SessionInfo from "../info/SessionInfo";
import ImageDownloader from "../image-downloader/ImageDownloader";
import CameraPavementSettings from "../camera/CameraPavementSettings";

const NavBarHome: React.FC<{
  navigator: Navigator;
  project: ProjectInfo;
  projectCollection: ProjectCollectionInfo;
  cameras: ImageStream[];
  windowVisibilities: WindowVisibility[];
  onWindowVisibilityChanged: any;
  onYearChanged: any;
  settings: Settings;
  onResetLayout: any;
  onSetProjectDefaultLayout: any;
}> = ({
  navigator,
  project,
  projectCollection,
  cameras,
  windowVisibilities,
  onWindowVisibilityChanged,
  onYearChanged,
  settings,
  onResetLayout,
  onSetProjectDefaultLayout,
}) => {
  const getWindowVisibility = (
    windowId: string
  ): WindowVisibility | undefined => {
    return windowVisibilities.find((wv) => wv.windowId === windowId);
  };

  const [hasDistress, setHasDistress] = useState<boolean>(false);

  const [showPavementCameraSettings, setShowPavementCameraSettings] =
    useState<boolean>(false);

  const [showChainage, setShowChainage] = useState<boolean>(false);
  const [showChainageUnit, setShowChainageUnit] = useState<boolean>(false);

  const imageDownloaderDropdownToggleRef = useRef<HTMLDivElement>(null);
  const [openImageDownloader, setOpenImageDownloader] = useState<Boolean>(
    // eslint-disable-next-line
    new Boolean(false)
  );

  const onShowChainageChanded = (showChainage: boolean) => {
    setShowChainage(showChainage);
  };

  const onShowChainageUnitChanded = (showChainageUnit: boolean) => {
    setShowChainageUnit(showChainageUnit);
  };

  useEffect(() => {
    if (project) {
      setHasDistress(project.hasDistress);
    }
  }, [project]);

  useEffect(() => {
    setShowChainage(settings.showChainage);
    settings.onShowChainageChanged = onShowChainageChanded;

    setShowChainageUnit(settings.showChainageUnit);
    settings.onShowChainageUnitChanged = onShowChainageUnitChanded;

    settings.onShowDistressChanged = (showDistress: boolean) => {
      setHasDistress(showDistress);
    };
  }, [settings]);

  const [imageDownloaderBlink, setImageDownloaderBlink] =
    useState<boolean>(false);

  const onImageDownloaderHasRunningTasks = useCallback(
    (hasRunningTasks: boolean) => {
      setImageDownloaderBlink(hasRunningTasks);
    },
    []
  );

  return (
    <NavBar
      underLogoContent={
        <>
          {/* hidden div to make the position abosolute from below to occupy/allocate space on y axis so when text is large to move items to the right */}
          <div
            style={{
              visibility: "hidden",
              marginTop: "-25px",
              marginLeft: "3px",
              fontSize: "10px",
              fontWeight: "bold",
              textWrap: "nowrap",
            }}
          >
            {projectCollection.collectionName}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "-3px",
              fontSize: "10px",
              fontWeight: "bold",
            }}
          >
            {projectCollection.collectionName}
          </div>
        </>
      }
      leftContent={
        <div className="navbar-nav align-items-center">
          <div className="menu-item-spacing-right">
            <Playback
              navigator={navigator}
              project={project}
              cameras={cameras}
              settings={settings}
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "-3px",
              fontSize: "10px",
              marginLeft: "0px",
              fontWeight: "bold",
            }}
          >
            <SessionInfo navigator={navigator} />
          </div>
          <div
            className="menu-item-spacing-right"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Year
              project={project}
              projectCollection={projectCollection}
              onYearChanged={onYearChanged}
            ></Year>
            <Locators navigator={navigator} project={project} />
            {project.hasMilepostNavigation && (
              <Milepost navigator={navigator} project={project} />
            )}
            {showChainage && (
              <div className="menu-item-spacing-right">
                <Chainage
                  navigator={navigator}
                  project={project}
                  showChainageUnit={showChainageUnit}
                  settings={settings}
                />
              </div>
            )}
            {project.hasUturn && (
              <Uturn navigator={navigator} project={project} />
            )}
          </div>
        </div>
      }
      rightContent={
        <>
          {/* map */}
          <div className="navbar-nav">
            <div
              className="nav-link dropdown-toggle hidden-arrow"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Map"
              >
                <i className="navbar-nav-fa fa fa-map fa-lg"></i>
              </div>
            </div>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <button
                  className={
                    "dropdown-item" +
                    (getWindowVisibility(WindowIds.MapId)?.isVisibile
                      ? " active"
                      : "")
                  }
                  type="button"
                  onClick={() =>
                    onWindowVisibilityChanged(
                      WindowIds.MapId,
                      !getWindowVisibility(WindowIds.MapId)?.isVisibile
                    )
                  }
                >
                  Map
                </button>
              </li>
            </ul>
          </div>
          {/* cameras */}
          <div className="navbar-nav">
            <div
              className="nav-link dropdown-toggle hidden-arrow"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Camera"
              >
                <i className="navbar-nav-fa fa fa-camera fa-lg"></i>
              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-end">
              <div className={showPavementCameraSettings ? "d-flex" : ""}>
                <ul
                  style={{ listStyle: "none", margin: "0px", padding: "0px" }}
                >
                  {cameras.map((camera) => (
                    <li key={camera.cameraName}>
                      <button
                        className={
                          "dropdown-item" +
                          (getWindowVisibility(
                            WindowIds.getCameraId(camera.cameraName)
                          )?.isVisibile
                            ? " active"
                            : "")
                        }
                        type="button"
                        onClick={() =>
                          onWindowVisibilityChanged(
                            WindowIds.getCameraId(camera.cameraName),
                            !getWindowVisibility(
                              WindowIds.getCameraId(camera.cameraName)
                            )?.isVisibile
                          )
                        }
                      >
                        <div className="d-flex">
                          <div>Camera {camera.cameraName}</div>
                          {camera.cameraName === "Pavement" && hasDistress && (
                            <div
                              style={{ marginLeft: "15px" }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setShowPavementCameraSettings(
                                  !showPavementCameraSettings
                                );
                              }}
                            >
                              <i className="navbar-nav-fa fa fa-cog fa-lg"></i>
                            </div>
                          )}
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
                {showPavementCameraSettings && (
                  <div
                    style={{
                      borderWidth: "0px 0px 0px 3px",
                      borderStyle: "solid",
                      borderImage:
                        "linear-gradient(white, #808080cc, white) 1 100%",
                      marginLeft: "16px",
                      // border: "1px solid red",
                    }}
                  >
                    <CameraPavementSettings settings={settings} />
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* table */}
          <div className="navbar-nav">
            <div
              className="nav-link dropdown-toggle hidden-arrow"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Table"
              >
                <i className="navbar-nav-fa fa fa-table fa-lg"></i>
              </div>
            </div>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <button
                  className={
                    "dropdown-item" +
                    (getWindowVisibility(WindowIds.TableId)?.isVisibile
                      ? " active"
                      : "")
                  }
                  type="button"
                  onClick={() =>
                    onWindowVisibilityChanged(
                      WindowIds.TableId,
                      !getWindowVisibility(WindowIds.TableId)?.isVisibile
                    )
                  }
                >
                  Roads
                </button>
              </li>
            </ul>
          </div>
          <div className="navbar-nav">
            <div
              className="nav-link dropdown-toggle hidden-arrow"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
              ref={imageDownloaderDropdownToggleRef}
            >
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Downloads"
                className={imageDownloaderBlink ? "blink" : ""}
                onClick={() => {
                  const isOpen =
                    imageDownloaderDropdownToggleRef.current?.classList.contains(
                      "show"
                    );
                  if (isOpen) {
                    // eslint-disable-next-line
                    setOpenImageDownloader(new Boolean(true));
                  }
                }}
              >
                <i className="navbar-nav-fa fa fa-cloud-download fa-lg"></i>
              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-end">
              <div className="dropdown-item-padding">
                <ImageDownloader
                  navigator={navigator}
                  project={project}
                  onHasRunningTasks={onImageDownloaderHasRunningTasks}
                  open={openImageDownloader}
                />
              </div>
            </div>
          </div>
          <div className="navbar-nav">
            <div
              className="nav-link dropdown-toggle hidden-arrow"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                title="Settings"
              >
                <i className="navbar-nav-fa fa fa-cog fa-lg"></i>
              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-end">
              <SettingsControl
                settings={settings}
                project={project}
                onResetLayout={onResetLayout}
                onSetProjectDefaultLayout={onSetProjectDefaultLayout}
              />
            </div>
          </div>
        </>
      }
    />
  );
};

export default NavBarHome;
