export default class GpsPosition {
  public latitude: number;
  public longitude: number;
  public elevation: number;

  constructor(
    latitude: number = 0,
    longitude: number = 0,
    elevation: number = 0
  ) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.elevation = elevation;
  }
}
