import { DistressRecord } from "../models/distressRecord";
import PavementImageFrame from "../models/pavementImageFrame";
import httpService from "./httpService";

const apiEndpoint = "/pavementImages";

const getImageFrames = (
  idProject: number,
  idSession: number,
  cameraName: string,
  startIndex: number,
  stopIndex: number,
  startDistance: number = -1,
  endDistance: number = -1
) => {
  return httpService.get<PavementImageFrame[]>(
    `${apiEndpoint}/getPavementImageFrames?idProject=${idProject}&idSession=${idSession}&cameraName=${cameraName}&startIndex=${startIndex}&stopIndex=${stopIndex}&startDistance=${startDistance}&endDistance=${endDistance}`
  );
};

const getImageFrameIndex = (
  idProject: number,
  idSession: number,
  distance: number,
  cameraName: string,
  startDistance: number = -1,
  endDistance: number = -1
) => {
  return httpService.get<number>(
    `${apiEndpoint}/getPavementImageFrameIndex?idProject=${idProject}&idSession=${idSession}&distance=${distance}&cameraName=${cameraName}&startDistance=${startDistance}&endDistance=${endDistance}`
  );
};

const getImageFramesCount = (
  idProject: number,
  idSession: number,
  cameraName: string,
  startDistance: number = -1,
  endDistance: number = -1
) => {
  return httpService.get<number>(
    `${apiEndpoint}/getPavementImageFramesCount?idProject=${idProject}&idSession=${idSession}&cameraName=${cameraName}&startDistance=${startDistance}&endDistance=${endDistance}`
  );
};

const getDistressRecords = (
  idProject: number,
  idSession: number,
  startDistance: number,
  endDistance: number
) => {
  return httpService.get<DistressRecord[]>(
    `${apiEndpoint}/getDistressRecords?idProject=${idProject}&idSession=${idSession}&startDistance=${startDistance}&endDistance=${endDistance}`
  );
};

const pavementImageService = {
  getImageFrames,
  getImageFrameIndex,
  getImageFramesCount,
  getDistressRecords,
};

export default pavementImageService;
