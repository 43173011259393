import { useContext, useEffect, useState } from "react";
import UserContext from "../../contexts/userContext";
import User from "../../models/user";
import userService from "../../services/userService";

const UserProfile: React.FC = () => {
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>();

  const [updateOk, setUpdateOk] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const user = useContext<User | undefined>(UserContext);

  useEffect(() => {
    setFirstName(user?.firstName);
    setLastName(user?.lastName);
    setUsername(user?.username);
  }, [user]);

  const onFirstNameChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setFirstName(newValue);
  };

  const onLastNameChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setLastName(newValue);
  };

  const onChangePasswordCheckbox = () => {
    clearMessages();
    const newValue = !isChangePassword;
    setIsChangePassword(newValue);
  };

  const onOldPasswordChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setOldPassword(newValue);
  };

  const onNewPasswordChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setNewPassword(newValue);
  };

  const onNewPasswordConfirmChanged = (event: any) => {
    clearMessages();
    const newValue = event.target.value;
    setNewPasswordConfirm(newValue);
  };

  const onSubmit = async (event: any) => {
    event.preventDefault();

    clearMessages();

    if (!firstName) {
      setErrorMessage("First Name cannot be empty");
      return;
    }

    if (!lastName) {
      setErrorMessage("Last Name cannot be empty");
      return;
    }

    if (isChangePassword) {
      if (!oldPassword) {
        setErrorMessage("Old Password cannot be empty");
        return;
      }

      if (!newPassword) {
        setErrorMessage("New Password cannot be empty");
        return;
      }

      if (!newPasswordConfirm) {
        setErrorMessage("Confirm New Password cannot be empty");
        return;
      }

      if (newPassword !== newPasswordConfirm) {
        setErrorMessage("New Password and Confirm New Password don't match");
        return;
      }
    }

    // all good

    try {
      await userService.updateProfile(
        firstName,
        lastName,
        isChangePassword,
        oldPassword,
        newPassword
      );
    } catch (error: any) {
      const errorMessage = error.response.data;
      setErrorMessage(errorMessage);
      return;
    }

    if (user) {
      user.firstName = firstName;
      user.lastName = lastName;
      user.updateName();

      if (user.onUserInfoChanged) {
        user.onUserInfoChanged();
      }
    }

    setUpdateOk(true);
  };

  const clearMessages = () => {
    setErrorMessage("");
    setUpdateOk(false);
  };

  return (
    <form className="input-form" onSubmit={onSubmit}>
      <div className="row g-2">
        <div className="col-md">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="firstName"
              placeholder="First Name"
              value={firstName ?? ""}
              onChange={onFirstNameChanged}
            />
            <label htmlFor="firstName">First Name</label>
          </div>
        </div>
        <div className="col-md">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="Last Name"
              value={lastName ?? ""}
              onChange={onLastNameChanged}
            />
            <label htmlFor="lastName">Last Name</label>
          </div>
        </div>
      </div>
      <div className="form-floating mb-3">
        <input
          type="email"
          className="form-control"
          id="username"
          placeholder="Username"
          value={username ?? ""}
          disabled
        />
        <label htmlFor="username">Email</label>
      </div>
      <div className="form-check mb-3">
        <input
          className="form-check-input"
          type="checkbox"
          id="changePassword"
          checked={isChangePassword}
          onChange={onChangePasswordCheckbox}
        />
        <label className="form-check-label" htmlFor="changePassword">
          Change Password
        </label>
      </div>
      {isChangePassword && (
        <>
          <div className="form-floating mb-3">
            <input
              type="password"
              className="form-control"
              id="oldPassword"
              placeholder="Old Password"
              value={oldPassword ?? ""}
              onChange={onOldPasswordChanged}
            />
            <label htmlFor="oldPassword">Old Password</label>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  placeholder="New Password"
                  value={newPassword ?? ""}
                  onChange={onNewPasswordChanged}
                />
                <label htmlFor="newPassword">New Password</label>
              </div>
            </div>
            <div className="col-md">
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Confirm New Password"
                  value={newPasswordConfirm ?? ""}
                  onChange={onNewPasswordConfirmChanged}
                />
                <label htmlFor="confirmPassword">Confirm New Password</label>
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <button type="submit" className="btn btn-primary">
          Update
        </button>
      </div>
      <div className="col-md-12 text-center mt-3">
        {errorMessage !== "" && (
          <div className="error-message">{errorMessage}</div>
        )}
      </div>
      <div className="col-md-12 text-center mt-3">
        {updateOk && <div className="submit-ok">Profile updated</div>}
      </div>
    </form>
  );
};

export default UserProfile;
