import Geometry from "ol/geom/Geometry";
import { Vector as VectorSource } from "ol/source";

function vector({ features }: { features: any }): VectorSource<Geometry> {
  return new VectorSource({
    features,
  });
}

export default vector;
