import { useEffect } from "react";
import userService from "../services/userService";
import { useNavigate } from "react-router-dom";

const Logout: React.FC<{ onLogout: any }> = ({ onLogout }) => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("logoutPageInit");

    userService.logout();
    onLogout();
    navigate("/login");
  }, [navigate, onLogout]);
  return <div></div>;
};

export default Logout;
